@mixin setPaddingToGrid($paddingH, $paddingV){
	margin-left: -$paddingH;
	margin-top: -$paddingV;

	.grid__item{
		padding-left: $paddingH;
		padding-top: $paddingV;
	}
}

.grid{
	font-size: 0;
	line-height: 0;

	&_center{
		text-align: center;
	}
	
	&_justify{
		font-size: 1px;
		text-align: justify;
		text-align-last: justify;

		&:before{
			content: '';
			display: block;
			width: 100%;
		}

		&:after{
			content: '';
			display: inline-block;
			width: 100%;
			height: 0;
			font-size: 0;
			line-height: 0;
			overflow: hidden;
		}

		.grid__item{
		  text-align-last: left;
		}
	}

	&__item{
		display: inline-block;
		vertical-align: top;
		text-align: left;
		width: 100%;
		font-size: getBase('font-size');
		line-height: normal;
		position: relative;
		box-sizing: border-box;
	}
}


/*-----------------------------------------------------------
	Custom Styles
-----------------------------------------------------------*/

.grid_one{
	.grid__item{
		width: (100%/3);
		cursor: default;
	}
	
	@media screen and (max-width: 1024px) {
		text-align: center;
		.grid__item{
			width: 50%;
		}
	}

	@media screen and (max-width: 620px) {
		.grid__item{
			width: 100%;
			padding: 0;
			border: none;
			&:hover{
				border: none;
			}
		}
	}
}

.grid_x2_img{
	@media screen and (min-width: 1025px) {
		.grid__item{
			&_x2{
				width: (100%/3)*2;
			}

			&_img_ramp{
				padding-left: 90px;
				img{
					max-width: 100%;
				}
			}
		}
	}
	
	@media screen and (max-width: 1024px) and (min-width: 621px) {
		padding-top: 220px;
		.grid__item{
			&_img_ramp{
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -310px;
				padding: 0;
				img{
					width: 620px;
					max-width: none;
				}
			}
		}
	}

	@media screen and (max-width: 620px) {
		.grid__item{
			&_img_ramp{
				padding: 30px 0 20px;
				width: 120%;
				margin-left: -10%;
				img{
					max-width: 100%;
					min-width: 480px;
				}
			}
		}
	}
}

.grid_portfolio{
	
	.grid__show-all{
		width: 100%;
		height: 85px;
		line-height: 84px;
		text-transform: uppercase;
		font-size: 18px;
		padding: 0 10px;
		text-align: center;
		background: #f5f5f5;
		color: #6e6e6e;
		cursor: pointer;
		display: none;
		
		&:after{
			content: '';
			display: inline-block;
			background: url(../img/triangle-right-blue.png) no-repeat;
			width: 13px;
			height: 26px;
			vertical-align: middle;
			margin-left: 13px;
			
		}
	}
	
	
	.grid__item{
		width: 25%;
	}
	
	@media screen and (max-width: 768px) {
		.grid__item{
			width: 50%;

			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8){
				display: none;
			}
		}
		
		.grid__show-all{
			&:nth-child(3),
			&:nth-child(4){
				display: none;
			}
			display: block;
		}
	}

	@media screen and (max-width: 480px) {
		.grid__item{
			width: 100%;

			&:nth-child(3),
			&:nth-child(4){
				display: none;
			}
		}
	}
	
	&.show-all{
		.grid__item{
			display: inline-block;
		}

		.grid__show-all{
			display: none;
		}
	}
}


.grid_stages{
	margin-left: -20px;
	.grid__item{
		padding: 0 0 15px 20px;
	}
	@media screen and (max-width: 1000px) {
		.grid__item{
			width: 50%;
		}
	}

	@media screen and (max-width: 630px) {
		margin-left: 0;
		.grid__item{
			width: 100%;
			padding-left: 0;
		}
	}
}


.grid_our-figures{
	color: #fff;
	.grid__item{
		width: 25%;
		padding-top: 20px;
		@media screen and (max-width: 768px) { 
			padding-top: 40px;
		}
		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 2px;
			height: 170px;
			background: #fff;
		}

		&:first-child{
			&:after{
				display: none;
			}
		}
	}
	
	@media screen and (max-width: 1140px) {
		.grid__item{
			width: 50%;

			&:after{
				display: none;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.grid__item{
			width: 100%;

			&:after{
				display: none;
			}
		}
	}
}

.grid_home-header{
	margin-left: -30px;
	.grid__item{
		width: (100%/3);
		padding-left: 30px;
		text-align: center;
	}
	
	@media screen and (max-width: 1050px) {
		margin: 0;
		.grid__item{
			width: 100%;
			margin-bottom: 20px;
			padding-left: 0;
		}
	}
	
	@media screen and (max-width: 370px) and (max-height: 550px) {
		.grid__item{
			margin-bottom: 10px;
		}
	}
}


.grid_biz-header{
	max-width: 820px;
	margin-right: auto;
	margin-left: auto;
	.grid__item{
		width: (100%/3);
		text-align: center;
	}
	
	@media screen and (max-width: 768px) {
		.grid__item{
			width: 50%;
		}
	}
}



.grid_ramp{
	.grid__item{
		width: (100%/6);

		img{
			width: 100%;
		}
	}
	
	.grid__item-icon{
		position: absolute;
		color: #fff;
		opacity: 0.3;
		@include sizeRelativePageWidth('width', 90px, 1920px, 5px);
		@include sizeRelativePageWidth('height', 90px, 1920px, 5px);
		@include sizeRelativePageWidth('right', 30px, 1920px, 5px);
		@include sizeRelativePageWidth('bottom', 30px, 1920px, 5px);
	}
	
	.grid__item_delivery{
		.grid__item-icon{
			@include sizeRelativePageWidth('width', 110px, 1920px, 5px);
			@include sizeRelativePageWidth('height', 85px, 1920px, 5px);
		}
	}
	
	.grid__item_delivery,
	.grid__item_setup,
	.grid__item_handmade,
	.grid__item_options,
	.grid__item_ride{
		position: absolute;
		left: 0;
		top: 0;
		background: getBase('color-two');
		width: 100%;
		height: 100%;
		color: #fff;
		@include sizeRelativePageWidth('padding-left', 25px, 1920px, 5px);
		@include sizeRelativePageWidth('padding-right', 25px, 1920px, 5px);
		@include sizeRelativePageWidth('padding-top', 65px, 1920px, 5px);
		@include sizeRelativePageWidth('font-size', 24px, 1920px, 12px);
	}
	
	.grid__item-icon{
		line-height: 0;
	}

	.grid__item_setup{
		background: #575757;
	}
	.grid__item_handmade{
		background: #99CC33;
	}
	.grid__item_options{
		background: #FF9933;
		@include sizeRelativePageWidth('padding-top', 25px, 1920px, 5px);
	}
	.grid__item_ride{
		background: #000;
	}
	
	.grid__item_ride{
		
		.grid__item-icons{
			overflow: hidden;
			text-align: center;
			@include sizeRelativePageWidth('margin-top', 30px, 1920px, 1px);
		}
		.grid__item-icon{
			position: static;
			display: inline-block;
			border-radius: 50%;
			border: 1px solid #fff;
			@include sizeRelativePageWidth('width', 70px, 1920px, 5px);
			@include sizeRelativePageWidth('height', 70px, 1920px, 5px);
			@include sizeRelativePageWidth('padding', 12px, 1920px, 1px);
			@include sizeRelativePageWidth('margin-left', 15px, 1920px, 1px);
		}
		
		.grid__item-icon:first-child{
			margin-left: 0;
		}
	}
}

.grid_ramp-tablet,
.grid_ramp-mobile{
	display: none;
}

.grid_ramp-tablet{
	.grid__item{
		width: 25%;
	}
	
	.grid__item_delivery,
	.grid__item_setup,
	.grid__item_handmade,
	.grid__item_options,
	.grid__item_ride{
		@include sizeRelativePageWidth('padding-left', 15px, 768px, 5px);
		@include sizeRelativePageWidth('padding-right', 15px, 768px, 5px);
		@include sizeRelativePageWidth('padding-top', 30px, 768px, 5px);
		@include sizeRelativePageWidth('font-size', 15px, 768px, 10px);
	}
	
	.grid__item_options{
		@include sizeRelativePageWidth('padding-top', 22px, 768px, 5px);
	}
	
	.grid__item-icon{
		position: absolute;
		color: #fff;
		opacity: 0.3;
		@include sizeRelativePageWidth('width', 55px, 768px, 5px);
		@include sizeRelativePageWidth('height', 55px, 768px, 5px);
		@include sizeRelativePageWidth('right', 18px, 768px, 5px);
		@include sizeRelativePageWidth('bottom', 12px, 768px, 5px);
	}
	
	.grid__item_delivery{
		.grid__item-icon{
			@include sizeRelativePageWidth('width', 70px, 768px, 5px);
			@include sizeRelativePageWidth('height', 40px, 768px, 5px);
		}
	}
	
	.grid__item_ride{
		
		.grid__item-icons{
			overflow: hidden;
			text-align: center;
			@include sizeRelativePageWidth('margin-top', 25px, 768px, 1px);
		}
		.grid__item-icon{
			@include sizeRelativePageWidth('width', 41px, 768px, 5px);
			@include sizeRelativePageWidth('height', 41px, 768px, 5px);
			@include sizeRelativePageWidth('padding', 8px, 768px, 1px);
			@include sizeRelativePageWidth('margin-left', 8px, 768px, 1px);
		}
		
		.grid__item-icon:first-child{
			margin-left: 0;
		}
	}
}

.grid_ramp-mobile{
	.grid__item{
		width: 50%;
	}
	
	.grid__item_delivery,
	.grid__item_setup,
	.grid__item_handmade,
	.grid__item_options,
	.grid__item_ride{
		@include sizeRelativePageWidth('padding-left', 10px, 320px, 5px);
		@include sizeRelativePageWidth('padding-right', 10px, 320px, 5px);
		@include sizeRelativePageWidth('padding-top', 25px, 320px, 5px);
		@include sizeRelativePageWidth('font-size', 13px, 320px, 8px);
	}
	
	.grid__item_options{
		@include sizeRelativePageWidth('padding-top', 18px, 320px, 5px);
	}
	
	.grid__item-icon{
		position: absolute;
		color: #fff;
		opacity: 0.3;
		@include sizeRelativePageWidth('width', 45px, 320px, 5px);
		@include sizeRelativePageWidth('height', 45px, 320px, 5px);
		@include sizeRelativePageWidth('right', 10px, 320px, 5px);
		@include sizeRelativePageWidth('bottom', 10px, 320px, 5px);
	}
	
	.grid__item_delivery{
		.grid__item-icon{
			@include sizeRelativePageWidth('width', 55px, 320px, 5px);
			@include sizeRelativePageWidth('height', 30px, 320px, 5px);
		}
	}
	
	.grid__item_ride{
		
		.grid__item-icons{
			overflow: hidden;
			text-align: center;
			@include sizeRelativePageWidth('margin-top', 15px, 320px, 1px);
		}
		.grid__item-icon{
			@include sizeRelativePageWidth('width', 35px, 320px, 5px);
			@include sizeRelativePageWidth('height', 35px, 320px, 5px);
			@include sizeRelativePageWidth('padding', 5px, 320px, 1px);
			@include sizeRelativePageWidth('margin-left', 6px, 320px, 1px);
		}
		
		.grid__item-icon:first-child{
			margin-left: 0;
		}
	}
}
@media screen and (max-width: 1024px) {
	.grid_ramp-desktop{
		display: none;
	}
	.grid_ramp-tablet{
		display: block;
	}
}

@media screen and (max-width: 700px) {
	.grid_ramp-tablet{
		display: none;
	}
	.grid_ramp-mobile{
		display: block;
	}
}


.grid_soc-stages-for-part{
	@include setPaddingToGrid(90px, 0);
	
	.grid__item{
		width: (100%/3);
		
		&:before{
			
		}
	}
	
	
	@media screen and (max-width: 730px) {
		@include setPaddingToGrid(0, 70px);
		.grid__item{
			width: 100%;
		}
	}
}


.grid_cards{
	@include setPaddingToGrid(25px, 25px);
}