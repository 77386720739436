.fk-item{
	&__title{
		font-family: Gotham;
		color: #000;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&__text{
		font-size: 18px;
		color: #000;
		text-align: left;
	}
}

.fk-item_counter{
	text-align: center;
	padding: 0 20px 10px;
	border: 2px solid transparent;
	&:hover{
			border: 2px solid getBase('color-one');
	}

	.fk-item__number{
		font: bold 72px Gotham, sans-serif;
		color: #f3f3f3;
		margin-bottom: 5px;
	}

	.fk-item__title{
		font-size: 24px;
		margin-bottom: 20px;
	}

	.fk-item__text{
		color: #000;
	}
}


/*------------------------[ Reasons ]----------------------------*/
.fk-item_reason{
	min-height: 270px;
	
	@media screen and (max-width: 570px) {
		min-height: 0;
		padding-bottom: 0;

		.fk-item__number{
			font-size: 48px;
		}
		.fk-item__title{
			font-size: 18px;
		}
		.fk-item__text{
			font-size: 15px;
		}
	}
}

/*------------------------[ Pluses ]----------------------------*/
.fk-item_plus{
	min-height: 350px;
	.fk-item__number{
		position: absolute;
		left: 50%;
		top: 17px;
		margin-left: -75px;
		font: bold 30px Gotham, sans-serif;
	}
	
	.fk-item__icon{
		height: 103px;
		color: getBase('color-two');
		text-align: center;
		padding-top: 30px;
		padding-bottom: 5px;
		width: 100px;
		margin: 0 auto;
		
		[class*="ic-"]{
			font-size: 58px;
		}
		
		.ic-ramp{
			top: 14px;
		}
	}
	
	.fk-item__title{
		font: 18px;
		text-align: center;
		text-shadow: 1px 0 rgba(0,0,0,0.3);
	}
	
	.fk-item__text{
		text-align: left;
	}
	
	@media screen and (max-width: 570px) {
		min-height: 0;
		padding-bottom: 0;

		.fk-item__title{
			font-size: 18px;
		}
		.fk-item__text{
			font-size: 16px;
		}
	}
}

 .fk-item_our-figures{
	color: #fff;
	text-align: center;
	padding: 0 20px;
	
	.fk-item__title{
		color: #fff;
		text-transform: none;
		font-weight: bold;
		font-size: 32px;
		height: 90px;
		    line-height: NORMAL;
	}

	.fk-item__text{
		color: #fff;
	}
	
	@media screen and (max-width: 1140px) {
		padding-bottom: 40px;
		.fk-item__title{
			height: auto;
		}
	}
	
	@media screen and (max-width: 1024px) {
		.fk-item__title{
			font-size: 20px;
		}
	}
	
	@media screen and (max-width: 768px) {
		.fk-item__title{
			font-size: 18px;
		}
	}
	
	@media screen and (max-width: 568px) {
		padding: 0;
		.fk-item__text{
			font-size: 15px;
			text-align: center;
			padding:0 10px;
		}
	}
}