.text-field{
	font-size: 18px;
	line-height: 58px;
	padding: 0 28px;
	border: 1px solid #d9d9d9;
	background: transparent;
	width: 100%;
	margin-bottom: 21px;
	
	&.error{
		$errorColor: #ff6666;
		border-color: $errorColor;
		color: $errorColor;
		&::-webkit-input-placeholder {color:$errorColor;}
		&::-moz-placeholder          {color:$errorColor;}
		&:-moz-placeholder           {color:$errorColor;}
		&:-ms-input-placeholder      {color:$errorColor;}
		&:placeholder                {color:$errorColor;}
	}
}

textarea.text-field{
	padding: 18px 28px;
	line-height: normal;
}