//N ABOUT BLOCK 31.03.2017
.n-about {
	background-color: #f9f9f9;
	padding: 45px 0 0;
	@media screen and (max-width: 568px) {
		padding: 32px 0 0;
	}
	.n-content-wrap {
		
		max-width:1220px;
		margin: 0 auto;
		padding: 0 30px;
		overflow: hidden;
		@media screen and (max-width: 1024px) {
			padding: 0 20px;
		}
		@media screen and (max-width: 568px) {
			padding: 0 8px;
		}
		&__row {
			font-size: 0;
			line-height: 0;
			margin-left: -35px;
			
			@media screen and (max-width: 568px) {
				margin-left: 0;
			}
		}
		&__col {
			display: inline-block;
			vertical-align: top;	
			padding-left: 35px;
			&--left {
				width: 52%;
			}
			&--right {
				width: 48%;
				padding-left: 30px;
				@media screen and (max-width: 1024px) {
					padding-left: 46px;
				}
			}
			@media screen and (max-width: 568px) {
				padding-left: 0;
				&--left {
					width: 100%;
				}
				&--right {
					width: 100%;
					padding-left: 0;
				}
			}
		}
	}
	.n-content-title {
		font-size: 24px;
		line-height: 28px;
		color:#000;
		font-family:  GothamBold;
		/*font-weight: 700;*/ 
		text-transform: uppercase;
		padding-bottom: 22px;
		margin: 0;
		@media screen and (max-width: 568px) {
			text-align: center;
			padding-bottom: 16px;
		}
	}
	.n-features {
		padding-bottom: 24px;
		margin-left: -25px;
		@media screen and (max-width: 1024px) {
			margin-left: -10px;
			padding-bottom: 27px;
		}
		@media screen and (max-width: 568px) {
			margin-left: -5px;
		}
		&__inner {
			position: relative;		
			z-index: 0;	
			&:before {
				content:'';
				position: absolute;
				top:0;
				left: 22px;
				width: 3px;
				height: 1000000px;
				background-color: #5392d5;
				z-index: -1;
			}
			@media screen and (max-width: 568px) {
				&:before {
					display: none;
				}
			}
		}
	}
	.n-features-item {
		padding-bottom: 38px;
		@media screen and (max-width: 1024px) {
			padding-bottom: 18px;
		}
		@media screen and (max-width: 568px) {
			padding-bottom: 0;
			&:last-child {
				.n-features-item__list-item {
					&:last-child {
						&:before {
							height: 7px;
						}
					}
				}
			}
		}
		&:last-child {
			padding-bottom: 0;
		}
		&__head {
			padding-bottom: 22px;
			@media screen and (max-width: 568px) {
				position: relative;
				&:before {
					content:'';
					position: absolute;
					top:0;
					left: 22px;
					height: 100%;
					width: 3px;
					background-color: #5392d5;
					z-index: -1;
				}
			}
			&-icon,
			&-title {
				display: inline-block;
				vertical-align: middle;
			}
			&-icon {
				width: 50px;
				height: 50px;
				border-radius:50%;
				border:3px solid #a4d435;
				background-color: #fff;
				color:#888888;
				text-align: center;
				margin-right: 16px;

				&:before {
					content:'';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}
				&-skating {
					width: 17px;
					height: 31px;
					display: inline-block;
					vertical-align: middle;
				}
				&-finger {
					width: 24px;
					height: 29px;
				}
				@media screen and (max-width: 1024px) {
					margin-right: 8px;
				}
			}
			&-title {
				font-size: 18px;
				line-height: 22px;
				font-family:  GothamBold;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
				width: calc(100% - 66px);
				@media screen and (max-width: 1024px) {
					width: calc(100% - 58px);
				}
				@media screen and (max-width: 568px) {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
		&__el {
			position: relative;
			&:before {
				content:'';
				position: absolute;
				top: 4px;
				left:-44px;
				width: 7px;
				height: 7px;
				background-color: #fff;
				border:2px solid #a4d435;
				border-radius:50%;
				@media screen and (max-width: 1024px) {
					left: -27px;
				}
				@media screen and (max-width: 568px) {
					left: -27px;
				}
			}
			&:after {
				content:'';
				position: absolute;
				left: -33px;
				top:9px;
				width: 22px;
				height: 2px;
				background-color: #a4d435;
				@media screen and (max-width: 1024px) {
					width: 10px;
					left: -16px;
				}
				@media screen and (max-width: 568px) {
					width: 10px;
					left: -16px;
				}
			}
		}
		&__list {
			padding: 0;
			margin: 0;
			list-style-type: none;
			&-item {
				padding-bottom: 18px;
				padding-left:62px;
				position: relative;
				font-size: 16px;
				line-height: 20px;
				font-family: Calibri;
				font-weight: 300;
				&:last-child {
					padding-bottom: 0;
				}
				@media screen and (max-width: 1024px) {
					padding-left: 45px;
				}
				@media screen and (max-width: 568px) {
					font-size: 14px;
					padding-bottom: 14px;
					&:last-child {
						padding-bottom: 32px;
					}
					&:before {
						content: '';
	    				position: absolute;
	    				top: -2px;
	    				left: 22px;
	    				width: 3px;
	    				height: 103%;
	    				background-color: #5392d5;
					}
				}
				
			}
		}
	}
	.n-about-info {
		padding-bottom: 26px;
		&__pic {
			width: 355px;
			max-width:100%;
			height: 228px;
			margin-bottom: 20px;
			@media screen and (max-width: 1024px)  {
				width: 289px;
				height: 185px;
				margin-bottom: 10px;
			}
			@media screen and (max-width: 568px) {
				margin: 0 auto 6px;
			}
		}
		&__text {
			padding-bottom: 40px;
			font-size: 16px;
			line-height: 19px;
			font-family: Calibri;
			font-weight: 300;
			@media screen and (max-width: 1024px) {
				padding-bottom: 32px;
			}
			@media screen and (max-width: 568px) {
				font-size: 14px;
				line-height: 17px;
				padding-bottom: 19px;
			}
		}
		&__subtitle {
			font-weight: 700;
			padding-bottom: 29px;
			font-size: 18px;
			line-height: 21px;
			margin: 0;
			@media screen and (max-width: 1024px) {
				font-size: 16px;
				line-height: 19px;
				padding-bottom: 21px;
			}
		}
		&__text-item {
			padding-bottom: 20px;
			margin: 0;
			&:last-child {
				padding-bottom: 0;
			}
		}
		&__bottom {
			&:after {
				content:'';
				clear: both;
				display: table;
				width: 100%;
			}
		}
		&__link {
			float: right;
			&-text {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
				line-height: 16px;
				font-family: Calibri;
				font-weight: 300;
				color:#555555;
				&:hover {
					color:#0094db
				}
			}
			&-icon {
				display: inline-block;
				vertical-align: middle;
				margin-left: 4px;
				width: 11px;
				height: 18px;
				color:#0393d8;
			}
		}
	}
}

//N ABOUT BLOCK END 31.03.2017