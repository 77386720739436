body{
	font: normal 20px 'Calibri', sans-serif;
}

.underline{
	position: relative;
	display: inline-block;
	&:after{
		content: '';
		display: block;
		position: absolute;
		bottom: -1;
		width: 100%;
		border-top: 1px solid #000;
		border-bottom: 1px solid rgba(0,0,0,0.3);
		border-right: 1px solid rgba(0,0,0,0.1);
		border-left: 1px solid rgba(0,0,0,0.1);
	}
}

.underline-none{
	&:after{
		border-color: transparent;
	}
}

.preloader{
	@include overlay(rgba(255,255,255,0.6));
	opacity: 0;
	display: none;
	transition: opacity 0.2s;
	z-index: 9999;

	&.active{
		display: block;
		opacity: 1;
	}
	&__icon{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -25px;
		margin-top: -25px;
		height: 50px;
		width:  50px;
		border-width: 5px;
		border-style: solid;
		border-color: getBase('color-one') getBase('color-one') getBase('color-two') getBase('color-two');
		border-radius: 100%;
		animation: clockwise .6s linear infinite;
	}
}

.from-1180px{
	display: none;
}


.soc-project-full{
	border: 1px solid #c4c4c4;
	padding: 15px;

	&__stage{
		margin-bottom: 70px;
		font-size: 18px;
		text-align: justify;
	}

	&__stage-name{
		font: bold 24px getBase('font-heading');
		text-transform: uppercase;
	}

	&__content{
		overflow: hidden;
	}

	&__first{
		width: 50%;
		float: right;
	}

	&__second{
		width: 50%;
		float: left;
		padding-right: 35px;
		padding-top: 20px;
	}

	&__third{
		padding-top: 0;
	}

	&__space{
		visibility: hidden;
	}

	&__after{
		font-size: 18px;
		padding: 30px 15px 0;
	}
}

.js-svg_attention{

	&_small-blue{
		width: 19px;
		height: 19px;
		color: getBase('color-two');
		position: relative;
		top: -2px;
	}
}


.scroll-top{
	position: fixed;
	right: 0;
	bottom: -100px;
	width: 47px;
	height: 47px;
	line-height: 50px;
	text-align: center;
	color: #fff;
	font-size: 20px;
	background: #8cd50e;
	cursor: pointer;
	z-index: 999;
	transition: bottom 0.2s ease;
	box-shadow: 0 0 5px rgba(0,0,0,0.5);

	&.active{
		bottom: 100px;
	}
}

.breadcrumbs{
	color: #a7a7a7;
	font-size: 0;
	padding-top: 30px;
	padding-bottom: 30px;

	&__item{
		font-size: 16px;
		margin-right: 16px;
		position: relative;
	}

	&__item:hover{
		text-decoration: underline;
	}

	&__item:before{
		position: absolute;
		left: -11px;
		content: '/';
	}

	&__item:first-child:before{
		display: none;
	}
}

.l-download{
	@extend .grid;
	&__one, &__two{
		@extend .grid__item;
		position: relative;
		width: 50%;
		height: 100%;
	}
	&__broshure{
		position: relative;
		bottom: -40px;
		right: -130px;
		background: url(../img/broshure.png) no-repeat;
		width: 784px;
		height: 472px;
		float: right;
	}

	&__two{
		padding: 68px 0 20px;
	}

	&__title{
		font: 31px Gotham, sans-serif;
		padding-left: 70px;
		margin-bottom: 33px;
		text-transform: uppercase;
	}

	&__line-one,
	&__line-two,
	&__line-three,
	&__line-four{
		padding-left: 150px;
		margin-bottom: 23px;
		font-size: 18px;
	}

	&__line-one{
		font-size: 24px;
	}

	&__context-btn{
		color: getBase('color-one');
		text-transform: uppercase;
		font-size: 14px;
		height: 48px;
		line-height: 44px;
		padding: 0 17px;
	}

	&__context-btn-icon{
		display: inline-block;
		position: relative;
		font-size: 22px;
		margin-right: 7px;
		vertical-align: middle;
		width: 32px;
		font-size: 0;
	}

	&__context-btn-label{
		vertical-align: middle;
	}

	&__btn{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -60px;
		margin-left: 50px;
		width: 88px;
		height: 88px;
		border-radius: 100%;
		background: rgba(255,255,255, 0.67);
		color: #000;
		text-align: center;
		padding-top: 12px;

		&:hover{
			background: rgba(164, 212, 53, 0.67);
		}
	}
	&__btn-icon{
		display: block;
		font-size: 35px;
		margin-bottom: 2px;
	}
	&__btn-label{
		display: block;
		text-decoration: underline;
		font-size: 16px;
		text-transform: uppercase;
		padding-left: 1px;
	}
}


.custom-radio,
.custom-checkbox{
	overflow: hidden;
	position: relative;
	input{
		position: absolute;
		visibility: hidden;
		left: -100px;
		top: -100px;
	}

	&__view{
		cursor: pointer;
	}
}

.custom-radio_type_one,
.custom-checkbox_type_one{
	vertical-align: top;
	.custom-radio__view,
	.custom-checkbox__view{
		position: relative;
		font-size: 18px;
		color: #000;
		border: 2px solid #e8e8e8;
		padding: 22px;
		padding-right: 45px;
		width: 250px;
		text-align: left;
		vertical-align: top;

		&:after,
		&:before{
			content: '';
			position: absolute;
			border-radius: 50%;
			right: 22px;
			top: 25px;
		}

		&:before{
			width: 21px;
			height: 21px;
			background: #e8e8e8;
		}

		&:after{
			width: 5px;
			height: 5px;
			background: #fff;
			margin-right: 6px;
			margin-top: 6px;
			border: 2px solid #dcdcdc;
		}
	}

	input:checked+.custom-radio__view,
	input:checked+.custom-checkbox__view{
		border-color: getBase('color-two');

		&:before{
			background: getBase('color-two');
		}

		&:after{
			background: getBase('color-two');
			border-color: #fff;
		}
	}
}




.ramp-calc{
	text-align: left;
	border: 1px solid #dcdcdc;
	padding: 40px 18px 35px;

	&__section{
		margin-bottom: 35px;
	}

	&__section-label{
		text-transform: uppercase;
		color: #8b8b8b;
		margin-bottom: 15px;
		font-size: 18px;
	}

	&__section_height{
		float: left;
	}

	&__section_width{
		float: right;
	}

	&__section_view{
		overflow: hidden;
		width: 100%;
	}

	&__total{
		text-align: right;
		padding-top: 32px;
		border-top: 1px solid #e8e8e8;
	}

	&__total-label{
		font-size: 24px;
		margin-right: 20px;
	}

	&__total-price{
		font-size: 36px;
	}

	@media screen and (max-width: 1090px) {
		max-width: 630px;
		margin: 0 auto;
		padding-left: 60px;
		padding-right: 60px;
		&__section_height,
		&__section_width{
			float: none;
		}

		.custom-checkbox__view{
			margin-bottom: 15px;
		}
	}

	@media screen and (max-width: 665px) {
		width: 100%;
		margin: 0 auto;
		padding: 30px 5px 0;
		border-left: none;
		border-right: none;
		border-bottom: 0;
		&__section_height,
		&__section_width{
			float: none;
		}

		&__section{
			margin-bottom: 15px;
		}

		&__section-label{
			font-size: 16px;
			text-align: center;
		}

		&__total{
			padding: 0;
			text-align: center;
			border-top: none;
		}

		&__total-label{
			margin-right: 5px;
		}

		&__total-price{
			font-size: 30px;
		}

		&__section-content{
			text-align: center;
		}

		.custom-checkbox{
			width: 50%;
		}
		.custom-checkbox__view,
		.custom-radio__view{
			font-size: 14px;
			padding: 7px;
			padding-right: 24px;
			margin-bottom: 10px;
			width: 45%;

			&:before{
				top: 10px;
				right: 7px;
				width: 13px;
				height: 13px;
			}

			&:after{
				top: 10px;
				right: 7px;
				width: 1px;
				height: 1px;
				margin-right: 4px;
				margin-top: 4px;
			}
		}
	}
}



.img-viewer{
	width: 100%;

	img{
		max-width: 100%;
	}
}


.faq{
	max-width: 738px;
	width: 100%;
	margin: 0 auto;

	&__item{
		border: 1px solid #9a9a9a;
		border-top-width: 0;
		color: #5e5e5e;

		background-color: #fff;
		transition: background-color 0.4s;
		&:first-child{
			border-top-width: 1px;
		}
	}

	&__question{
		cursor: pointer;
		font-size: 26px;
		position: relative;
		padding: 20px;
		padding-right: 35px;
		&:hover{
			text-decoration: underline;
		}
		&:after{
			content: $ic-arrow-down;
			font-family: fk;
			color: #8abb19;
			font-size: 27px;
			position: absolute;
			right: 20px;
   			top: 20px;
		}
	}

	&__answer-content{
		padding: 20px;
		font-size: 18px;
		font-weight: 300;
	}

	&__answer{
		overflow: hidden;
		display: none;
	}

	&__item.active{
		background-color: #fafafb;

		.faq__question:after{
			content: $ic-arrow-up;
			color: #1299da;

			@media (max-width:767px) {


    			top: calc(50% - 12px) !important;

			}
		}
	}
}




.slider{
	position: relative;
}
.slick-arrow{
	position: absolute;
	top: 45%;
	outline: none;
	z-index: 10;
	cursor: pointer;
	color: #000;
	background: transparent;
	font-size: 0;
	margin-top: -50px;
	padding: 50px 10px;
	text-shadow: -2px 0 0 #fff, -1px 0 0 #fff, 0 0 0 #fff, 1px 0 0 #fff, 2px 0 0 #fff,
				 0 -2px 0 #fff, 0 -1px 0 #fff, 0 0 0 #fff, 0 1px 0 #fff, 0 2px 0 #fff,
				 1px -2px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 1px 2px 0 #fff,
				 2px -2px 0 #fff, 2px -1px 0 #fff, 2px 0 0 #fff, 2px 1px 0 #fff, 2px 2px 0 #fff,
				 -1px -2px 0 #fff, -1px -1px 0 #fff, -1px 0 0 #fff, -1px 1px 0 #fff, -1px 2px 0 #fff,
				 -2px -2px 0 #fff, -2px -1px 0 #fff, -2px 0 0 #fff, -2px 1px 0 #fff, -2px 2px 0 #fff;
	&:hover{
		color: getBase('color-two');
	}
	&:after{
		font-family: 'fk';
		font-size: 40px;
	}
}

.slick-slide{
	outline: none;
}

.slick-prev{
	left: 0;
	&:after{
		content: $ic-arrow-left;
	}
}

.slick-next{
	right: 0;
	&:after{
		content: $ic-arrow-right;
	}
}

.slider_regular{
	padding: 0 70px;

	.slider__slide{
		text-align: center;
	}

	.slick-arrow{
		top: 0;
		height: 100%;
		color: #c5c5c5;
		&:after{
			font-size: 30px;
		}

		&:hover{
			color: getBase('color-two');
		}
	}
}

.slider_athlets{
	.slick-arrow{
		top: 0;
		height: 100%;
		color: #c6c6c6;
		&:after{
			font-size: 25px;
		}
		&:hover{
			color: getBase('color-two');
		}
	}

	@media screen and (max-width: 700px) {
		.slick-arrow{
			top: 130px;
			height: 25px;
		}
	}
}

.slider_athlets_fulltime{
	/*top: 30px;*/
	z-index: 20;
	padding-top: 30px;
	    margin-bottom: -1px;
	.slick-arrow{
		top: 0;
		height: 100%;
		color: #c6c6c6;
		&:after{
			font-size: 38px;
		}
		&:hover{
			color: getBase('color-two');
		}
	}

	@media screen and (min-width: 1300px) {
		.slick-arrow{
			top: 50%;
			margin-top: -18px;
			height: auto;
		}

		.slick-prev{
			left: 50%;
			margin-left: -650px;
		}

		.slick-next{
			right: 50%;
			margin-right: -650px;
		}
	}

	@media screen and (max-width: 700px) {
		.slick-arrow{
			top: 130px;
			height: 25px;
		}
	}
}

.slider_one{
	.slick-arrow{
		top: 318px;
	}
}

@media screen and (min-width:1250px) {
	.slider_one{
		.slick-arrow{
			top: 308px;
			right: auto;
			left: auto;
		}

		.slick-prev{
			left: 50%;
			margin-left: -620px;
		}

		.slick-next{
			right: 50%;
			margin-right: -620px;
		}
	}
}


.slide{
	outline: none;
	overflow: hidden;
	cursor: default;
}

.slide_style_one{
	position: relative;
	height: 950px;
	.slide__content{
		position: relative;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
	}

	.slide__text{
		position: absolute;
		right: 53px;
		top: 0;
		max-width: 523px;
		width: 50%;
		font-size: 18px;

		p{
			margin: 20px 0;

		}

		.slide__marked-text{
			font: 24px Gotham, sans-serif;
			text-align: right;
			margin-top: 80px;
			text-transform: uppercase;
			/*text-shadow: 1px 0px 0px #000;*/
		}
	}

	.slide__title{
		text-align: center;
		font: 30px GothamMedium, sans-serif;
		color: #1299da;
		/*text-shadow: 1px 0px 0px #1299da;*/
		text-transform: uppercase;
	}

	.slide__img{
		position: relative;
		left: -145px;
	}

	.slide__title_big{
		font-size: 32px;
	}
}


.slider_docs{
	padding: 0 60px;
	.slick-arrow{
		top: 50%;
		right: auto;
		left: auto;
		margin-top: -20px;
		color: rgba(255,255,255,0.32);
		text-shadow: none;
		&:after{

		}
		&:hover{
			color: getBase('color-two');
		}
	}

	.slick-prev{
		left: 0;
	}

	.slick-next{
		right: 0;
	}
}

.docs-block{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 270px;
	background: rgba(32,34,37,0.78);
	color: #fff;
	overflow: hidden;
}

.docs-block__title{
	width: 33.333%;
	float: left;
	font: 36px Gotham, sans-serif;
	text-transform: uppercase;
	margin-top: 68px;
	padding-left: 20px;
}

.docs-block__slider{
	width: 66.666%;
	float: right;
	margin-top: 60px;
}


.doc{
	display: block;
	position: relative;
	width: 170px;
	height: 128px;
	background: url(../img/file-white.png) no-repeat;
	padding: 20px 17px 7px;
	margin: 0 auto;

	&:hover{
		background-image: url(../img/file-blue.png);
	}

	&__title{
		font-size: 18px;
	}

	&__type{
		position: absolute;
		right: 11px;
		bottom: 7px;
		font-size: 16px;
	}
}



.slider_reviews{
	padding: 0 60px;
	max-width: 1050px;
	width: 100%;
	margin: 0 auto;
	.slick-arrow{
		top: 70px;
		color: #cbcbcb;
		&:hover{
			color: getBase('color-two');
		}
	}

	.slick-prev{
		left: 0;
	}

	.slick-next{
		right: 0;
	}
}

.slider_blog-videos{

	.slick-arrow{
		&:after{
			text-shadow: none;
			color: #fff;
			opacity: 0.7;
			font-size: 26px;
		}

		&:hover:after{
			opacity: 1;
		}
	}

	@media screen and (min-width: 1200px) {
		width: 120%;

		.slick-next{
			right: 16.6666%;
		}
	}
	/*.slick-slide{
		width: 32vw !important;
	}
	.slick-current{
		width: 66vw !important;
	}*/
}

.link{
	cursor: pointer;

	&_blue{
		text-decoration: underline;
		color: getBase('color-two');
		&:hover{
			text-decoration: none;
		}
	}

	&_underline{
		@extend .underline;

		&:hover{
			@extend .underline-none;
		}
	}
}

.loupe{
	width: 100%;
	text-align: center;

	&__img{
		display: inline-block;
		margin-bottom: 20px;
		border: 2px solid transparent;
		padding: 8px;
		position: relative;
		height: 176px;
		line-height: 176px;
		text-align: center;

		img{
			max-height: 100%;
			vertical-align: middle;
		}

		&:after{
			content: $ic-loupe-plus;
			font-family: fk;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -11px;
			margin-top: -12px;
			font-size: 27px;
			color: getBase('color-two');
			display: none;
			line-height: normal;
		}
	}

	&__label{
		font-size: 18px;
		text-transform: uppercase;

	}

	&:hover{
		.loupe__img{
			border: 2px solid #73bcdf;
			background: rgba(255,255,255,0.59);
			img{
				opacity: 0.59;
			}

			&:after{
				display: block;
			}
		}

		.loupe__label{
			text-decoration: underline;
		}
	}
}



.page__row_footer{
	background: getBase('color-two');
	color: #fff;
}
.page-footer{
	padding: 54px 20px 40px;
	&__first,
	&__second{
		@extend .grid;
		text-align: justify;
	}

	&__menu,
	&__contacts,
	&__copyright,
	&__company,
	&__address,
	&__soc-icons{
		@extend .grid__item;
		width: auto;
	}

	&__first{
		margin-bottom: 47px;
	}

	&__logo{
		width: 130px;
		height: 108px;
		fill: #fff;
	}

	&__second{
		position: relative;
		margin-top: -100px;
		width: 78%;
		a{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}

	&__justify-last{
		display: inline-block;
		width: 100%;
		height: 0;
		overflow: hidden;
	}

	&__copyright,
	&__company,
	&__address,
	&__copyright-mobile{
		font-size: 14px;
		font-weight: 300;
		color: #c3ecff;
	}

	&__company span{
		width: auto;
		display: block;
	}

	&__soc-icons{
		margin-top: 48px;
	}

	&__soc-icon{
		width: 40px;
		height: 40px;
		line-height: 36px;
		/*border: 2px solid #fff;*/
		border-radius: 100%;
		text-align: center;
		font-size: 24px;
		color: #fff;

		&:nth-child(1):hover{
			background: #da2713;
		}
		&:nth-child(2):hover{
			background: #000000;
		}
		&:nth-child(3):hover{
			background: #3b5998;
		}
		&:nth-child(4):hover{
			background: #6291c4;
		}
	}

	&__contacts{
		text-align: right;
	}

	&__menu-btn{
		display: none;
		float: right;
		border: 2px solid #fff;
		height: 48px;
		line-height: 47px;
		color: #fff;
		text-transform: uppercase;
		padding: 0 28px;
		font-size: 14px;
		cursor: pointer;
		&:hover{
			background: #fff;
			color: getBase('color-two');
		}
	}

	&__copyright-mobile{
		display: none;
		text-align: center;
		margin-top: 25px;
		line-height: normal;
		a{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
}


.contact{
	margin-bottom: 18px;
	&__title{
		font-size: 14px;
		font-weight: 300;
	}
	&__data{
		font-size: 36px;
		font-weight: 300;
	}
}



.fixed-help{
	position: fixed;
	right: 0;
	top: 215px;
	z-index: 9999;

	&__close,
	&__content{
		display: none;
	}

	&.open{
		.fixed-help__close,
		.fixed-help__content{
			display: block;
		}
		.fixed-help__open{
			display: none;
		}
	}

	&__open{
		width: 130px;
		height: 47px;
		line-height: 47px;
		background: #0094db;
		/* font-size: 0; */
		color: #fff;
		text-transform: uppercase;
		cursor: pointer;
		text-align: center;
		box-shadow: 0 0 5px rgba(0,0,0,.5);
		transform: rotate(-90deg);
		font-size: 18px;
		margin-right: -42px;
		margin-top: 41px;
	}

	&__close{
		position: absolute;
		right: 0;
		top: 0;
		width: 47px;
		height: 47px;
		line-height: 47px;
		text-align: center;
		background: getBase('color-two');
		color: rgba(255,255,255, 0.8);
		font-size: 17px;
		cursor: pointer;
		z-index: 10;
		box-shadow: 0 0 5px rgba(0,0,0,0.5);
		&:hover{
			color: #fff;
		}

		&:after{
			content: '';
			display: block;
			width: 100%;
			height: 8px;
			position: absolute;
			left: 0;
			bottom: -4px;
			background: getBase('color-two');
		}
	}


	&__content{
		position: absolute;
		right: 0;
		top: 45px;
		width: 220px;
		background: getBase('color-two');
		color: #fff;
		font-size: 16px;
		padding: 20px 13px;
		font-weight: 300;
		box-shadow: 0 0 5px rgba(0,0,0,0.5);
		a:hover{
			text-decoration: underline;
		}
	}

	&__item{
		margin-bottom: 13px;
		padding-left: 30px;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			right: 100%;
			margin-right: -20px;
			top: 5px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}

	&__item_mail{
		margin-bottom: 20px;
	}

	&__item_chat:before{
		content: $ic-dialog;
		font-family: fk;
		font-size: 18px;
	}
	&__item_mail:before{
		content: $ic-mail;
		font-family: fk;
		font-size: 12px;
		top: 5px;
	}
	&__item_phones:before{
		content: $ic-phone2;
		font-family: fk;
		font-size: 18px;
		top: 3px;
	}

	&__marked{
		display: block;
		font-size: 20px;
		font-weight: normal;
		margin-bottom: 13px;

		&:last-child{
			margin-bottom: 0;
		}
	}
}


.form_contact-us{
	.form__section_one{
		width: 50%;
		float: left;
		padding-right: 20px;
	}

	.form__section_two{
		float: right;
		width: 50%;

		textarea{
			height: 140px;
		}
	}

	.form__section_row{
		overflow: hidden;
		height: 185px;
	}

	.form__section_four{
		padding-left: 51px;
		display: inline-block;
		font-size: 16px;
		color: #9f9f9f;

		a{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}

	@media screen and (max-width:1110px) {
		.form__section_row{
			height: auto;
			margin-bottom: 15px;
		}
	}
}

.form_callback{

	.form__section-phone{
		margin-top: 15px;
		.text-field{
			margin-bottom: 0;
		}
	}

	.form__section-datetime{
		font-size: 0;
		text-align: left;
	}

	.form__section-date{
		display: inline-block;
		width: 40%;
		padding: 10px 10px 10px 0;
	}

	.form__section-hours{
		display: inline-block;
		width: 30%;
		padding: 10px 10px 10px 0;
	}
	.form__section-minutes{
		display: inline-block;
		width: 30%;
		padding: 10px 0 10px 0;
	}

	.form__section-button{
		text-align: center;

		.btn{
			margin-top: 10px;
		}
	}
}

.contact_two{
	text-align: left;
	margin-bottom: 20px;

	.contact__data{
		font: 32px/32px Gotham, sans-serif;
	}

	.contact__title{
		font-size: 18px;
		line-height: 18px;
		margin-bottom: 3px;
	}
}


.vk-widget{
	width: 100% !important;
}

@media screen and (max-width: 1024px) {
	.build-instruction-video{
		height: 480px;
	}
}

@media screen and (max-width: 700px) {
	.build-instruction-video{
		height: 320px;
	}
}

@media screen and (max-width: 480px) {
	.build-instruction-video{
		height: 220px;
	}
}

.video-blog-item{
	display: block;
	overflow: hidden;
	position: relative;
	cursor: pointer;

	&__bg{
		height: 670px;
		width: 100%;
		position: relative;
		background-size: auto 100% !important;
		transition: background-size 0.4s;
		&:before{
			content: '';
			@include overlay(url(../img/slider-video-overlay.png) center center no-repeat);
			z-index: 5;
		}
	}

	&:hover &__bg{
		background-size: auto 105% !important;
	}

	&__content{
		@include overlay();
		z-index: 10;
		color: #fff;
	}

	&__title,
	&__play{
		position: absolute;
		top: 50%;
		left: 10%;
	}

	&__title{
		font: 24px/40px getBase('font-heading');
		max-width: 435px;
		margin-top: 20px;
	}

	&__play{
		font: 18px getBase('font-heading');
		margin-top: -50px;
	}

	.js-svg_play{
		height: 53px;
		width: 53px;
		margin-left: 10px;
	}


	@media screen and (max-width: 1260px) {
		&__bg{
			height: 480px;
		}

		&__title{
			font-size: 20px;
			line-height: 32px;
			max-width: 300px;
		}

		&__play{
			font-size: 16px;
		}

		.js-svg_play{
			height: 40px;
			width: 40px;
		}
	}

	@media screen and (max-width: 1024px) {
		&__bg{
			height: 385px;
		}


		&__title{
			font: normal 16px/22px getBase('font-regular');
			max-width: 250px;
		}

		&__play{
			font-size: 14px;
		}

		.js-svg_play{
			height: 30px;
			width: 30px;
			margin-left: 7px;
		}
	}

	@media screen and (max-width: 767px) {
		&__bg{
			height: 330px;
			background-size: cover !important;
		}

		&:hover &__bg{
			background-size: cover !important;
		}

		&__title{
			font: normal 14px/22px getBase('font-regular');
			max-width: 190px;
		}

		&__play{
			font-size: 12px;
		}

		.js-svg_play{
			height: 25px;
			width: 25px;
			margin-left: 5px;
		}
	}
}

.panel{
	position: relative;
	z-index: 11;
	padding: 23px 15px 25px;
	line-height: normal;
	overflow: hidden;
	width: 100%;

	&__title{
		font-size: 22px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 30px;
		line-height: normal;
	}

	&__content{
		text-align: left;
		font-size: 18px;
		font-weight: 300;
		text-transform: none;
		line-height: normal;
		width: 100%;
	}

	&__bgicon{
		position: absolute;
		bottom: -10px;
		right: -30px;
		width: 130px;
		height: 140px;
		color: #157eb1;
		z-index: -1;
	}

	&__close{
		position: absolute;
		right: 7px;
		top: 7px;
		font-size: 15px;
		display: none;
	}
	&__more{
		position: absolute;
		bottom: 5px;
		right: 15px;
		text-align: right;
		padding: 4px 0;
		text-decoration: underline;
		display: none;
		font-style: italic;
	}

	.touch-device &{
		padding-bottom: 35px;
	}

	.touch-device &__close,
	.touch-device &__more{
		display: block;
	}

	@media screen and (max-width: 550px) {
		.panel__title{
			font-size: 18px;
			margin-bottom: 20px;
		}

		.panel__content{
			font-size: 14px;
		}
	}
}

.panel_blue{
	background: getBase('color-two');
	color: #fff;
}

@media screen and (max-width:1180px) {
	.slide_number_two{
		.slide__img{
			position: absolute;
			bottom: 0;
			top: auto;
			left: -70px;
			img{
				width: 600px;
			}
		}

		.slide__text{
			position: relative;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			width: 100%;
			max-width: 100%;
		}

		.slide__content{
			height: 726px;
			padding: 0 45px;
		}
		.slide__title{
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	.slide_number_one{
		.slide__img{
			position: absolute;
			top: 0;
			left: 50px;
			img{
				width: 440px;
			}
		}

		.slide__text{
			position: relative;
			left: auto;
			right: auto;
			top: 270px;
			bottom: auto;
			width: 100%;
			max-width: 100%;
			padding-top: 30px;
		}

		.slide__content{
			height: 726px;
			padding: 0 45px;
		}
		.slide__text .slide__marked-text{
			position: absolute;
			bottom: 100%;
			right: 0;
			margin: 0;
			width: 220px;
			text-align: right;
			font: bold 18px Calibri, sans-serif;
			text-transform: none;
		}

		.slide__title{
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	.slider_one .slick-arrow{
		top: 230px !important;

		&:after{
			color: #ccc;
			font-size: 34px;
		}

		&:hover:after{
			color:#0094db;
		}
	}

	.from-1180px{
		display: block;
	}
}

@media screen and (max-width:1110px) {
	.page-footer{
		padding: 37px 20px 33px;

		&__logo{
			width: 115px;
			height: 95px;
			fill: #fff;
		}

		&__contacts{
			width: 100%;
			text-align: left;
			padding-top: 32px;
			.contact{
				display: inline-block;
				margin-right: 7%;
				&__data{
					font-size: 24px;
					font-weight: 300;
				}
			}
		}

		&__first{
			margin-bottom: 30px;
		}

		&__second{
			position: static;
			margin-top: 0;
			width: 100%;
		}


		&__soc-icons{
			float: right;
			margin-top: 0;
		}

		&__soc-icon{
			width: 40px;
			height: 40px;
			line-height: 40px;
			/*border: 2px solid #fff;*/
			border-radius: 100%;
			text-align: center;
			font-size: 24px;
			color: #fff;

			&:hover{
				background: #fff;
				color: getBase('color-two');
			}
		}

		&__company span{
			display: inline-block;
			margin-right: 5px;
		}

		&__copyright,
		&__copyright,
		&__address,
		&__company{
			padding-right: 50px;
			width: 50%;
			margin-bottom: 22px;
			text-align: left;
		}
	}

	.br_footer{
		display: none;
	}
}

@media screen and (max-width:1060px) {
	.docs-block__title{
		width: 100%;
		margin-top: 25px;
		margin-left: 0;
		text-align: center;
		font-size: 30px;
	}

	.docs-block__slider{
		width: 100%;
		margin-top: 25px;
	}
}


@media screen and (max-width:950px) {
	.l-download__two,
	.l-download__one{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 40px;
	}


	.l-download{
		position: relative;
		padding-top: 80px;
		height: 470px;
	}

	.l-download__title{
		text-align: center;
		padding: 0;
	}

	.l-download__btn{
		z-index: 99;
		right: 405px;
		top: 235px;
		margin: 0;
		left: auto;
	}

	.l-download__broshure{
		position: absolute;
		margin: 0;
		right: 205px   !important;
		bottom: -25px;
		width: 556px;
		height: 345px;
		background-size: contain !important;
	}

	.l-download__line-one{
		padding-left: 30px;
		width: 262px;
	}

	.l-download__line-two{
		position: absolute;
		top: 113px;
		right: 30px;
		width: 215px;
		padding: 0;
	}


	.l-download__line-three{
		position: absolute;
		top: 192px;
		right: 34px;
		width: 165px;
		padding: 0;
	}

	.l-download__line-four{
		position: absolute;
		bottom: 10px;
		right: 30px;
		width: 230px;
		padding: 0;
	}
}



@media screen and (max-width:768px) {
	.page__row_header{
		background-size: cover;
	}

	.scroll-top{
		width: 45px;
		height: 45px;
		line-height: 48px;
		font-size: 20px;
	}

	.page-footer__menu{
		display: none;
	}

	.page-footer__menu-btn,
	.page-footer__copyright-mobile{
		display: block;
	}

	.page-footer__contacts{
		text-align: center;
		.contact{
			display: inline-block;
			margin-right: 20px;
			margin-left: 20px;
			&__data{
				font-size: 30px;
			}
		}
	}
	.page-footer__soc-icons{
		display: block;
		float: none;
		text-align: center;
	}

	.page-footer__copyright,
	.page-footer__copyright,
	.page-footer__address,
	.page-footer__company{
		display: none;
	}

	.page-footer__logo{
		height: 75px;
		width: 92px;
	}

	.page-footer{
		padding-bottom: 15px;
	}

	.slide_number_two{
		.slide__img{
			position: absolute;
			bottom: 0;
			top: auto;
			left: -70px;
			img{
				width: 600px;
			}
		}

		.slide__text{
			position: relative;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			width: 100%;
			max-width: 100%;
			font-size: 16px;
		}

		.slide__text .slide__marked-text{
			font-size: 18px;
		}
	}

	.slide_number_one{
		.slide__img{
			position: absolute;
			bottom: 170px;
			top: auto;
			left: 50%;
			margin-left: -205px;
			img{
				width: 410px;
			}
		}

		.slide__text{
			position: relative;
			left: auto;
			right: auto;
			top: 0;
			bottom: 0;
			width: 100%;
			max-width: 100%;
			font-size: 16px;
			width: 100%;
			padding-top: 50px;
		}
		.slide__text .slide__marked-text{
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			width: 100%;
			text-align: right;
			font: bold 16px Calibri, sans-serif;
			text-transform: none;
		}
	}

	.slide_style_one .slide__title_big,
	.slide_style_one .slide__title{
		font-size: 24px !important;
	}

	.slider_one{
		.slick-arrow{
			top: auto !important;
    		bottom: 628px !important;
			width: 50px;
			height: 70px;

			&:after{
				color: #ccc;
				font-size: 28px;
			}
		}

		.slick-prev{
			left: 0;
		}

		.slick-next{
			right: 0;
		}
	}
}


@media screen and (max-width:660px) {
	.l-download{
		position: relative;
		padding-top: 80px;
		height: 650px;
	}

	.l-download__title{
		text-align: center;
	}

	.l-download__btn{
		display: none;
	}

	.l-download__broshure{
		position: absolute;
		margin: 0;
		left: 50%;
		bottom: 70px;
		width: 453px;
		height: 276px;
		margin-left: -226px;
		background-size: contain !important;
	}

	.l-download__line-one,
	.l-download__line-two,
	.l-download__line-three{
		position: static;
		padding-left: 30px;
		width: 100%;
	}

	.l-download__line-two{
		margin-bottom: 5px;
	}

	.l-download__line-four{
		position: absolute;
		bottom: 10px;
		right: 0;
		width: 100%;
		text-align: center;
		padding: 0;
	}

	.l-download__context-btn{
		background: getBase('color-one');
	    color: #000;
	}

	.docs-block__title,
	.docs-block__slider{
		float: none;
		width: 100%;
	}

	.docs-block__title{
		font-size: 24px;
		margin-top: 20px;
		text-align: center;
	}

	.docs-block__slider{
		margin-top: 20px;
	}

	.form_contact-us{
		.form__section_one{
			width: 100%;
			float: none;
			padding-right: 0;
		}

		.form__section_two{
			float: none;
			width: 100%;
		}

		.form__section_three{
			padding-top: 0;
			text-align: center;
		}

		.form__section_row{
			overflow: hidden;
		}

		.form__section_four{
			margin-top: 10px;
			padding-left: 0;
			display: block;
		}

	}
}


@media screen and (max-width:570px) {
	.faq{
		&__question{
			font-size: 18px;
		}

		&__answer-content{
			padding-top: 20px;
			font-size: 15px;
		}
	}
}

@media screen and (max-width:510px), screen and (max-height: 515px) {
	.fixed-help{
		top: auto;
		right: auto;
		right: 0;
		bottom: 0;
		&__open{
			font-size: 18px;
			width: 111px;
			height: 46px;
			right: 0;
			bottom: 0;
			left: auto;
			top: auto;
			line-height: 46px;
			background-image: none;
			margin: 0;
			transform: rotate(0);
		}

		&__close{
			right: 0;
			bottom: 0;
			top: auto;
			left: auto;

			&:after{
				height: 100%;
				width: 8px;
				left: -4px;
				top: 0;
			}
		}


		&__content{
			top: auto;
			left: auto;
			right: 46px;
			bottom: 0;
		}
	}

}

@media screen and (max-width:480px) {

	.slide_number_two{

		.slide__content{
			height: 900px;
			padding: 0 10px;
		}


		.slide__marked-text{
			margin-top: 30px !important;
		}
	}

	.slide_number_one{
		.slide__img{
			position: absolute;
			bottom: 80px;
			top: auto;
			left: 50%;
			margin-left: -205px;
			img{
				width: 410px;
			}
		}

		.slide__text{
			position: relative;
			left: auto;
			right: auto;
			top: 0;
			bottom: 0;
			width: 100%;
			max-width: 100%;
			font-size: 16px;
			width: 100%;
			padding-top: 60px;
		}
		.slide__text .slide__marked-text{
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			width: 100%;
			text-align: right;
			font: bold 16px Calibri, sans-serif;
			text-transform: none;
		}

		.slide__content{
			height: 760px;
			padding: 0 10px;
		}
	}

	.slide_style_one .slide__title_big,
	.slide_style_one .slide__title{
		font-size: 18px;
	}

	.slider_one .slick-arrow{
		bottom: 300px;
	}

	.contact_two .contact__data{
		font-size: 30px;
	}

	.page-footer{
		padding: 20px 7px 20px;
	}

	.slider_docs{
		padding: 0 40px;
	}
}
