.formated-text{
	font-size: 16px;
	
	p{
		margin-top: 0;
		margin-bottom: 30px;
	}
	
	h4{
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 16px;
		font-weight: normal;
	}
}

.formated-text_detailed{
	p{
		margin-bottom: 18px;
	}
}