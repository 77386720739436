.reasons_wrapper {
	margin-bottom: 70px;
	.reasons_container {
		max-width: 1160px;
		margin:0 auto;
		position: relative;
		.reasons_title {
			font:normal 32px GothamMedium,sans-serif;
			color:#0094db;
			text-transform: uppercase;
			/*font-weight: 700;*/
			text-align: center;
			margin-bottom: 75px;
		}
		.reason_image {
			text-align: center;
			max-width: 582px;
			position: absolute;
			top:375px;
			left: 0;
			right: 0;
			margin:0 auto;
			height: 221px;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		.reasons_inner {
			font-size: 0;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: 120px;
				top:89px;
				bottom: 89px;
				width: 2px;
				background:url(../img/border-reason-vertical.png) 0 0 repeat-y;
			}
			&:after {
				content: "";
				position: absolute;
				right: 120px;
				top:89px;
				bottom: 89px;
				width: 2px;
				background:url(../img/border-reason-vertical.png) 0 0 repeat-y;
			}
			.row_reason {
				&.row_reason_top {
					text-align: center;
					min-height: 178px;
					position: relative;

					.reason_item {
						display: inline-block;
						vertical-align: top;
						margin:0 40px 80px;
					}
					&:before {
						content: "";
						height: 2px;
						background:url(../img/border-reason.png) 0 0 repeat-x;
						position: absolute;
						left: 125px;
						right: 125px;
						top:89px;
					}
				}
				&.row_reason_middle {
					&:after {
						content: "";
						height: 0;
						display: block;
						clear: both;
					}
					.reason_item {
						margin-bottom: 105px;
						&:first-child {
							float: left;
						}
						&:last-child {
							float: right;
						}
					}
				}
				&.row_reason_bottom {
					text-align: center;
					min-height: 178px;
					position: relative;
					.reason_item {
						display: inline-block;
						vertical-align: top;
						margin:0 40px 0;
					}
					&:before {
						content: "";
						height: 2px;
						background:url(../img/border-reason.png) 0 0 repeat-x;
						position: absolute;
						left: 125px;
						right: 125px;
						top:89px;
					}
				}
				.reason_item {
					width: 250px;
					height: 178px;
					position: relative;
					background:#fff;
					border:2px solid #d9d9d9;
					box-sizing:border-box;
					text-align: center;
					z-index: 2;
					.title {
						font:normal 16px/18px GothamBold;
						padding-top: 31px;
						padding-bottom: 16px;
					}
					.body {
						font:normal 16px/20px Calibri;
						color: #1c2330;
					}
					.reason_svg {
						left: 50%;
						margin-left: -30px;
						top:-30px;
						width: 60px;
						height: 60px;
						position: absolute;
						border-radius: 50%;
						background:#fff;
					}
					.js-svg_galka{
						width: 29px;
						height: 21px;
						color: #a4d435;
						margin-top: 17px;
					}
				}
			}
		}
	}
}

.reasons_wrapper4 {
	margin-bottom: 70px;
	.reasons_container4 {
		max-width: 1160px;
		margin:0 auto;
		position: relative;
		.reasons_title4 {
			font:normal 32px GothamMedium,sans-serif;
			color:#0094db;
			text-transform: uppercase; 
			text-align: center;
			margin-bottom: 75px;
		}
		.reason_image4 {
			text-align: center;
			max-width: 582px;
			position: absolute;
			top:406px;
			left: 0;
			right: 0;
			margin:0 auto;
			height: 221px;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		.reasons_inner4 {
			font-size: 0;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: 120px;
				top:89px;
				bottom: 89px;
				width: 2px;
				background:url(../img/border-reason-vertical.png) 0 0 repeat-y;
			}
			&:after {
				content: "";
				position: absolute;
				right: 120px;
				top:89px;
				bottom: 89px;
				width: 2px;
				background:url(../img/border-reason-vertical.png) 0 0 repeat-y;
			}
			.row_reason4 {
				&.row_reason_top4 {
					text-align: center;
					min-height: 178px;
					position: relative;

					.reason_item4 {
						display: inline-block;
						vertical-align: top;
						margin:0 40px 60px;
						box-sizing:border-box;
						padding-bottom: 20px;
						min-height: 168px;
					}
					&:before {
						content: "";
						height: 2px;
						background:url(../img/border-reason.png) 0 0 repeat-x;
						position: absolute;
						left: 125px;
						right: 125px;
						top:89px;
					}
				}
				&.row_reason_middle4 {
					&:after {
						content: "";
						height: 0;
						display: block;
						clear: both;
					}
					.reason_item4 {
						margin-bottom: 28px;
						width: 248px;
						min-height: 250px;
						box-sizing:border-box;
						padding-bottom: 20px;
						&:first-child {
							float: left;
						}
						&:last-child {
							float: right;
						}
					}
				}
				&.row_reason_bottom4 {
					text-align: center;
					min-height: 178px;
					position: relative;
					.reason_item4 {
						display: inline-block;
						vertical-align: top;
						margin:0 40px 0;
						padding-bottom: 20px;
						min-height: 157px;
					}
					&:before {
						content: "";
						height: 2px;
						background:url(../img/border-reason.png) 0 0 repeat-x;
						position: absolute;
						left: 125px;
						right: 125px;
						top:89px;
					}
				}
				.reason_item4 {
					width: 540px;
					min-height: 178px;
					position: relative;
					background:#fff;
					border:2px solid #d9d9d9;
					box-sizing:border-box;
					text-align: center;
					z-index: 2;
					.title4 {
						font:normal 16px/18px GothamBold;
						padding-top: 31px;
						padding-bottom: 16px;
					}
					.body4 {
						font:normal 16px/20px Calibri;
						color: #1c2330;
					}
					.reason_svg4 {
						left: 50%;
						margin-left: -30px;
						top:-30px;
						width: 60px;
						height: 60px;
						position: absolute;
						border-radius: 50%;
						background:#fff;
					}
					.js-svg_galka{
						width: 29px;
						height: 21px;
						color: #a4d435;
						margin-top: 17px;
					}
				}
			}
		}
	}
}


.portfolio_slider {
	position: relative;
	.portfolio_slider_title {
		text-align: center;
		font:normal 32px GothamMedium,sans-serif;
		color:#0094db;
		text-transform: uppercase;
		 
	}
	.port_slider {
		position: relative;

		&.port_slider_tablet {
			display: none;
			 padding-bottom: 20px;
			@media (min-width:768px) and (max-width:1001px) {
				display: block;

			}
		}
	}
	.owl-dots {
		outline: 0;
		text-align: center;
		font-size: 0;
		position: absolute;
		margin-top: 20px;
		margin-bottom: 20px;
		left: 0;
		right: 0;

		z-index: 222;
		     height: 40px;
		 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		&:hover, &:focus {
			outline: 0;
			background: transparent;
		}
		.owl-dot {
			display: inline-block;
			vertical-align: top;

			margin:0 6px;
			cursor: pointer;
			z-index: 3444;
			position:relative;
			overflow: hidden;

			    @media (max-width:767px)  {
				/*	 padding: 5px;*/

				}
			span {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				box-sizing:border-box;
				border:1px solid #c5c5c5;
			}
			&.active span{
				border-color: #0094db;
			}
		}
	}
	.owl-nav {
		font-size: 0;
		div {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.2);
			cursor: pointer;
			&:hover {
				background: rgba(255, 255, 255, 0.7);
				span {
					svg {
						color: #0094db;
					}
				}
			}
			&:first-child {
				span {
					right: 19px;
					transform: rotate(180deg);
				}
			}
			span {
				position: absolute;
				top: 14px;
				right: 16px;
				width: 14px;
				height: 23px;
				svg {
					color:#fff;
				}
			}
		}
	}
	.owl-prev {
		position: absolute;
		top:50%;
		margin-top: -40px;
		left: 5px;
	}
	.owl-next {
		position: absolute;
		top:50%;
		margin-top: -40px;
		right: 5px;
	}
}

.portfolio_slider {
	max-width: 1920px;
	margin:0 auto;
	.portfolio_slider_title {
		margin-bottom: 30px;
	}
	.owl-item {
		display: inline-block !important;
		vertical-align: top;
	}
}

.slider_athlets,
.slider_one,
.slider_athlets_fulltime  {
	ul.slick-dots {
		text-align: center;
		font-size: 0;
		position: absolute;
		left: 0;
		bottom:47px;
		right: 0;
		li {
			display: inline-block;
			vertical-align: top;
			margin:0 3px;
			border-radius: 50%;
			-webkit-box-shadow:3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
			-moz-box-shadow:3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
			box-shadow:3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
			background:#f4f4f4;
			overflow: hidden;
			position: relative;
			cursor: pointer;
			&:before {
				border:1px solid #cdcdcd;
				content: "";
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				top:0;
				bottom: 0;
				z-index: 1;

			}
			&:first-child {
				button {
					/*background-position: -8px 5px !important;
					background-size: 100% 100% !important;*/
				}
			}
			button {
				width: 56px;
				height: 56px;
				background:none;
				box-sizing:border-box;
				outline: none;
				background-size:cover !important;
				overflow: hidden;
				&:hover {
					outline: none;
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
		li.slick-active {
			&:before {
				border:2px solid #0393d9;
				content: "";
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				top:0;
				bottom: 0;
				z-index: 1;
			}
		}

	}
}

.slider_one {
	ul.slick-dots {
		bottom:285px;
		li {
			>button {
				background-size: cover !important
			}
		}
	}
}

.slider_athlets {
	ul.slick-dots {
		bottom:-27px;
 		@media (min-width: 768px) {
			bottom:7px;
		}
	}

}


.header__menu, .header__logo {
	position: relative;
	z-index: 2;
}

.header_biz .header__second {
	padding-top: 0;
	position: absolute;
	left: 0;
	right: 0;
	top:0;
	bottom: 0;
	font-size: 0;
	&:after {
		content: '';
		width: 0;
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	}
	.header__second_inner {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		width: 100%;
	}
	.header__title {
		padding: 0;
		top:auto;
		text-shadow:none;
		font-size: 48px;
		line-height: 50px;
		font-family: Gotham;
		margin-bottom: 0;
		position: static;
		left: auto;
	}
	.header__second_links {
		padding-top: 50px;
		font-size: 0;
		a.header_second__link {
			width: 100%;
			max-width: 388px;
			display: inline-block;
			vertical-align: top;
			box-sizing:border-box;
			font: 700 22px/70px Calibri, sans-serif;
			color:#fff;
			height: 74px;
			border:2px solid #fff;
			text-align: center;
			margin:0 33px;
			background:rgba(0,0,0, .46);

			&:hover {
				border-color:#8dd50e;
			}
			&:first-child {
				max-width: 405px;
			}
		}
	}
}

.header__third {
	overflow: visible;
	.header__third_links {
		width: 230px;
		left: 50%;
		margin-left: -115px;
		text-align: center;
		bottom: 25px;
		position: absolute;
		font:normal 12px/16px 'Calibri';
		color:#fff;
		transition: all 0.3s ease;
		a {
			color: #fff;
			transition: all 0.3s ease;
			&:hover {
				color: #8dd50e;
			}
		}
	}
}

.header__second_inner {
	.header__third_links {
		text-align: center;
		font:normal 12px/16px 'Calibri';
		color:#fff;
		transition: all 0.3s ease;
		padding-top: 70px;
		display: none;
		a {
			color: #fff;
			transition: all 0.3s ease;
			&:hover {
				color: #8dd50e;
			}
		}
	}
}




@media screen and (max-width: 1160px) {
	.reasons_wrapper {
		.reasons_container {
			.reason_image {
				max-width: 450px;
			}
		}
	}
	.reasons_wrapper4 {
		.reasons_container4 {
			.reason_image4 {
				max-width: 450px;
			}
		}
	}
}

@media screen and (max-width: 1040px) {
	.portfolio_slider {
		.portfolio_slider_title {
			font-size: 30px;
			line-height: 34px;
		}
		.item_sl img {
		    width: 100%;
		}
	}
	.reasons_wrapper {
		.reasons_container {
			.reasons_title {
				font-size: 30px;
				line-height: 34px;
			}
		}
	}
	.reasons_wrapper4 {
		.reasons_container4 {
			.reasons_title4 {
				font-size: 30px;
				line-height: 34px;
			}
		}
	}
}



@media screen and (max-width: 991px) {
	.reasons_wrapper {
		.reasons_container {
			.reason_image {
				position: static;
				top:auto;
				left: auto;
				right: auto;
				max-width: 100%;
				margin-bottom: 93px;
				box-sizing:border-box;
				padding: 0 60px;
				height: auto;
			}
			.reasons_inner {
				padding: 0 60px;

				&:before {
					left: 185px;
					top:0;
					bottom: 0;
				}
				&:after {
					right: 185px;
					top:0;
					bottom: 0;
				}
				.row_reason {
					&:after {
						content: "";
						height: 0;
						display: block;
						clear: both;
					}
					&.row_reason_top {
						padding-top: 58px;
						&:before {
							top:0;
						}
						.reason_item  {
							margin:0 0 79px 0;
							&:first-child {
								float: left;
							}
							&:last-child {
								float: right;
							}
						}
					}
					&.row_reason_bottom {
						padding-bottom: 58px;
						&:before {
							top:auto;
							bottom: 0;
						}
						.reason_item  {
							margin:0 0 0 0;
							&:first-child {
								float: left;
							}
							&:last-child {
								float: right;
							}
						}
					}
				}
			}
		}
	}
	.reasons_wrapper4 {
		.reasons_container4 {
			.reason_image4 {
				position: static;
				top:auto;
				left: auto;
				right: auto;
				max-width: 100%;
				margin-bottom: 93px;
				box-sizing:border-box;
				padding: 0 60px;
				height: auto;
			}
			.reasons_inner4 {
				padding: 0 60px;

				&:before {
					left: 185px;
					top:0;
					bottom: 0;
				}
				&:after {
					right: 185px;
					top:0;
					bottom: 0;
				}
				.row_reason4 {
					&:after {
						content: "";
						height: 0;
						display: block;
						clear: both;
					}
					&.row_reason_top4 {
						padding-top: 58px;
						&:before {
							top:0;
						}
						.reason_item4  {
							margin:0 0 79px 0;
							width: 100%;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
					&.row_reason_middle4 {
						.reason_item4  {
							margin-bottom: 78px;
						}
					}
					&.row_reason_bottom4 {
						padding-bottom: 58px;
						&:before {
							top:auto;
							bottom: 0;
						}
						.reason_item4  {
							margin:0 0 0 0;
							width: 100%;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 940px) {
	.header_biz .header__second {
		.header__second_links {
			a.header_second__link {
				width: 100%;
				max-width: 360px;
				display: block;
				vertical-align: top;
				box-sizing:border-box;
				font: 700 20px/70px Calibri, sans-serif;
				color:#fff;
				height: 74px;
				border:2px solid #fff;
				text-align: center;
				margin:0 auto 25px;
				background:rgba(0,0,0, .46);

				&:hover,&:active  {
					border-color:#8dd50e;
				}
				&:first-child {
					max-width: 360px;
				}
			}
		}
	}
}

@media screen and (max-width: 860px) {
	.header_biz .header__second {
		padding:0 0;
		.header__second_inner {
			.header__title {
				font-size: 40px;
				line-height: 42px;
			}
		}
		.header__second_links {
			padding-top: 70px;
		}
	}
}

@media screen and (max-width: 768px) {
	.slider_athlets_fulltime {
		.athlet-review__text {
			padding-bottom: 123px !important;
		}
	}
	.portfolio_slider {
		.port_slider {
			.owl-stage-outer {
				.owl-item {
					.item_sl {
						height: 490px;
						overflow: hidden;
						position: relative;
						width: 100%;
						img {
							max-width: none;
						    height: 790px;
						    width: 790px;
						    position: absolute;
						    left: 0;
						    /* margin-left: -960px; */
						    top: 50%;
						    margin-top: -380px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 645px) {
	.reasons_wrapper {
		.reasons_container {
			.reasons_title {
				padding: 0 15px;
				margin-bottom: 40px;
			}
			.reason_image {
				padding: 0 15px;
				margin-bottom: 50px;
			}
			.reasons_inner {
				padding: 0 35px;
				&:before {
					left: 50%;
					margin-left: -1px;
				}
				&:after {
					display: none;
				}
				.row_reason  {
					&.row_reason_top {
						padding-top: 0;
						&:before {
							display: none;
						}
						.reason_item {
							display: block;
							margin:0 auto 64px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
					&.row_reason_middle {
						padding-top: 0;
						&:before {
							display: none;
						}
						.reason_item {
							display: block;
							margin:0 auto 64px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
					&.row_reason_bottom {
						padding-top: 0;
						padding-bottom: 0;
						&:before {
							display: none;
						}
						.reason_item {
							display: block;
							margin:0 auto 64px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
								margin-bottom: 0;
							}
						}
					}
					.reason_item {
						display: block;
					}
				}
			}
		}
	}
	.reasons_wrapper4 {
		.reasons_container4 {
			.reasons_title4 {
				padding: 0 15px;
				margin-bottom: 40px;
			}
			.reason_image4 {
				padding: 0 15px;
				margin-bottom: 50px;
			}
			.reasons_inner4 {
				padding: 0 35px;
				&:before {
					left: 50%;
					margin-left: -1px;
				}
				&:after {
					display: none;
				}
				.row_reason4  {
					&.row_reason_top4 {
						padding-top: 0;
						&:before {
							display: none;
						}
						.reason_item4 {
							display: block;
							margin:0 auto 64px;
							width: 248px;
							padding: 0 15px 20px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
					&.row_reason_middle4 {
						padding-top: 0;
						&:before {
							display: none;
						}
						.reason_item4 {
							display: block;
							margin:0 auto 64px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
							}
						}
					}
					&.row_reason_bottom4 {
						padding-top: 0;
						padding-bottom: 0;
						&:before {
							display: none;
						}
						.reason_item4 {
							display: block;
							margin:0 auto 64px;
							width: 248px;
							padding: 0 15px 20px;
							&:first-child {
								float: none;
							}
							&:last-child {
								float: none;
								margin-bottom: 0;
							}
						}
					}
					.reason_item4 {
						display: block;
					}
				}
			}
		}
	}
}

@media screen and (max-width:570px) {
	.portfolio_slider {
		.portfolio_slider_title {
			font-size: 24px;
			line-height: 28px;
		}
	}
	.reasons_wrapper {
		.reasons_container {
			.reasons_title {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
	.reasons_wrapper4 {
		.reasons_container4 {
			.reasons_title4 {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
}


@media screen and (max-width: 480px) {
	.header__third {
		overflow: visible;
	}
	.header__third {
		.header__third_links {
			bottom: 120%;
			display: none;
		}
	}
	.header__second_inner {
		.header__third_links {
			display: block;
			padding-top: 	0px;
		}
	}
	.header_biz .header__second{
		position: static;
		left: auto;
		right:auto;
		top:auto;
		bottom: auto;
	}
	.header {
		/*height: auto;*/
		padding-bottom: 105px;
	}
}


@media screen and (max-width: 425px) {
	.portfolio_slider {
		.port_slider {
			.owl-stage-outer {
				.owl-item {
					.item_sl {
						height: 400px;
						overflow: hidden;
						position: relative;
						width: 100%;
						img {
							    max-width: none;
    height: 790px;
    width: 790px;
    position: absolute;
    left: 50%;
    margin-left: -380px;
    top: 50%;
    margin-top: -380px;
						}
					}
				}
			}
		}
	}
}


@media screen and (max-width: 425px) {
	.header_biz .header__second {
		.header__second_inner {
			display: block;
			margin-top: 130px;
			.header__title {
				font-size: 18px;
			}
		}
		.header__second_links {
			padding-top: 15px;
			a.header_second__link {
				width: 100%;
				max-width: 245px;
				font-size: 12px;
				line-height: 53px;
				height: 55px;
				margin-bottom: 15px;
				&:first-child {
					max-width: 245px;
				}
			}
		}
	}

}
