.shop_middle_banner {
	background:url(../img/shop_banner_bg.jpg) 50% 0 no-repeat;
	/*background-size: cover;*/
	.shop_middle_banner__container {
		max-width: 1160px;
		margin:0 auto;
		min-height: 368px;
		position: relative;
		.shop_middle_banner__caption {
			position: absolute;
			left: 50%;
			margin-left: -200px;
			z-index: 3;
			padding-top: 34px;
			width: 357px;
			&:before {
				content: "";
				position: absolute;
				left: 37px;
				top:0;
				width: 285px;
				height: 368px;
				background:url(../img/fk-pattern-dark-small.jpg);
				transform: skewX(-12deg);
				z-index: -1;
			}
			.banner_caption_logo {
				margin:0 0 40px 149px;
				width: 90px;
				height: 87px;
			}
			.banner_caption__title {
				color:#fff;
				text-transform: uppercase;
				padding: 0 60px 0 66px;
				font:normal 18px 'Calibri';
				margin-bottom: 7px;
			}
			.banner_caption__list {
				list-style: none;
				padding: 0 60px 10px 66px;
				margin:0;
				counter-reset: list;
				&.banner_caption__list_bottom {
					padding-left: 45px;
				}
				li {
					color:#fff;
					font:normal 16px 'Calibri';
					&:before {
						counter-increment: list;
						content: counter(list) ". ";
					}
					span {
						color:#8fd400;
						font-weight: bold;
					}
				}
			}
		}

		.shop_banner__slider {
			position: relative;
			z-index: 2;
			.shop_banner__item {
				position: relative;
				padding: 47px 0 0 0;
				box-sizing:border-box;
				min-height: 351px;
				.shop_banner__top_price {
					position: absolute;
					right: 0;
					top:35px;
					width: 88px;
					height: 88px;
					line-height: 88px;
					background:#e8e8e6;
					border-radius: 50%;
					-webkit-box-shadow: -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
					-moz-box-shadow:    -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
					box-shadow:         -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
					text-align: center;
					z-index: 3;
					span.price_text_height {
						display: inline-block;
						vertical-align: middle;
						font:normal 16px 'Calibri';
						color: #000000;
						span.current_price {
							display: block;
							color: #0393d8;
							font:bold 20px 'Calibri';
						}
					}
				}
				.shop_banner__title {
					font:normal 30px/32px GothamBold;
					color:#000000;
					text-transform: uppercase;
				}
				.shop_banner__body {
					max-width: 240px;
					font:normal 16px 'Calibri';
					padding-top: 7px;
				}
				.slide_pic {
					display: none;
				}
				.shop_banner__link {
					width: 142px;
					height: 49px;
					box-sizing:border-box;
					border:2px solid #0f101f;
					position: absolute;
					left: 50%;
					bottom: 39px;
					margin-left: -71px;
					text-align: center;
					text-transform: uppercase;
					font:normal 18px/47px 'Calibri';
					transition: all 0.5s ease;
					&:hover {
						color:#8fd400;
						background:#0f101f;
					}
				}
			}
			.owl-nav {
				display: none !important;
			}
			.owl-item {
				display: inline-block !important;
				vertical-align: top;
			}
			.owl-dots {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}
	}
}



.rotate-phone {
	background:#000 !important;
	text-align: center;
	&:after {
		content: '';
		width: 0;
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	}
	.rotate_inner {
		width: 100%;
		max-width: 410px;
		display: inline-block;
		vertical-align: middle;
		.rotate_svg {

		}
		.rotate_bottom {
			text-align: left;
			font:normal 36px/38px GothamBold;
			color:#fff;
			text-transform: uppercase;
			position: relative;
			.section_top_svg {
				position: absolute;
				right: 0;
				top:0;
				width: 36px;
				height: 25px;
				transform: scaleX(-1);
			}
			.section_top {
				margin-top: 5px;
			}
			.section_bottom {
				font: 200 18px Calibri;
				text-transform: none;
				padding-left: 28px;
				position: relative;
				padding-top: 0;
				margin-top: 5px;
				.section_bottom_svg {
					width: 18px;
					height: 18px;
					position: absolute;
					left: 0;
					top:3px;
				}
			}
		}
	}

}

@media screen and (max-width: 991px) {
	.shop_middle_banner {
		background:url(../img/slider_mobile_bg.jpg) 50% 0 no-repeat;
		background-size: cover;
		.shop_middle_banner__container {
			.shop_middle_banner__caption {
				width: 400px;
				margin:0;
				left: -76px;
				&:before {
					width: 335px;
				}
				.banner_caption_logo {
					margin-left: 108px;
				}
				.banner_caption__title {
					padding-left: 108px;
				}
				.banner_caption__list {
					padding-left: 108px;
					&.banner_caption__list_bottom {
						padding-left: 108px;
					}
				}
			}
			.shop_banner__slider {
				padding-left: 323px;
				.shop_banner__item {
					.shop_banner__top_price {
						right: 25px;
					}
					.shop_banner__title {
						padding-left: 35px;
					}
					.shop_banner__body {
						padding-left: 35px;
						max-width: 280px;
					}
					.slide_pic {
						display: block;
						right: 0;
						top: 159px;
						left: 0;
						position: absolute;
						text-align: center;
					}
					.shop_banner__link {
						bottom: 24px;
						margin-left: -105px;
					}
				}
				.owl-dots {
					text-align: center;
					font-size: 0;
					bottom: 15px;
					.owl-dot {
						display: inline-block;
						vertical-align: top;
						margin:0 6px;
						width: 10px;
						height: 10px;
						box-sizing:border-box;
						border-radius: 50%;
						border:1px solid #c3c3c3;
						background:#fff !important;
						&.active {
							border-color:#0a97db;
							background:#fff;
						}
					}
				}
			}
		}
	}
}

/*
@media (max-width: 767px) and (orientation: landscape) {
	.rotate-phone {
		display: block;
	}
	body {
		height: 100%;
		overflow: hidden !important;
		width: 100%;
		position: fixed;
	}
}
*/

@media screen and (max-width: 700px) {
	.shop_middle_banner {
		overflow: hidden;
		.shop_middle_banner__container {
			min-height: 496px;
			.shop_middle_banner__caption {
				padding-top:23px;
				left: 0;
				right: 0;
				width: auto;
				height: 225px;
				&:before {
					width: 100%;
					top:-50%;
					height: 225px;
					transform: skewY(10deg);
					left: 0;
					margin-top:50px;
				}
				.banner_caption_logo {
					margin:0;
					position: absolute;
					left: 15px;
					top:29px;
					width: 66px;
					height: 63px;
				}
				.banner_caption__title {
					padding-left: 100px;
					padding-right: 0;
					font-size: 14px;
					margin-bottom: 3px;
				}

				.banner_caption__list {
					padding-left: 100px;
					padding-right: 0;
					padding-bottom: 5px;
					display: inline-block;
					vertical-align: top;
					&.banner_caption__list_bottom {
						padding-left: 20px;
					}
					li {
						font-size: 14px;
					}
				}
			}
			.shop_banner__slider {
				padding: 0;
				.shop_banner__item {
					padding-top: 225px;
					min-height: 496px;
					.shop_banner__top_price {
						width: 65px;
						height: 65px;
						line-height: 65px;
						.price_text_height {
							.price_inner {
								font-size: 12px;
								.current_price {
									font-size: 15px;
								}
							}
						}
					}
					.shop_banner__title {
						padding-left: 15px;
						font-size: 18px;
					}
					.shop_banner__body {
						padding-left: 15px;
						font-size: 14px;
						box-sizing:border-box;
						max-width: 210px;
					}
					.shop_banner__top_price {
						top:225px;
					}
					.slide_pic {
						top:315px;
						left: 15px;
					}
					.shop_banner__link {
						bottom: 36px;
					}
					.shop_banner__link {
						margin-left: -71px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 530px) {
	.shop_middle_banner {
		.shop_middle_banner__container {
			.shop_middle_banner__caption {
				.banner_caption__list {
					&.banner_caption__list_bottom {
						position: absolute;
						right: 20px;
						top: 85px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 430px) {
	.shop_middle_banner {
		.shop_middle_banner__container {
			.shop_middle_banner__caption {
				&:before {
					margin-top: 70px;
				}
				.banner_caption__list {
					&.banner_caption__list_bottom {
						top: 105px;
					}
				}
			}
		}
	}
}



@media screen and (max-width:600px) {
	.rotate-phone {
		.rotate_inner {
			width: 100%;
			max-width: 250px;
			display: inline-block;
			.rotate_bottom {
				font:normal 22px/24px GothamBold;
				.section_top_svg {
					position: absolute;
					right: 0;
					top:0;
					width: 22px;
					height: 22px;
					transform: scaleX(-1);
				}
				.section_bottom {
					font: 200 12px Calibri;
					text-transform: none;
					padding-left:17px;
					position: relative;
					padding-top: 2px;
					.section_bottom_svg {
						width: 11px;
						height: 11px;
						position: absolute;
						left: 0;
						top:3px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 321px) {
	.shop_middle_banner {
		.shop_middle_banner__container {
			.shop_middle_banner__caption {
				.banner_caption__list {
					display: block;
					&.banner_caption__list_bottom {
						position: static;
						right: auto;
						top:auto;
						display: block;
						padding-left: 100px;
					}
				}
			}
		}
	}
}