.athlet-review{
	@extend .clearfix;
	position: relative;
	padding: 0 10px;

	&__parallax_one,
	&__parallax_two{
		font: bold 160px/160px getBase('font-heading');
		color: #f0f0f0;
		position: absolute;
		white-space: nowrap;
		transform-style: flat;
	}

	&__parallax_one{
		top: -20px;
		right: -50px;
	}

	&__parallax_two{
		top: 140px;
		right: 130px;
	}

	&__about-info{
		position: absolute;
		left: 0;
		top: 0;
		width: 225px
	}

	&__about-title{
		font-size: 18px;
		text-transform: uppercase;
	}
	&__about-text{
		font-size: 14px;
		color: #939393;
	}

	&__about,
	&__text{
		width: 50%;
	}

	&__title{
		margin-bottom: 30px;
		font:  36px GothamMedium,sans-serif;
		color: getBase('color-two');
		text-transform: uppercase;
	}

	&__about{
		float: left;
		position: relative;
		img{
			max-width: 100%;
		}
	}

	&__text{
		float: right;
		padding-left: 50px;
		padding-right: 50px;
		padding-bottom: 15px;
		font-size: 18px;
		text-align: left;

		p{
			margin-top: 0;
		}
	}

	&__soc-comment{
		margin-top: 80px;
		min-height: 155px;
	}

	&__video-play{
		padding-top: 30px;
		position: relative;
	}

	&__video-play-btn{
		position: absolute;
		left: 32%;
		top: 25%;
		cursor: pointer;
		&:after{
			content: '';
			display: block;
			width: 94px;
			height: 94px;
			background-image: url(../img/icons/play-white.png);
		}

		&:hover:after{
			background-image: url(../img/icons/play-blue.png);
		}
	}
}

.athlet-review_reverse{
	.athlet-review__parallax_one{
		right: 20px;
	}

	.athlet-review__parallax_two{
		right: 120px;
	}
	.athlet-review__video-play-btn{
		top: auto;
		bottom: 35%;
		right: auto;
		left: 45%;
	}
}

.athlet-review_nikita{
	.athlet-review__video-play-btn{
		top: auto;
		bottom: 35%;
		right: auto;
		left: 35%;
	}
}

.athlet-review_fulltime,
.athlet-review_fulltime2{
	padding: 80px 5px 0;
	overflow: hidden;

	.athlet-review__content{
		height: 100%;
		@extend .clearfix;
		position: relative;
		max-width: getBase('site-width');
		margin-left: auto;
		margin-right: auto;
	}

	.athlet-review__text{
		padding-bottom: 70px;
		height: 100%;
	}
}

.athlet-review_fulltime2{
	padding-top: 0;
}



@media screen and (min-width: 1025px) {
	.home .athlet-review_reverse{
		margin-bottom: 80px;
	}

	.athlet-review_fulltime{
		margin-bottom: 0;
	}

	.athlet-review_reverse,
	.athlet-review_nikita{
		.athlet-review__parallax_one{
			top: -20px;
			right: auto;
			left: 0;
		}

		.athlet-review__parallax_two{
			top: 140px;
			right: auto;
			left: 0;
		}

		.athlet-review__about{
			float: right;
		}

		.athlet-review__text{
			padding-left: 0;
			padding-right: 100px;
			padding-top: 60px;
			float: left
		}

		.athlet-review__about-info{
			top: 60px;
			left: 50%;
			text-align: right;
			width: 200px;
		}

		.athlet-review__video-play{
			padding: 0;
			padding-left: 40px;
		}

		.athlet-review__video-play-btn{
			right: 48%;
			top: 40%;
		}
	}

	.athlet-review_nikita{
		height: 700px;

		.athlet-review__about{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 60%;
			margin-right: -10%;

			img{
				width: 100%;
			}
		}

		.athlet-review__about-info{
			top: 120px;
			right: 30px;
			left: auto;
			text-align: right;
			width: 200px;
		}

		.athlet-review__text{
			padding-top: 90px;
			padding-right: 20px;
		}

		.athlet-review__video-play-btn{
			right: 48%;
			top: 40%;
		}
	}

	.athlet-review_konstantin{
		height: 700px;
		.athlet-review__about{
			position: absolute;
			bottom: 0;
			width: 60%;
			margin-left: -10%;

			img{
				width: 100%;
			}
		}
	}

	.athlet-review_max{
		height: 700px;
		.athlet-review__about{
			position: absolute;
			bottom: 0;
			right: 0;

			img{
				width: 100%;
			}
		}

		.athlet-review__about-info{
			top: 0;
			width: 290px;
			left: auto;
			right: 50%;
			margin-right: -250px;
		}

		.athlet-review__text{
			padding-top: 90px;
			padding-right: 20px;
		}
	}
}


@media screen and (max-width: 1024px) {
	.athlet-review{
		padding: 0;
		overflow: hidden;
	}

	.athlet-review__about{
		margin-left: -10%;
		width: 60%;
	}

	.athlet-review__about-info{
		position: static;
		text-align: left;
		width: 100%;
		max-width: 230px;
		margin-bottom: 30px;
	}

	.athlet-review__about-text{
		color: #000;
	}

	.athlet-review__text{
		padding-left: 0;
		padding-right: 20px;
	}

	.athlet-review__comment{
		font-size: 16px;
	}

	.athlet-review__soc-comment{
		margin-top: 30px;
	}

	.athlet-review__parallax_one,
	.athlet-review__parallax_two{
		font-size: 90px;
		line-height: 90px;
		left: 48%;
	}

	.athlet-review__parallax_one{
		top: 20px;
	}

	.athlet-review__parallax_two{
		top: 120px;
	}

	.athlet-review__video-play{
		padding: 0;
	}

	.athlet-review_reverse{
		.athlet-review__parallax_one{
			left: 35%;
		}

		.athlet-review__parallax_two{
			left: 40%;
		}
	}
}



@media screen and (min-width: 700px) and (max-width: 1024px) {
	.athlet-review_nikita{
		.athlet-review__text{
			float: left;
			padding-left: 10px;
		}

		.athlet-review__about{
			position: absolute;
			bottom: 29px;
			right: 0;
			margin-right: -10%;
			margin-left: 0;
		}

		.athlet-review__parallax_one{
			top: 0;
			left: 0;
			right: auto;
		}

		.athlet-review__parallax_two{
			top: 100px;
			left: 0;
			right: auto;
		}
	}
}
@media screen and (max-width: 700px) {
	.athlet-review__about{
		margin-left: 0;
		width: 100%;
		float: none;

		img{
			max-width: none;
			height: 100%;
			display: inline-block;

		}
	}

	.athlet-review__video-play{
		height: 390px;
		text-align: right;
	}

	.athlet-review_reverse{
		.athlet-review__video-play{
			text-align: left;
		}
	}

	.athlet-review__text{
		width: 100%;
		float: none;
		padding: 0;
		padding-bottom: 20px;
	}

	.athlet-review__about-info{
		background: #f7f7f7;
		text-align: center;
		width: 100%;
		max-width: 100%;
		padding: 15px 10px;
	}

	.athlet-review__comment,
	.athlet-review__soc-comment{
		padding: 0 10px;
	}

	.athlet-review__parallax_one,
	.athlet-review__parallax_two{
		font-size: 79px;
		line-height: 79px;
	}

	.athlet-review__parallax_one{
		top: 240px;
		left: auto;
		right: 0;
	}

	.athlet-review__parallax_two{
		top: 310px;
		left: 0;
		right: auto;
	}

	.athlet-review__title{
		text-align: center;
		margin-top: 20px;
		font-size: 24px
	}

	.athlet-review_reverse{
		.athlet-review__parallax_one{
			top: 0;
			left: auto;
			right: -80px;
		}

		.athlet-review__parallax_two{
			top: 79px;
			right: auto;
			left: 0;
		}
	}

	.athlet-review_nikita{
		.athlet-review__parallax_one{
			top: 0;
			left: auto;
			right: 20px;
		}

		.athlet-review__parallax_two{
			top: 79px;
			right: auto;
			left: 0;
		}
	}
}

@media screen and (max-width: 500px) {
	.athlet-review__video-play{
		height: 300px;
	}
	.athlet-review__parallax_one,
	.athlet-review__parallax_two{
		font-size: 50px;
		line-height: 50px;
	}

	.athlet-review__parallax_one{
		top: 200px;
	}

	.athlet-review__parallax_two{
		top: 250px;
	}

	.athlet-review_reverse{
		.athlet-review__parallax_one{
			top: 0;
			left: auto;
			right: -30px;
		}

		.athlet-review__parallax_two{
			top: 50px;
			right: auto;
			left: 0;
		}
	}
}


@media screen and (max-width: 385px) {
	.athlet-review__video-play{
		height: 250px;
	}
	.athlet-review__parallax_one{
		top: 150px;
	}

	.athlet-review__parallax_two{
		top: 200px;
	}
}


@media screen and (max-width: 340px) {
	.athlet-review__parallax_one{
		top: 160px;
	}

	.athlet-review__parallax_two{
		top: 210px;
	}
}
