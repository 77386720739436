@keyframes headerShowMore{
	0%,70%,100% {
		transform: translateY(0px)
	}

	85% {
		transform: translateY(10px)
	}
}

@keyframes clockwise{
  to {transform: rotate(360deg) translatez(0);}
}