
.second-level-menu {
    background:rgb(214, 214, 214);
    position: fixed;
width: 100%;
    height: 43px;
    z-index: 9999;
    right: 0;
    top:0;
    left: 0;
    opacity: 0;


}
.portfolio-item{
	height: auto;
	position: relative;
	cursor: pointer;
	overflow: hidden;

	&__img{
		width: 100%;
	}

	&__info{
		@include overlay(rgba(0, 0, 0, 0.76));
		color: #fff;
		display: none;
		padding: sizeRelativePageWidth(50px, 1920px) sizeRelativePageWidth(36px, 1920px);

	}

	&__title{
		font-family:  getBase('font-heading');
		line-height: 1.4;
		text-decoration: underline;
		overflow: hidden;
		@include sizeRelativePageWidth('font-size', 24px, 1920px, 12px);
		@include sizeRelativePageWidth('margin-bottom', 26px, 1920px, 8px);
	}

	&__tags{
		@include sizeRelativePageWidth('margin-bottom', 36px, 1920px, 14px);
	}
	&__tag{
		display: inline-block;
		background: #fff;
		color: #000;
		font-weight: 300;
		text-transform: uppercase;
		@include sizeRelativePageWidth('margin-right', 10px, 1920px, 3px);
		@include sizeRelativePageWidth('height', 40px, 1920px, 18px);
		@include sizeRelativePageWidth('line-height', 40px, 1920px, 18px);
		@include sizeRelativePageWidth('padding-right', 13px, 1920px, 2px);
		@include sizeRelativePageWidth('padding-left', 13px, 1920px, 2px);
		@include sizeRelativePageWidth('font-size', 18px, 1920px, 8px);

		&_one{
			background: getBase('color-two');
			color: #fff;
		}

		&_two{
			background: getBase('color-one');
			color: #000;
		}

		&_three{
			background: #a1a1a1;
			color: #000;
		}
	}

	&__client{
		@include sizeRelativePageWidth('font-size', 18px, 1920px, 10px);
	}

	&__description{
		@include sizeRelativePageWidth('font-size', 18px, 1920px, 10px);
		@include sizeRelativePageWidth('height', 420px, 1920px, 200px);
		font-weight: 300;
	}

	.mouse-device &:hover &__info{
		display: block;
	}

	.touch-device &.hovered &__info{
		display: block;
	}

	@media screen and (max-width:768px) {
		&__title{
			font-size: sizeRelativePageWidth(20px, 768px);
			margin-bottom: sizeRelativePageWidth(20px, 768px);
		}

		&__description,
		&__client,
		&__tag{
			font-size: sizeRelativePageWidth(18px, 768px);
		}

		&__tags{
			margin-bottom: sizeRelativePageWidth(15px, 768px);
		}

		&__tag{
			margin-right: sizeRelativePageWidth(9px, 768px);
			height: sizeRelativePageWidth(35px, 768px);
			line-height: sizeRelativePageWidth(35px, 768px);
			padding: 0 sizeRelativePageWidth(10px, 768px);
		}

		&__info{
			padding: sizeRelativePageWidth(20px, 768px);
			padding-bottom: 0;
		}
	}

	@media screen and (max-width:480px) {
		&__title{
			font-size: sizeRelativePageWidth(26px, 480px);
			margin-bottom: sizeRelativePageWidth(29px, 480px);
		}

		&__description,
		&__client,
		&__tag{
			font-size: sizeRelativePageWidth(22px, 480px);
		}

		&__tags{
			margin-bottom: sizeRelativePageWidth(26px, 480px);
		}

		&__tag{
			margin-right: sizeRelativePageWidth(9px, 480px);
			height: sizeRelativePageWidth(38px, 480px);
			line-height: sizeRelativePageWidth(38px, 480px);
			padding: 0 sizeRelativePageWidth(13px, 480px);
		}

		&__info{
			padding: sizeRelativePageWidth(20px, 480px);
			padding-bottom: 0;
		}
	}
}
