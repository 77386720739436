/*-----------------------------------------------------------
	Site Modal
-----------------------------------------------------------*/
.site-modal{
	display: none;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 99999;
	background: transparent;
	opacity: 0;
}

.site-modal.active{
	display: block;
}

.site-modal__table{
	display: table;
	height: 100%;
	width: 100%;
	table-layout: fixed;
}

.site-modal__cell{
	display: table-cell;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	text-align: center;
	background: rgba(255,255,255,0.5);
}

.site-modal__window{
	display: inline-block;
	position: relative;
	text-align: left;
}

.site-modal__close{
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

.modal{
	display: none;
	background: #fff;
	box-shadow: 0 0 30px rgba(0,0,0,0.2);
	max-width: 1200px;
	min-width: 150px;
	min-height: 150px;
}

.site-modal__content.active{
	display: block !important;
}
/*-----------------------------------------------------------
	/Site Modal
-----------------------------------------------------------*/


/*-----------------------------------------------------------
	Custom
-----------------------------------------------------------*/
.site-modal__cell{
	background: rgba(0,0,0,.8);
}

.site-modal__window{
	padding: 20px;
	
	@media screen and (max-width: 660px) {
		padding: 0;
	}
}

.modal{
	position: relative;
	max-width: 1200px;
	padding: 50px 40px;

	&__close{
		position: absolute;
		right: 10px;
		top: 10px;
		color: #5c5c5c;
		cursor: pointer;
		
		&:hover{
			color: getBase('color-two');
		}
	}
	
	&__title{
		font: 24px Gotham, sans-serif;
		text-transform: uppercase;
		text-align: center;
	}
	
	&__content{
		margin-top: 60px;
	}
	
	@media screen and (max-width: 660px) {
		padding: 40px 15px;
		&__close{
			top: 10px;
			right: 10px;
		}
	}
}


.modal_detailed{
	padding: 25px 20px 30px;
	@media screen and (max-width:1024px) {
		padding-right: 10px ;
		padding-left: 10px;
		padding-top: 15px;
	}

	@media screen and (max-width:660px) {
		width: 100%;
		padding-top: 60px;
	}

	@media screen and (max-width:480px) {
		padding-top: 5px;
		padding-right: 0;
		padding-left: 0;
	}
}



/*.modal_dark{
	position: relative;
	max-width: 440px;
	padding: 45px 30px 25px;
	text-align: center;
	color: #fff;
	background: #000;
		

	.modal__close{
		color: #fff;
		
		&:hover{
			color: getBase('color-two');
		}
	}
	
	.modal__title{
		font: 24px Calibri, sans-serif;
		text-transform: inherit;
		text-align: center;
		color: #fff;
	}
	
	.modal__content{
		font-size: 16px;
		margin-top: 15px;
	}
}*/


/*-------------------Confidentiality------------------------*/
.modal_confidentiality{
	max-width: 900px;
	
	@media screen and (max-width: 480px) {
		.modal__title{
			font-size: 20px;
		}
	}
	
	@media screen and (max-width: 320px) {
		.modal__title{
			font-size: 18px;
		}
	}
}


.modal_success-request{
	max-width: 760px;
	text-align: center;
	padding: 25px 55px 55px;
	
	.modal__content{
		margin-top: 0;
	}
	.modal__img{
		img{
			max-width: 100%;
		}
		margin-bottom: 30px;
	}
	.modal__title{
		font-size: 36px;
		text-transform: none;
		margin-bottom: 15px;
	}
	.modal__desc{
		font-size: 18px;
		margin-bottom: 28px;
	}
	.modal__button{
		width: 310px;
		font-size: 18px;
	}
	
	@media screen and (max-width: 768px) {
		.modal__title{
			font-size: 30px;
		}
	}
	
	@media screen and (max-width: 550px) {
		.modal__title{
			font-size: 26px;
		}
	}
	
	@media screen and (max-width: 480px) {
		.modal__title{
			font-size: 20px;
		}
		
		.modal__desc{
			font-size: 16px;
		}
	}
	
	@media screen and (max-width: 350px) {
		.modal__button{
			width: 100%;
			font-size: 16px;
		}
	}
}



.zcwPopup-bg {
	display: none;
	position: fixed !important;
	left: 0 !important;
	top: 0 !important;
	z-index: 99991 !important;
	width: 100% !important;
	height: 100% !important;
	background: rgba(0,0,0,0.8) !important;
	cursor: pointer !important;
}

.zcwPopup {
	position: absolute;
	left: 50%;
	top: 20%;
	margin-left: -220px;
	display: none;
	text-align: center;
	position: fixed;
	width: 440px;
	z-index: 99992 !important;
	height: auto !important;
	background-color: #000;
	color: #fff;
	padding: 45px 30px 25px;
	max-height: 100%;
	overflow-y: auto;
	border: 2px solid rgba(255,255,255,1);
}

.zcwPopup-title,
.zcwPopup-description,
.zcwPopup-content {
	margin: 0;
}

.zcwPopup-title {
	font: 24px Gotham,sans-serif;
	text-transform: inherit;
	text-align: center;
	color: #fff;
	margin-bottom: 12px;
}

#zcwPopup-raty img {
    display: inline !important;
}

.zcwPopup-close {
	cursor: pointer !important;
	text-decoration: none;
	color: grey;
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 0;

	&:after{
		content: $ic-close;
		font-family: fk;
		font-size: 20px;
	}

	&:hover{
		color: getBase('color-two');
	}
}

.zcwPopup-content {
	font-size: 16px;
}

.zcwPopup-description{
	margin-bottom: 17px;
}

.zcwPopup-countdown {
    font-weight: bold;
    display: none;
	font-size: 25px;
	width: 100%;
}

.zcwPopup .link {
    text-decoration: underline;
    cursor: pointer;
}

.zcwPopup .powered_by {
    margin-top: 7px;
    font-size: 12px;
    color: #777777 !important;
}

.zcwPopup .powered_by a {
    font-weight: bold !important;
    color: #777777 !important;
    text-decoration: underline !important;
}

.zcwPopup form{
	font-size: 0;
}

.zcwPopup input[type="text"]{
	font-size: 18px;
	line-height: 58px;
	padding: 0 28px;
	border: 1px solid #d9d9d9;
	background: 0 0;
	width: 100%;
	color: #d9d9d9;
	margin-bottom: 10px;
}

.zcwPopup select{
	font-size: 18px;
	margin: 0;
	color: #d9d9d9;
	border: 1px solid #d9d9d9;
	display: inline !important;
	background: #000 url(../img/drop-down.png) no-repeat;
	background-position: right 15px top 24px;
	height: 58px;
	padding-left: 26px;
	
	&:hover,
	&:focus{
		background-image: url(../img/drop-down-hover.png);
	}
}

.zcwPopup select[name="day"]{
	width: 144px;
}

.zcwPopup select[name="hour"]{
	width: 108px;
	margin-left: 10px;
}

.zcwPopup select[name="minute"]{
	width: 108px;
	margin-left: 10px;
}

.zcwPopup textarea{
	font-size: 18px;
	color: #d9d9d9;
}

.zcwPopup button,
.zcwPopup input[type="submit"],
.zcwPopup input[type="button"]{
	border: 2px solid getBase('color-one');
	padding: 0 40px;
	color: getBase('color-one');
	font: 700 18px/18px Gotham,sans-serif;
	height: 53px;
	line-height: 52px;
	background: 0 0;
	margin-top: 10px;
	margin-bottom: 10px;
	&:hover{
		background: getBase('color-one');
		color: #000;
	}
}

.zcwPopup input.zcwInputError,
.zcwPopup select.zcwInputError,
.zcwPopup textarea.zcwInputError {
    border: 1px solid red;
}

#zcwMiniButton {
    position:fixed;
    right: 100px;bottom: 100px;    width:70px;
    height:70px;
    cursor:pointer;
    opacity:0.5;
    z-index:99990;
}

#zcwMiniButton #zcwMiniButtonMain {
    -moz-border-radius:50% !important;
    -webkit-border-radius:50% !important;
    border-radius:50% !important;
    -moz-background-clip:padding;
    -webkit-background-clip:padding-box;
    background-clip:padding-box;
    background-color:rgb(46,119,179);
    width:70px;
    height:70px;
    -webkit-animation:zcwmini 1.5s 0s ease-out infinite;
    -moz-animation:zcwmini 1.5s 0s ease-out infinite;
    animation:zcwmini 1.5s 0s ease-out infinite;
    
}

#zcwMiniButton #zcwMiniButtonMain:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-image:url(images/mini.png);
    background-repeat:no-repeat;
    background-position:center center;
    -webkit-animation: zcwphone 1.5s linear infinite;
    -moz-animation: zcwphone 1.5s linear infinite;
    animation: zcwphone 1.5s linear infinite;
    
}

#zcwPopup-rate{
	line-height: 8px;
}


@media screen and (max-width: 440px) {
	.zcwPopup{
		top: 0;
		width: 100%;
		margin-left: 0;
		left: 0;
		padding-top: 15px;
		select{
			width: 100% !important;
			margin-left: 0 !important;
			margin-bottom: 10px;
			height: 36px;
			background-position: right 15px top 14px;
		}
		
		input[type="text"]{
			line-height: 36px;
		}
		
		input[type="submit"]{
			height: 45px;
			line-height: 42px;
		}
	}
}


.modal_video{
	padding: 0;
	.modal__close{
		right: -30px;
		top: -30px;
		color: #fff;
		
		&:hover{
			color: getBase('color-two');
		}
	}
}

.modal_player{
	padding: 0;
	width: 900px;
	height: 505px;
	
	.modal__close{
		right: -30px;
		top: -30px;
		color: #fff;
		
		&:hover{
			color: getBase('color-two');
		}
	}
	
	@media screen and (max-width: 990px) {
		width: 675px;
		height: 377px;
	}
	
	@media screen and (max-width: 760px) {
		width: 90vw;
		height: 320px;
		.modal__close{
			display: none;
		}
	}
	
	@media screen and (max-width: 660px) {
		width: 95vw;
		height: 280px;
		.modal__close{
			display: none;
		}
	}
}