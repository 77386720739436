@charset "UTF-8";
.clearfix:after, .athlet-review:after, .athlet-review_fulltime .athlet-review__content:after,
.athlet-review_fulltime2 .athlet-review__content:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.clearfix, .athlet-review, .athlet-review_fulltime .athlet-review__content,
.athlet-review_fulltime2 .athlet-review__content {
  display: inline-block; }

/* start commented backslash hack \*/
* html .clearfix, * html .athlet-review, * html .athlet-review_fulltime .athlet-review__content, .athlet-review_fulltime * html .athlet-review__content, * html
.athlet-review_fulltime2 .athlet-review__content,
.athlet-review_fulltime2 * html .athlet-review__content {
  height: 1%; }

.clearfix, .athlet-review, .athlet-review_fulltime .athlet-review__content,
.athlet-review_fulltime2 .athlet-review__content {
  display: block; }

/*-----------------------------------------------------------
	Sizes Control
-----------------------------------------------------------*/
/*-----------------------------------------------------------
	Other
-----------------------------------------------------------*/
@keyframes headerShowMore {
  0%, 70%, 100% {
    transform: translateY(0px); }
  85% {
    transform: translateY(10px); } }

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0); } }

.shop_middle_banner {
  background: url(../img/shop_banner_bg.jpg) 50% 0 no-repeat;
  /*background-size: cover;*/ }
  .shop_middle_banner .shop_middle_banner__container {
    max-width: 1160px;
    margin: 0 auto;
    min-height: 368px;
    position: relative; }
    .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption {
      position: absolute;
      left: 50%;
      margin-left: -200px;
      z-index: 3;
      padding-top: 34px;
      width: 357px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption:before {
        content: "";
        position: absolute;
        left: 37px;
        top: 0;
        width: 285px;
        height: 368px;
        background: url(../img/fk-pattern-dark-small.jpg);
        transform: skewX(-12deg);
        z-index: -1; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption_logo {
        margin: 0 0 40px 149px;
        width: 90px;
        height: 87px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__title {
        color: #fff;
        text-transform: uppercase;
        padding: 0 60px 0 66px;
        font: normal 18px 'Calibri';
        margin-bottom: 7px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list {
        list-style: none;
        padding: 0 60px 10px 66px;
        margin: 0;
        counter-reset: list; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
          padding-left: 45px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list li {
          color: #fff;
          font: normal 16px 'Calibri'; }
          .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list li:before {
            counter-increment: list;
            content: counter(list) ". "; }
          .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list li span {
            color: #8fd400;
            font-weight: bold; }
    .shop_middle_banner .shop_middle_banner__container .shop_banner__slider {
      position: relative;
      z-index: 2; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item {
        position: relative;
        padding: 47px 0 0 0;
        box-sizing: border-box;
        min-height: 351px; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price {
          position: absolute;
          right: 0;
          top: 35px;
          width: 88px;
          height: 88px;
          line-height: 88px;
          background: #e8e8e6;
          border-radius: 50%;
          -webkit-box-shadow: -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
          -moz-box-shadow: -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
          box-shadow: -1px 3px 7px 0px rgba(0, 0, 0, 0.58);
          text-align: center;
          z-index: 3; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price span.price_text_height {
            display: inline-block;
            vertical-align: middle;
            font: normal 16px 'Calibri';
            color: #000000; }
            .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price span.price_text_height span.current_price {
              display: block;
              color: #0393d8;
              font: bold 20px 'Calibri'; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__title {
          font: normal 30px/32px GothamBold;
          color: #000000;
          text-transform: uppercase; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__body {
          max-width: 240px;
          font: normal 16px 'Calibri';
          padding-top: 7px; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .slide_pic {
          display: none; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__link {
          width: 142px;
          height: 49px;
          box-sizing: border-box;
          border: 2px solid #0f101f;
          position: absolute;
          left: 50%;
          bottom: 39px;
          margin-left: -71px;
          text-align: center;
          text-transform: uppercase;
          font: normal 18px/47px 'Calibri';
          transition: all 0.5s ease; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__link:hover {
            color: #8fd400;
            background: #0f101f; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-nav {
        display: none !important; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-item {
        display: inline-block !important;
        vertical-align: top; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-dots {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0; }

.rotate-phone {
  background: #000 !important;
  text-align: center; }
  .rotate-phone:after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle; }
  .rotate-phone .rotate_inner {
    width: 100%;
    max-width: 410px;
    display: inline-block;
    vertical-align: middle; }
    .rotate-phone .rotate_inner .rotate_bottom {
      text-align: left;
      font: normal 36px/38px GothamBold;
      color: #fff;
      text-transform: uppercase;
      position: relative; }
      .rotate-phone .rotate_inner .rotate_bottom .section_top_svg {
        position: absolute;
        right: 0;
        top: 0;
        width: 36px;
        height: 25px;
        transform: scaleX(-1); }
      .rotate-phone .rotate_inner .rotate_bottom .section_top {
        margin-top: 5px; }
      .rotate-phone .rotate_inner .rotate_bottom .section_bottom {
        font: 200 18px Calibri;
        text-transform: none;
        padding-left: 28px;
        position: relative;
        padding-top: 0;
        margin-top: 5px; }
        .rotate-phone .rotate_inner .rotate_bottom .section_bottom .section_bottom_svg {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 3px; }

@media screen and (max-width: 991px) {
  .shop_middle_banner {
    background: url(../img/slider_mobile_bg.jpg) 50% 0 no-repeat;
    background-size: cover; }
    .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption {
      width: 400px;
      margin: 0;
      left: -76px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption:before {
        width: 335px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption_logo {
        margin-left: 108px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__title {
        padding-left: 108px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list {
        padding-left: 108px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
          padding-left: 108px; }
    .shop_middle_banner .shop_middle_banner__container .shop_banner__slider {
      padding-left: 323px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price {
        right: 25px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__title {
        padding-left: 35px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__body {
        padding-left: 35px;
        max-width: 280px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .slide_pic {
        display: block;
        right: 0;
        top: 159px;
        left: 0;
        position: absolute;
        text-align: center; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__link {
        bottom: 24px;
        margin-left: -105px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-dots {
        text-align: center;
        font-size: 0;
        bottom: 15px; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-dots .owl-dot {
          display: inline-block;
          vertical-align: top;
          margin: 0 6px;
          width: 10px;
          height: 10px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 1px solid #c3c3c3;
          background: #fff !important; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .owl-dots .owl-dot.active {
            border-color: #0a97db;
            background: #fff; } }

/*
@media (max-width: 767px) and (orientation: landscape) {
	.rotate-phone {
		display: block;
	}
	body {
		height: 100%;
		overflow: hidden !important;
		width: 100%;
		position: fixed;
	}
}
*/
@media screen and (max-width: 700px) {
  .shop_middle_banner {
    overflow: hidden; }
    .shop_middle_banner .shop_middle_banner__container {
      min-height: 496px; }
      .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption {
        padding-top: 23px;
        left: 0;
        right: 0;
        width: auto;
        height: 225px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption:before {
          width: 100%;
          top: -50%;
          height: 225px;
          transform: skewY(10deg);
          left: 0;
          margin-top: 50px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption_logo {
          margin: 0;
          position: absolute;
          left: 15px;
          top: 29px;
          width: 66px;
          height: 63px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__title {
          padding-left: 100px;
          padding-right: 0;
          font-size: 14px;
          margin-bottom: 3px; }
        .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list {
          padding-left: 100px;
          padding-right: 0;
          padding-bottom: 5px;
          display: inline-block;
          vertical-align: top; }
          .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
            padding-left: 20px; }
          .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list li {
            font-size: 14px; }
      .shop_middle_banner .shop_middle_banner__container .shop_banner__slider {
        padding: 0; }
        .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item {
          padding-top: 225px;
          min-height: 496px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price {
            width: 65px;
            height: 65px;
            line-height: 65px; }
            .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price .price_text_height .price_inner {
              font-size: 12px; }
              .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price .price_text_height .price_inner .current_price {
                font-size: 15px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__title {
            padding-left: 15px;
            font-size: 18px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__body {
            padding-left: 15px;
            font-size: 14px;
            box-sizing: border-box;
            max-width: 210px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__top_price {
            top: 225px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .slide_pic {
            top: 315px;
            left: 15px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__link {
            bottom: 36px; }
          .shop_middle_banner .shop_middle_banner__container .shop_banner__slider .shop_banner__item .shop_banner__link {
            margin-left: -71px; } }

@media screen and (max-width: 530px) {
  .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
    position: absolute;
    right: 20px;
    top: 85px; } }

@media screen and (max-width: 430px) {
  .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption:before {
    margin-top: 70px; }
  .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
    top: 105px; } }

@media screen and (max-width: 600px) {
  .rotate-phone .rotate_inner {
    width: 100%;
    max-width: 250px;
    display: inline-block; }
    .rotate-phone .rotate_inner .rotate_bottom {
      font: normal 22px/24px GothamBold; }
      .rotate-phone .rotate_inner .rotate_bottom .section_top_svg {
        position: absolute;
        right: 0;
        top: 0;
        width: 22px;
        height: 22px;
        transform: scaleX(-1); }
      .rotate-phone .rotate_inner .rotate_bottom .section_bottom {
        font: 200 12px Calibri;
        text-transform: none;
        padding-left: 17px;
        position: relative;
        padding-top: 2px; }
        .rotate-phone .rotate_inner .rotate_bottom .section_bottom .section_bottom_svg {
          width: 11px;
          height: 11px;
          position: absolute;
          left: 0;
          top: 3px; } }

@media screen and (max-width: 321px) {
  .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list {
    display: block; }
    .shop_middle_banner .shop_middle_banner__container .shop_middle_banner__caption .banner_caption__list.banner_caption__list_bottom {
      position: static;
      right: auto;
      top: auto;
      display: block;
      padding-left: 100px; } }

.grid, .l-download, .page-footer__first, .page-footer__second {
  font-size: 0;
  line-height: 0; }
  .grid_center {
    text-align: center; }
  .grid_justify {
    font-size: 1px;
    text-align: justify;
    text-align-last: justify; }
    .grid_justify:before {
      content: '';
      display: block;
      width: 100%; }
    .grid_justify:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 0;
      font-size: 0;
      line-height: 0;
      overflow: hidden; }
    .grid_justify .grid__item, .grid_justify .l-download__one, .grid_justify .l-download__two, .grid_justify .page-footer__menu, .grid_justify .page-footer__contacts, .grid_justify .page-footer__copyright, .grid_justify .page-footer__company, .grid_justify .page-footer__address, .grid_justify .page-footer__soc-icons {
      text-align-last: left; }
  .grid__item, .l-download__one, .l-download__two, .page-footer__menu, .page-footer__contacts, .page-footer__copyright, .page-footer__company, .page-footer__address, .page-footer__soc-icons {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 100%;
    font-size: 20px;
    line-height: normal;
    position: relative;
    box-sizing: border-box; }

/*-----------------------------------------------------------
	Custom Styles
-----------------------------------------------------------*/
.grid_one .grid__item, .grid_one .l-download__one, .grid_one .l-download__two, .grid_one .page-footer__menu, .grid_one .page-footer__contacts, .grid_one .page-footer__copyright, .grid_one .page-footer__company, .grid_one .page-footer__address, .grid_one .page-footer__soc-icons {
  width: 33.33333%;
  cursor: default; }

@media screen and (max-width: 1024px) {
  .grid_one {
    text-align: center; }
    .grid_one .grid__item, .grid_one .l-download__one, .grid_one .l-download__two, .grid_one .page-footer__menu, .grid_one .page-footer__contacts, .grid_one .page-footer__copyright, .grid_one .page-footer__company, .grid_one .page-footer__address, .grid_one .page-footer__soc-icons {
      width: 50%; } }

@media screen and (max-width: 620px) {
  .grid_one .grid__item, .grid_one .l-download__one, .grid_one .l-download__two, .grid_one .page-footer__menu, .grid_one .page-footer__contacts, .grid_one .page-footer__copyright, .grid_one .page-footer__company, .grid_one .page-footer__address, .grid_one .page-footer__soc-icons {
    width: 100%;
    padding: 0;
    border: none; }
    .grid_one .grid__item:hover, .grid_one .l-download__one:hover, .grid_one .l-download__two:hover, .grid_one .page-footer__menu:hover, .grid_one .page-footer__contacts:hover, .grid_one .page-footer__copyright:hover, .grid_one .page-footer__company:hover, .grid_one .page-footer__address:hover, .grid_one .page-footer__soc-icons:hover {
      border: none; } }

@media screen and (min-width: 1025px) {
  .grid_x2_img .grid__item_x2 {
    width: 66.66667%; }
  .grid_x2_img .grid__item_img_ramp {
    padding-left: 90px; }
    .grid_x2_img .grid__item_img_ramp img {
      max-width: 100%; } }

@media screen and (max-width: 1024px) and (min-width: 621px) {
  .grid_x2_img {
    padding-top: 220px; }
    .grid_x2_img .grid__item_img_ramp {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -310px;
      padding: 0; }
      .grid_x2_img .grid__item_img_ramp img {
        width: 620px;
        max-width: none; } }

@media screen and (max-width: 620px) {
  .grid_x2_img .grid__item_img_ramp {
    padding: 30px 0 20px;
    width: 120%;
    margin-left: -10%; }
    .grid_x2_img .grid__item_img_ramp img {
      max-width: 100%;
      min-width: 480px; } }

.grid_portfolio .grid__show-all {
  width: 100%;
  height: 85px;
  line-height: 84px;
  text-transform: uppercase;
  font-size: 18px;
  padding: 0 10px;
  text-align: center;
  background: #f5f5f5;
  color: #6e6e6e;
  cursor: pointer;
  display: none; }
  .grid_portfolio .grid__show-all:after {
    content: '';
    display: inline-block;
    background: url(../img/triangle-right-blue.png) no-repeat;
    width: 13px;
    height: 26px;
    vertical-align: middle;
    margin-left: 13px; }

.grid_portfolio .grid__item, .grid_portfolio .l-download__one, .grid_portfolio .l-download__two, .grid_portfolio .page-footer__menu, .grid_portfolio .page-footer__contacts, .grid_portfolio .page-footer__copyright, .grid_portfolio .page-footer__company, .grid_portfolio .page-footer__address, .grid_portfolio .page-footer__soc-icons {
  width: 25%; }

@media screen and (max-width: 768px) {
  .grid_portfolio .grid__item, .grid_portfolio .l-download__one, .grid_portfolio .l-download__two, .grid_portfolio .page-footer__menu, .grid_portfolio .page-footer__contacts, .grid_portfolio .page-footer__copyright, .grid_portfolio .page-footer__company, .grid_portfolio .page-footer__address, .grid_portfolio .page-footer__soc-icons {
    width: 50%; }
    .grid_portfolio .grid__item:nth-child(5), .grid_portfolio .l-download__one:nth-child(5), .grid_portfolio .l-download__two:nth-child(5), .grid_portfolio .page-footer__menu:nth-child(5), .grid_portfolio .page-footer__contacts:nth-child(5), .grid_portfolio .page-footer__copyright:nth-child(5), .grid_portfolio .page-footer__company:nth-child(5), .grid_portfolio .page-footer__address:nth-child(5), .grid_portfolio .page-footer__soc-icons:nth-child(5), .grid_portfolio .grid__item:nth-child(6), .grid_portfolio .l-download__one:nth-child(6), .grid_portfolio .l-download__two:nth-child(6), .grid_portfolio .page-footer__menu:nth-child(6), .grid_portfolio .page-footer__contacts:nth-child(6), .grid_portfolio .page-footer__copyright:nth-child(6), .grid_portfolio .page-footer__company:nth-child(6), .grid_portfolio .page-footer__address:nth-child(6), .grid_portfolio .page-footer__soc-icons:nth-child(6), .grid_portfolio .grid__item:nth-child(7), .grid_portfolio .l-download__one:nth-child(7), .grid_portfolio .l-download__two:nth-child(7), .grid_portfolio .page-footer__menu:nth-child(7), .grid_portfolio .page-footer__contacts:nth-child(7), .grid_portfolio .page-footer__copyright:nth-child(7), .grid_portfolio .page-footer__company:nth-child(7), .grid_portfolio .page-footer__address:nth-child(7), .grid_portfolio .page-footer__soc-icons:nth-child(7), .grid_portfolio .grid__item:nth-child(8), .grid_portfolio .l-download__one:nth-child(8), .grid_portfolio .l-download__two:nth-child(8), .grid_portfolio .page-footer__menu:nth-child(8), .grid_portfolio .page-footer__contacts:nth-child(8), .grid_portfolio .page-footer__copyright:nth-child(8), .grid_portfolio .page-footer__company:nth-child(8), .grid_portfolio .page-footer__address:nth-child(8), .grid_portfolio .page-footer__soc-icons:nth-child(8) {
      display: none; }
  .grid_portfolio .grid__show-all {
    display: block; }
    .grid_portfolio .grid__show-all:nth-child(3), .grid_portfolio .grid__show-all:nth-child(4) {
      display: none; } }

@media screen and (max-width: 480px) {
  .grid_portfolio .grid__item, .grid_portfolio .l-download__one, .grid_portfolio .l-download__two, .grid_portfolio .page-footer__menu, .grid_portfolio .page-footer__contacts, .grid_portfolio .page-footer__copyright, .grid_portfolio .page-footer__company, .grid_portfolio .page-footer__address, .grid_portfolio .page-footer__soc-icons {
    width: 100%; }
    .grid_portfolio .grid__item:nth-child(3), .grid_portfolio .l-download__one:nth-child(3), .grid_portfolio .l-download__two:nth-child(3), .grid_portfolio .page-footer__menu:nth-child(3), .grid_portfolio .page-footer__contacts:nth-child(3), .grid_portfolio .page-footer__copyright:nth-child(3), .grid_portfolio .page-footer__company:nth-child(3), .grid_portfolio .page-footer__address:nth-child(3), .grid_portfolio .page-footer__soc-icons:nth-child(3), .grid_portfolio .grid__item:nth-child(4), .grid_portfolio .l-download__one:nth-child(4), .grid_portfolio .l-download__two:nth-child(4), .grid_portfolio .page-footer__menu:nth-child(4), .grid_portfolio .page-footer__contacts:nth-child(4), .grid_portfolio .page-footer__copyright:nth-child(4), .grid_portfolio .page-footer__company:nth-child(4), .grid_portfolio .page-footer__address:nth-child(4), .grid_portfolio .page-footer__soc-icons:nth-child(4) {
      display: none; } }

.grid_portfolio.show-all .grid__item, .grid_portfolio.show-all .l-download__one, .grid_portfolio.show-all .l-download__two, .grid_portfolio.show-all .page-footer__menu, .grid_portfolio.show-all .page-footer__contacts, .grid_portfolio.show-all .page-footer__copyright, .grid_portfolio.show-all .page-footer__company, .grid_portfolio.show-all .page-footer__address, .grid_portfolio.show-all .page-footer__soc-icons {
  display: inline-block; }

.grid_portfolio.show-all .grid__show-all {
  display: none; }

.grid_stages {
  margin-left: -20px; }
  .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
    padding: 0 0 15px 20px; }
  @media screen and (max-width: 1000px) {
    .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
      width: 50%; } }
  @media screen and (max-width: 630px) {
    .grid_stages {
      margin-left: 0; }
      .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
        width: 100%;
        padding-left: 0; } }

.grid_our-figures {
  color: #fff; }
  .grid_our-figures .grid__item, .grid_our-figures .l-download__one, .grid_our-figures .l-download__two, .grid_our-figures .page-footer__menu, .grid_our-figures .page-footer__contacts, .grid_our-figures .page-footer__copyright, .grid_our-figures .page-footer__company, .grid_our-figures .page-footer__address, .grid_our-figures .page-footer__soc-icons {
    width: 25%;
    padding-top: 20px; }
    @media screen and (max-width: 768px) {
      .grid_our-figures .grid__item, .grid_our-figures .l-download__one, .grid_our-figures .l-download__two, .grid_our-figures .page-footer__menu, .grid_our-figures .page-footer__contacts, .grid_our-figures .page-footer__copyright, .grid_our-figures .page-footer__company, .grid_our-figures .page-footer__address, .grid_our-figures .page-footer__soc-icons {
        padding-top: 40px; } }
    .grid_our-figures .grid__item:after, .grid_our-figures .l-download__one:after, .grid_our-figures .l-download__two:after, .grid_our-figures .page-footer__menu:after, .grid_our-figures .page-footer__contacts:after, .grid_our-figures .page-footer__copyright:after, .grid_our-figures .page-footer__company:after, .grid_our-figures .page-footer__address:after, .grid_our-figures .page-footer__soc-icons:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 170px;
      background: #fff; }
    .grid_our-figures .grid__item:first-child:after, .grid_our-figures .l-download__one:first-child:after, .grid_our-figures .l-download__two:first-child:after, .grid_our-figures .page-footer__menu:first-child:after, .grid_our-figures .page-footer__contacts:first-child:after, .grid_our-figures .page-footer__copyright:first-child:after, .grid_our-figures .page-footer__company:first-child:after, .grid_our-figures .page-footer__address:first-child:after, .grid_our-figures .page-footer__soc-icons:first-child:after {
      display: none; }
  @media screen and (max-width: 1140px) {
    .grid_our-figures .grid__item, .grid_our-figures .l-download__one, .grid_our-figures .l-download__two, .grid_our-figures .page-footer__menu, .grid_our-figures .page-footer__contacts, .grid_our-figures .page-footer__copyright, .grid_our-figures .page-footer__company, .grid_our-figures .page-footer__address, .grid_our-figures .page-footer__soc-icons {
      width: 50%; }
      .grid_our-figures .grid__item:after, .grid_our-figures .l-download__one:after, .grid_our-figures .l-download__two:after, .grid_our-figures .page-footer__menu:after, .grid_our-figures .page-footer__contacts:after, .grid_our-figures .page-footer__copyright:after, .grid_our-figures .page-footer__company:after, .grid_our-figures .page-footer__address:after, .grid_our-figures .page-footer__soc-icons:after {
        display: none; } }
  @media screen and (max-width: 767px) {
    .grid_our-figures .grid__item, .grid_our-figures .l-download__one, .grid_our-figures .l-download__two, .grid_our-figures .page-footer__menu, .grid_our-figures .page-footer__contacts, .grid_our-figures .page-footer__copyright, .grid_our-figures .page-footer__company, .grid_our-figures .page-footer__address, .grid_our-figures .page-footer__soc-icons {
      width: 100%; }
      .grid_our-figures .grid__item:after, .grid_our-figures .l-download__one:after, .grid_our-figures .l-download__two:after, .grid_our-figures .page-footer__menu:after, .grid_our-figures .page-footer__contacts:after, .grid_our-figures .page-footer__copyright:after, .grid_our-figures .page-footer__company:after, .grid_our-figures .page-footer__address:after, .grid_our-figures .page-footer__soc-icons:after {
        display: none; } }

.grid_home-header {
  margin-left: -30px; }
  .grid_home-header .grid__item, .grid_home-header .l-download__one, .grid_home-header .l-download__two, .grid_home-header .page-footer__menu, .grid_home-header .page-footer__contacts, .grid_home-header .page-footer__copyright, .grid_home-header .page-footer__company, .grid_home-header .page-footer__address, .grid_home-header .page-footer__soc-icons {
    width: 33.33333%;
    padding-left: 30px;
    text-align: center; }
  @media screen and (max-width: 1050px) {
    .grid_home-header {
      margin: 0; }
      .grid_home-header .grid__item, .grid_home-header .l-download__one, .grid_home-header .l-download__two, .grid_home-header .page-footer__menu, .grid_home-header .page-footer__contacts, .grid_home-header .page-footer__copyright, .grid_home-header .page-footer__company, .grid_home-header .page-footer__address, .grid_home-header .page-footer__soc-icons {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0; } }
  @media screen and (max-width: 370px) and (max-height: 550px) {
    .grid_home-header .grid__item, .grid_home-header .l-download__one, .grid_home-header .l-download__two, .grid_home-header .page-footer__menu, .grid_home-header .page-footer__contacts, .grid_home-header .page-footer__copyright, .grid_home-header .page-footer__company, .grid_home-header .page-footer__address, .grid_home-header .page-footer__soc-icons {
      margin-bottom: 10px; } }

.grid_biz-header {
  max-width: 820px;
  margin-right: auto;
  margin-left: auto; }
  .grid_biz-header .grid__item, .grid_biz-header .l-download__one, .grid_biz-header .l-download__two, .grid_biz-header .page-footer__menu, .grid_biz-header .page-footer__contacts, .grid_biz-header .page-footer__copyright, .grid_biz-header .page-footer__company, .grid_biz-header .page-footer__address, .grid_biz-header .page-footer__soc-icons {
    width: 33.33333%;
    text-align: center; }
  @media screen and (max-width: 768px) {
    .grid_biz-header .grid__item, .grid_biz-header .l-download__one, .grid_biz-header .l-download__two, .grid_biz-header .page-footer__menu, .grid_biz-header .page-footer__contacts, .grid_biz-header .page-footer__copyright, .grid_biz-header .page-footer__company, .grid_biz-header .page-footer__address, .grid_biz-header .page-footer__soc-icons {
      width: 50%; } }

.grid_ramp .grid__item, .grid_ramp .l-download__one, .grid_ramp .l-download__two, .grid_ramp .page-footer__menu, .grid_ramp .page-footer__contacts, .grid_ramp .page-footer__copyright, .grid_ramp .page-footer__company, .grid_ramp .page-footer__address, .grid_ramp .page-footer__soc-icons {
  width: 16.66667%; }
  .grid_ramp .grid__item img, .grid_ramp .l-download__one img, .grid_ramp .l-download__two img, .grid_ramp .page-footer__menu img, .grid_ramp .page-footer__contacts img, .grid_ramp .page-footer__copyright img, .grid_ramp .page-footer__company img, .grid_ramp .page-footer__address img, .grid_ramp .page-footer__soc-icons img {
    width: 100%; }

.grid_ramp .grid__item-icon {
  position: absolute;
  color: #fff;
  opacity: 0.3;
  width: 4.6875vw;
  height: 4.6875vw;
  right: 1.5625vw;
  bottom: 1.5625vw; }
  @media screen and (max-width: 106.66667px) {
    .grid_ramp .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 106.66667px) {
    .grid_ramp .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 320px) {
    .grid_ramp .grid__item-icon {
      right: 5px; } }
  @media screen and (max-width: 320px) {
    .grid_ramp .grid__item-icon {
      bottom: 5px; } }

.grid_ramp .grid__item_delivery .grid__item-icon {
  width: 5.72917vw;
  height: 4.42708vw; }
  @media screen and (max-width: 87.27273px) {
    .grid_ramp .grid__item_delivery .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 112.94118px) {
    .grid_ramp .grid__item_delivery .grid__item-icon {
      height: 5px; } }

.grid_ramp .grid__item_delivery,
.grid_ramp .grid__item_setup,
.grid_ramp .grid__item_handmade,
.grid_ramp .grid__item_options,
.grid_ramp .grid__item_ride {
  position: absolute;
  left: 0;
  top: 0;
  background: #0094db;
  width: 100%;
  height: 100%;
  color: #fff;
  padding-left: 1.30208vw;
  padding-right: 1.30208vw;
  padding-top: 3.38542vw;
  font-size: 1.25vw; }
  @media screen and (max-width: 384px) {
    .grid_ramp .grid__item_delivery,
    .grid_ramp .grid__item_setup,
    .grid_ramp .grid__item_handmade,
    .grid_ramp .grid__item_options,
    .grid_ramp .grid__item_ride {
      padding-left: 5px; } }
  @media screen and (max-width: 384px) {
    .grid_ramp .grid__item_delivery,
    .grid_ramp .grid__item_setup,
    .grid_ramp .grid__item_handmade,
    .grid_ramp .grid__item_options,
    .grid_ramp .grid__item_ride {
      padding-right: 5px; } }
  @media screen and (max-width: 147.69231px) {
    .grid_ramp .grid__item_delivery,
    .grid_ramp .grid__item_setup,
    .grid_ramp .grid__item_handmade,
    .grid_ramp .grid__item_options,
    .grid_ramp .grid__item_ride {
      padding-top: 5px; } }
  @media screen and (max-width: 960px) {
    .grid_ramp .grid__item_delivery,
    .grid_ramp .grid__item_setup,
    .grid_ramp .grid__item_handmade,
    .grid_ramp .grid__item_options,
    .grid_ramp .grid__item_ride {
      font-size: 12px; } }

.grid_ramp .grid__item-icon {
  line-height: 0; }

.grid_ramp .grid__item_setup {
  background: #575757; }

.grid_ramp .grid__item_handmade {
  background: #99CC33; }

.grid_ramp .grid__item_options {
  background: #FF9933;
  padding-top: 1.30208vw; }
  @media screen and (max-width: 384px) {
    .grid_ramp .grid__item_options {
      padding-top: 5px; } }

.grid_ramp .grid__item_ride {
  background: #000; }

.grid_ramp .grid__item_ride .grid__item-icons {
  overflow: hidden;
  text-align: center;
  margin-top: 1.5625vw; }
  @media screen and (max-width: 64px) {
    .grid_ramp .grid__item_ride .grid__item-icons {
      margin-top: 1px; } }

.grid_ramp .grid__item_ride .grid__item-icon {
  position: static;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #fff;
  width: 3.64583vw;
  height: 3.64583vw;
  padding: 0.625vw;
  margin-left: 0.78125vw; }
  @media screen and (max-width: 137.14286px) {
    .grid_ramp .grid__item_ride .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 137.14286px) {
    .grid_ramp .grid__item_ride .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 160px) {
    .grid_ramp .grid__item_ride .grid__item-icon {
      padding: 1px; } }
  @media screen and (max-width: 128px) {
    .grid_ramp .grid__item_ride .grid__item-icon {
      margin-left: 1px; } }

.grid_ramp .grid__item_ride .grid__item-icon:first-child {
  margin-left: 0; }

.grid_ramp-tablet,
.grid_ramp-mobile {
  display: none; }

.grid_ramp-tablet .grid__item, .grid_ramp-tablet .l-download__one, .grid_ramp-tablet .l-download__two, .grid_ramp-tablet .page-footer__menu, .grid_ramp-tablet .page-footer__contacts, .grid_ramp-tablet .page-footer__copyright, .grid_ramp-tablet .page-footer__company, .grid_ramp-tablet .page-footer__address, .grid_ramp-tablet .page-footer__soc-icons {
  width: 25%; }

.grid_ramp-tablet .grid__item_delivery,
.grid_ramp-tablet .grid__item_setup,
.grid_ramp-tablet .grid__item_handmade,
.grid_ramp-tablet .grid__item_options,
.grid_ramp-tablet .grid__item_ride {
  padding-left: 1.95312vw;
  padding-right: 1.95312vw;
  padding-top: 3.90625vw;
  font-size: 1.95312vw; }
  @media screen and (max-width: 256px) {
    .grid_ramp-tablet .grid__item_delivery,
    .grid_ramp-tablet .grid__item_setup,
    .grid_ramp-tablet .grid__item_handmade,
    .grid_ramp-tablet .grid__item_options,
    .grid_ramp-tablet .grid__item_ride {
      padding-left: 5px; } }
  @media screen and (max-width: 256px) {
    .grid_ramp-tablet .grid__item_delivery,
    .grid_ramp-tablet .grid__item_setup,
    .grid_ramp-tablet .grid__item_handmade,
    .grid_ramp-tablet .grid__item_options,
    .grid_ramp-tablet .grid__item_ride {
      padding-right: 5px; } }
  @media screen and (max-width: 128px) {
    .grid_ramp-tablet .grid__item_delivery,
    .grid_ramp-tablet .grid__item_setup,
    .grid_ramp-tablet .grid__item_handmade,
    .grid_ramp-tablet .grid__item_options,
    .grid_ramp-tablet .grid__item_ride {
      padding-top: 5px; } }
  @media screen and (max-width: 512px) {
    .grid_ramp-tablet .grid__item_delivery,
    .grid_ramp-tablet .grid__item_setup,
    .grid_ramp-tablet .grid__item_handmade,
    .grid_ramp-tablet .grid__item_options,
    .grid_ramp-tablet .grid__item_ride {
      font-size: 10px; } }

.grid_ramp-tablet .grid__item_options {
  padding-top: 2.86458vw; }
  @media screen and (max-width: 174.54545px) {
    .grid_ramp-tablet .grid__item_options {
      padding-top: 5px; } }

.grid_ramp-tablet .grid__item-icon {
  position: absolute;
  color: #fff;
  opacity: 0.3;
  width: 7.16146vw;
  height: 7.16146vw;
  right: 2.34375vw;
  bottom: 1.5625vw; }
  @media screen and (max-width: 69.81818px) {
    .grid_ramp-tablet .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 69.81818px) {
    .grid_ramp-tablet .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 213.33333px) {
    .grid_ramp-tablet .grid__item-icon {
      right: 5px; } }
  @media screen and (max-width: 320px) {
    .grid_ramp-tablet .grid__item-icon {
      bottom: 5px; } }

.grid_ramp-tablet .grid__item_delivery .grid__item-icon {
  width: 9.11458vw;
  height: 5.20833vw; }
  @media screen and (max-width: 54.85714px) {
    .grid_ramp-tablet .grid__item_delivery .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 96px) {
    .grid_ramp-tablet .grid__item_delivery .grid__item-icon {
      height: 5px; } }

.grid_ramp-tablet .grid__item_ride .grid__item-icons {
  overflow: hidden;
  text-align: center;
  margin-top: 3.25521vw; }
  @media screen and (max-width: 30.72px) {
    .grid_ramp-tablet .grid__item_ride .grid__item-icons {
      margin-top: 1px; } }

.grid_ramp-tablet .grid__item_ride .grid__item-icon {
  width: 5.33854vw;
  height: 5.33854vw;
  padding: 1.04167vw;
  margin-left: 1.04167vw; }
  @media screen and (max-width: 93.65854px) {
    .grid_ramp-tablet .grid__item_ride .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 93.65854px) {
    .grid_ramp-tablet .grid__item_ride .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 96px) {
    .grid_ramp-tablet .grid__item_ride .grid__item-icon {
      padding: 1px; } }
  @media screen and (max-width: 96px) {
    .grid_ramp-tablet .grid__item_ride .grid__item-icon {
      margin-left: 1px; } }

.grid_ramp-tablet .grid__item_ride .grid__item-icon:first-child {
  margin-left: 0; }

.grid_ramp-mobile .grid__item, .grid_ramp-mobile .l-download__one, .grid_ramp-mobile .l-download__two, .grid_ramp-mobile .page-footer__menu, .grid_ramp-mobile .page-footer__contacts, .grid_ramp-mobile .page-footer__copyright, .grid_ramp-mobile .page-footer__company, .grid_ramp-mobile .page-footer__address, .grid_ramp-mobile .page-footer__soc-icons {
  width: 50%; }

.grid_ramp-mobile .grid__item_delivery,
.grid_ramp-mobile .grid__item_setup,
.grid_ramp-mobile .grid__item_handmade,
.grid_ramp-mobile .grid__item_options,
.grid_ramp-mobile .grid__item_ride {
  padding-left: 3.125vw;
  padding-right: 3.125vw;
  padding-top: 7.8125vw;
  font-size: 4.0625vw; }
  @media screen and (max-width: 160px) {
    .grid_ramp-mobile .grid__item_delivery,
    .grid_ramp-mobile .grid__item_setup,
    .grid_ramp-mobile .grid__item_handmade,
    .grid_ramp-mobile .grid__item_options,
    .grid_ramp-mobile .grid__item_ride {
      padding-left: 5px; } }
  @media screen and (max-width: 160px) {
    .grid_ramp-mobile .grid__item_delivery,
    .grid_ramp-mobile .grid__item_setup,
    .grid_ramp-mobile .grid__item_handmade,
    .grid_ramp-mobile .grid__item_options,
    .grid_ramp-mobile .grid__item_ride {
      padding-right: 5px; } }
  @media screen and (max-width: 64px) {
    .grid_ramp-mobile .grid__item_delivery,
    .grid_ramp-mobile .grid__item_setup,
    .grid_ramp-mobile .grid__item_handmade,
    .grid_ramp-mobile .grid__item_options,
    .grid_ramp-mobile .grid__item_ride {
      padding-top: 5px; } }
  @media screen and (max-width: 196.92308px) {
    .grid_ramp-mobile .grid__item_delivery,
    .grid_ramp-mobile .grid__item_setup,
    .grid_ramp-mobile .grid__item_handmade,
    .grid_ramp-mobile .grid__item_options,
    .grid_ramp-mobile .grid__item_ride {
      font-size: 8px; } }

.grid_ramp-mobile .grid__item_options {
  padding-top: 5.625vw; }
  @media screen and (max-width: 88.88889px) {
    .grid_ramp-mobile .grid__item_options {
      padding-top: 5px; } }

.grid_ramp-mobile .grid__item-icon {
  position: absolute;
  color: #fff;
  opacity: 0.3;
  width: 14.0625vw;
  height: 14.0625vw;
  right: 3.125vw;
  bottom: 3.125vw; }
  @media screen and (max-width: 35.55556px) {
    .grid_ramp-mobile .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 35.55556px) {
    .grid_ramp-mobile .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 160px) {
    .grid_ramp-mobile .grid__item-icon {
      right: 5px; } }
  @media screen and (max-width: 160px) {
    .grid_ramp-mobile .grid__item-icon {
      bottom: 5px; } }

.grid_ramp-mobile .grid__item_delivery .grid__item-icon {
  width: 17.1875vw;
  height: 9.375vw; }
  @media screen and (max-width: 29.09091px) {
    .grid_ramp-mobile .grid__item_delivery .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 53.33333px) {
    .grid_ramp-mobile .grid__item_delivery .grid__item-icon {
      height: 5px; } }

.grid_ramp-mobile .grid__item_ride .grid__item-icons {
  overflow: hidden;
  text-align: center;
  margin-top: 4.6875vw; }
  @media screen and (max-width: 21.33333px) {
    .grid_ramp-mobile .grid__item_ride .grid__item-icons {
      margin-top: 1px; } }

.grid_ramp-mobile .grid__item_ride .grid__item-icon {
  width: 10.9375vw;
  height: 10.9375vw;
  padding: 1.5625vw;
  margin-left: 1.875vw; }
  @media screen and (max-width: 45.71429px) {
    .grid_ramp-mobile .grid__item_ride .grid__item-icon {
      width: 5px; } }
  @media screen and (max-width: 45.71429px) {
    .grid_ramp-mobile .grid__item_ride .grid__item-icon {
      height: 5px; } }
  @media screen and (max-width: 64px) {
    .grid_ramp-mobile .grid__item_ride .grid__item-icon {
      padding: 1px; } }
  @media screen and (max-width: 53.33333px) {
    .grid_ramp-mobile .grid__item_ride .grid__item-icon {
      margin-left: 1px; } }

.grid_ramp-mobile .grid__item_ride .grid__item-icon:first-child {
  margin-left: 0; }

@media screen and (max-width: 1024px) {
  .grid_ramp-desktop {
    display: none; }
  .grid_ramp-tablet {
    display: block; } }

@media screen and (max-width: 700px) {
  .grid_ramp-tablet {
    display: none; }
  .grid_ramp-mobile {
    display: block; } }

.grid_soc-stages-for-part {
  margin-left: -90px;
  margin-top: 0; }
  .grid_soc-stages-for-part .grid__item, .grid_soc-stages-for-part .l-download__one, .grid_soc-stages-for-part .l-download__two, .grid_soc-stages-for-part .page-footer__menu, .grid_soc-stages-for-part .page-footer__contacts, .grid_soc-stages-for-part .page-footer__copyright, .grid_soc-stages-for-part .page-footer__company, .grid_soc-stages-for-part .page-footer__address, .grid_soc-stages-for-part .page-footer__soc-icons {
    padding-left: 90px;
    padding-top: 0; }
  .grid_soc-stages-for-part .grid__item, .grid_soc-stages-for-part .l-download__one, .grid_soc-stages-for-part .l-download__two, .grid_soc-stages-for-part .page-footer__menu, .grid_soc-stages-for-part .page-footer__contacts, .grid_soc-stages-for-part .page-footer__copyright, .grid_soc-stages-for-part .page-footer__company, .grid_soc-stages-for-part .page-footer__address, .grid_soc-stages-for-part .page-footer__soc-icons {
    width: 33.33333%; }
  @media screen and (max-width: 730px) {
    .grid_soc-stages-for-part {
      margin-left: 0;
      margin-top: -70px; }
      .grid_soc-stages-for-part .grid__item, .grid_soc-stages-for-part .l-download__one, .grid_soc-stages-for-part .l-download__two, .grid_soc-stages-for-part .page-footer__menu, .grid_soc-stages-for-part .page-footer__contacts, .grid_soc-stages-for-part .page-footer__copyright, .grid_soc-stages-for-part .page-footer__company, .grid_soc-stages-for-part .page-footer__address, .grid_soc-stages-for-part .page-footer__soc-icons {
        padding-left: 0;
        padding-top: 70px; }
      .grid_soc-stages-for-part .grid__item, .grid_soc-stages-for-part .l-download__one, .grid_soc-stages-for-part .l-download__two, .grid_soc-stages-for-part .page-footer__menu, .grid_soc-stages-for-part .page-footer__contacts, .grid_soc-stages-for-part .page-footer__copyright, .grid_soc-stages-for-part .page-footer__company, .grid_soc-stages-for-part .page-footer__address, .grid_soc-stages-for-part .page-footer__soc-icons {
        width: 100%; } }

.grid_cards {
  margin-left: -25px;
  margin-top: -25px; }
  .grid_cards .grid__item, .grid_cards .l-download__one, .grid_cards .l-download__two, .grid_cards .page-footer__menu, .grid_cards .page-footer__contacts, .grid_cards .page-footer__copyright, .grid_cards .page-footer__company, .grid_cards .page-footer__address, .grid_cards .page-footer__soc-icons {
    padding-left: 25px;
    padding-top: 25px; }

.fk-item__title {
  font-family: Gotham;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 20px; }

.fk-item__text {
  font-size: 18px;
  color: #000;
  text-align: left; }

.fk-item_counter {
  text-align: center;
  padding: 0 20px 10px;
  border: 2px solid transparent; }
  .fk-item_counter:hover {
    border: 2px solid #8dd50e; }
  .fk-item_counter .fk-item__number {
    font: bold 72px Gotham, sans-serif;
    color: #f3f3f3;
    margin-bottom: 5px; }
  .fk-item_counter .fk-item__title {
    font-size: 24px;
    margin-bottom: 20px; }
  .fk-item_counter .fk-item__text {
    color: #000; }

/*------------------------[ Reasons ]----------------------------*/
.fk-item_reason {
  min-height: 270px; }
  @media screen and (max-width: 570px) {
    .fk-item_reason {
      min-height: 0;
      padding-bottom: 0; }
      .fk-item_reason .fk-item__number {
        font-size: 48px; }
      .fk-item_reason .fk-item__title {
        font-size: 18px; }
      .fk-item_reason .fk-item__text {
        font-size: 15px; } }

/*------------------------[ Pluses ]----------------------------*/
.fk-item_plus {
  min-height: 350px; }
  .fk-item_plus .fk-item__number {
    position: absolute;
    left: 50%;
    top: 17px;
    margin-left: -75px;
    font: bold 30px Gotham, sans-serif; }
  .fk-item_plus .fk-item__icon {
    height: 103px;
    color: #0094db;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 5px;
    width: 100px;
    margin: 0 auto; }
    .fk-item_plus .fk-item__icon [class*="ic-"] {
      font-size: 58px; }
    .fk-item_plus .fk-item__icon .ic-ramp {
      top: 14px; }
  .fk-item_plus .fk-item__title {
    font: 18px;
    text-align: center;
    text-shadow: 1px 0 rgba(0, 0, 0, 0.3); }
  .fk-item_plus .fk-item__text {
    text-align: left; }
  @media screen and (max-width: 570px) {
    .fk-item_plus {
      min-height: 0;
      padding-bottom: 0; }
      .fk-item_plus .fk-item__title {
        font-size: 18px; }
      .fk-item_plus .fk-item__text {
        font-size: 16px; } }

.fk-item_our-figures {
  color: #fff;
  text-align: center;
  padding: 0 20px; }
  .fk-item_our-figures .fk-item__title {
    color: #fff;
    text-transform: none;
    font-weight: bold;
    font-size: 32px;
    height: 90px;
    line-height: NORMAL; }
  .fk-item_our-figures .fk-item__text {
    color: #fff; }
  @media screen and (max-width: 1140px) {
    .fk-item_our-figures {
      padding-bottom: 40px; }
      .fk-item_our-figures .fk-item__title {
        height: auto; } }
  @media screen and (max-width: 1024px) {
    .fk-item_our-figures .fk-item__title {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    .fk-item_our-figures .fk-item__title {
      font-size: 18px; } }
  @media screen and (max-width: 568px) {
    .fk-item_our-figures {
      padding: 0; }
      .fk-item_our-figures .fk-item__text {
        font-size: 15px;
        text-align: center;
        padding: 0 10px; } }

.fk-item__storage-map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

/*------------------------[ Map ]----------------------------*/
.fk-item__storage-map {
  text-align: center; }
  @media screen and (max-width: 1180px) {
    .fk-item__storage-map img {
      max-width: 50%; } }
  @media screen and (max-width: 690px) {
    .fk-item__storage-map {
      display: none; } }

/*------------------------[ grid Stages ]----------------------------*/
.section_stages .section__content {
  position: relative; }
  @media screen and (min-width: 1080px) {
    .section_stages .section__content {
      min-height: 550px; } }

@media screen and (min-width: 690px) {
  .grid_stages {
    padding-top: 55px;
    overflow: hidden; } }

@media screen and (min-width: 690px) {
  .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
    width: 33% !important;
    float: left;
    text-align: right; } }

@media screen and (max-width: 690px) {
  .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
    width: auto !important;
    padding: 0;
    border-left: 3px solid #0393d8;
    margin-left: 40px; }
    .grid_stages .grid__item:hover, .grid_stages .l-download__one:hover, .grid_stages .l-download__two:hover, .grid_stages .page-footer__menu:hover, .grid_stages .page-footer__contacts:hover, .grid_stages .page-footer__copyright:hover, .grid_stages .page-footer__company:hover, .grid_stages .page-footer__address:hover, .grid_stages .page-footer__soc-icons:hover {
      border-left: 3px solid #0393d8; } }

@media screen and (min-width: 690px) {
  .grid_stages .grid__item.last, .grid_stages .last.l-download__one, .grid_stages .last.l-download__two, .grid_stages .last.page-footer__menu, .grid_stages .last.page-footer__contacts, .grid_stages .last.page-footer__copyright, .grid_stages .last.page-footer__company, .grid_stages .last.page-footer__address, .grid_stages .last.page-footer__soc-icons {
    text-align: left;
    float: right; }
    .grid_stages .grid__item.last .fk-item__number, .grid_stages .last.l-download__one .fk-item__number, .grid_stages .last.l-download__two .fk-item__number, .grid_stages .last.page-footer__menu .fk-item__number, .grid_stages .last.page-footer__contacts .fk-item__number, .grid_stages .last.page-footer__copyright .fk-item__number, .grid_stages .last.page-footer__company .fk-item__number, .grid_stages .last.page-footer__address .fk-item__number, .grid_stages .last.page-footer__soc-icons .fk-item__number {
      left: -75px !important; } }
    @media screen and (min-width: 690px) and (min-width: 1180px) {
      .grid_stages .grid__item.last .fk-item__number, .grid_stages .last.l-download__one .fk-item__number, .grid_stages .last.l-download__two .fk-item__number, .grid_stages .last.page-footer__menu .fk-item__number, .grid_stages .last.page-footer__contacts .fk-item__number, .grid_stages .last.page-footer__copyright .fk-item__number, .grid_stages .last.page-footer__company .fk-item__number, .grid_stages .last.page-footer__address .fk-item__number, .grid_stages .last.page-footer__soc-icons .fk-item__number {
        left: -85px !important; } }

/*------------------------[ Stages ]----------------------------*/
.fk-item_stage {
  position: relative;
  max-width: 300px; }
  .fk-item_stage:hover .fk-item__title a {
    text-decoration: underline !important; }
  .fk-item_stage:hover .fk-item__number {
    background: #a4d435;
    color: #fff; }
  .fk-item_stage .fk-item__title {
    font: 18px 'GothamBold', sans-serif;
    color: #333; }
    .fk-item_stage .fk-item__title a {
      font: 18px 'GothamBold', sans-serif;
      color: #333; }
  .fk-item_stage .fk-item__number {
    position: absolute;
    right: -75px;
    top: 0;
    border: 3px solid #a4d435;
    border-radius: 100%;
    background: #fff;
    width: 65px;
    height: 65px;
    text-align: center;
    font-size: 36px;
    line-height: 65px;
    color: #888; }
    @media screen and (min-width: 1180px) {
      .fk-item_stage .fk-item__number {
        right: -85px; } }
  .fk-item_stage .fk-item__desc {
    font: 16px 'Calibri', sans-serif;
    color: #000; }
  @media screen and (min-width: 690px) {
    .fk-item_stage {
      display: inline-block; }
      .fk-item_stage.first-stage {
        top: -15px;
        right: 50px; }
        .fk-item_stage.first-stage .fk-item__number {
          top: 20px; }
      .fk-item_stage.second-stage {
        margin-top: 45px;
        right: 120px; }
      .fk-item_stage.third-stage {
        margin-top: 75px;
        right: 68px; }
      .fk-item_stage.fourth-stage {
        top: -15px;
        left: 50px; }
        .fk-item_stage.fourth-stage .fk-item__number {
          top: 20px; }
      .fk-item_stage.fifth-stage {
        left: 110px;
        margin-top: 28px; }
        .fk-item_stage.fifth-stage .fk-item__number {
          top: 20px; }
      .fk-item_stage.sixth-stage {
        margin-top: 88px;
        left: 67px; } }
  @media screen and (max-width: 1024px) {
    .fk-item_stage .fk-item__title {
      font: 16px 'GothamBold', sans-serif;
      color: #000; }
      .fk-item_stage .fk-item__title a {
        font: 16px 'GothamBold', sans-serif;
        color: #000; }
    .fk-item_stage .fk-item__desc {
      font-size: 14px; } }

@media screen and (max-width: 690px) {
  div.fk-item_stage {
    top: auto !important;
    left: auto !important;
    right: auto !important;
    margin: 0 0 40px 34px !important; }
    div.fk-item_stage .fk-item__number {
      right: auto !important;
      left: -60px !important;
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 25px; }
    div.fk-item_stage.sixth-stage {
      margin-bottom: 0 !important; }
      div.fk-item_stage.sixth-stage .fk-item__desc {
        margin-bottom: 0; }
      div.fk-item_stage.sixth-stage .fk-item__number:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 17px;
        margin-top: 3px;
        width: 10px;
        height: 30px;
        background: #fff; } }

@media screen and (min-width: 690px) and (max-width: 800px) {
  .fk-item_stage {
    width: 200px; }
    .fk-item_stage .fk-item__title {
      font-size: 14px; }
      .fk-item_stage .fk-item__title a {
        font-size: 14px; }
  .first-stage {
    top: -58px !important;
    right: 10px !important; }
  .second-stage {
    margin-top: -30px !important;
    right: 60px !important;
    width: 200px; }
  .third-stage {
    margin-top: 5px !important;
    right: 40px !important; }
  .fourth-stage {
    top: -58px !important;
    left: 0 !important; }
    .fourth-stage .fk-item__desc {
      font-size: 13px; }
  .fifth-stage {
    left: 45px !important;
    top: -50px !important; }
  .sixth-stage {
    margin-top: -40px !important;
    left: 30px !important; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .fk-item_stage {
    width: 200px; }
  .first-stage {
    top: -55px !important;
    right: 20px !important; }
  .second-stage {
    margin-top: 0 !important;
    right: 60px !important;
    width: 200px; }
  .third-stage {
    margin-top: 15px !important;
    right: 50px !important; }
  .fourth-stage {
    top: -55px !important;
    left: 10px !important; }
  .fifth-stage {
    width: 200px;
    left: 55px !important;
    top: -40px !important; }
  .sixth-stage {
    margin-top: -40px !important;
    left: 40px !important; } }

@media screen and (min-width: 900px) and (max-width: 1180px) {
  .fk-item_stage {
    max-width: 200px; }
  .first-stage {
    top: -35px !important;
    right: 40px !important; }
  .fourth-stage {
    top: -35px !important;
    left: 30px !important; }
  .third-stage {
    margin-top: 15px !important;
    right: 50px !important; }
  .sixth-stage {
    margin-top: -10px !important;
    left: 30px !important; }
  .second-stage {
    margin-top: 0 !important;
    right: 80px !important; }
  .fifth-stage {
    left: 70px !important;
    top: -10px; } }

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .fk-item_stage {
    max-width: 200px; } }

.item__gallery {
  text-align: center;
  margin-top: 30px; }
  .item__gallery .item__gallery-item {
    display: inline-block;
    padding-right: 7px;
    height: 160px;
    overflow: hidden; }
    .item__gallery .item__gallery-item:last-child {
      padding-right: 0px; }
    .item__gallery .item__gallery-item.slick-slide {
      padding: 0 5px; }
    .item__gallery .item__gallery-item img {
      height: auto;
      width: 176px; }
  .item__gallery .slick-arrow:before {
    content: ''; }
  .item__gallery button {
    display: block;
    font-size: 0;
    top: 66% !important;
    transform: none;
    background: rgba(0, 0, 0, 0.2) !important; }
    .item__gallery button:hover {
      background: rgba(255, 255, 255, 0.7) !important;
      color: #0094db !important; }
  .item__gallery .slick-dots {
    bottom: -30px; }

.complex {
  text-align: left;
  width: 294px;
  position: relative;
  max-width: 100%; }
  .complex:before, .complex:after {
    position: absolute;
    left: 100%;
    font-size: 12px;
    color: #666; }
  .complex:before {
    content: '0%';
    bottom: 0; }
  .complex:after {
    content: '100%';
    top: 0; }
  .complex > div.complex__wrap {
    display: inline-block;
    border-right: 3px solid #c3eaf9;
    padding-right: 20px;
    position: relative; }
    .complex > div.complex__wrap:before, .complex > div.complex__wrap:after {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      border-top: 1px dashed #666; }
    .complex > div.complex__wrap:before {
      content: '';
      top: 15px; }
    .complex > div.complex__wrap:after {
      content: '';
      bottom: 15px; }
    @media (max-width: 768px) {
      .complex > div.complex__wrap {
        padding-right: 10px; } }
  .complex svg {
    width: 46px;
    margin-right: 15px;
    background: #fff;
    position: relative;
    z-index: 9; }
    @media (max-width: 768px) {
      .complex svg {
        margin-right: 7px; } }
  @media (max-width: 768px) {
    .complex {
      margin: 0 auto;
      width: 249px;
      transform: scale(0.9);
      position: relative;
      left: -10px; } }

.dwg__plan {
  position: absolute;
  right: 20px;
  top: 9px;
  color: #666666;
  font-size: 16px;
  text-transform: none;
  font-family: calibri; }
  .dwg__plan div {
    width: auto;
    padding-right: 10px; }
  .dwg__plan svg {
    width: 27px;
    color: #888888; }
  @media (max-width: 767px) {
    .dwg__plan {
      top: 36px;
      font-size: 14px; } }

.wbbg {
  background: #fff; }
  .wbbg .bg3 {
    background: #fff; }

.section_services {
  background: #fff;
  padding: 20px 7px !important; }

.sr-item {
  display: block;
  min-height: 280px;
  text-align: center;
  position: relative;
  border: 2px solid transparent; }
  .sr-item__title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 5px;
    font: 18px 'Gotham', sans-serif;
    color: #000;
    text-transform: uppercase; }
  .sr-item:hover {
    border-color: #a4d435; }

/*-----------------------------------------------------------
	Site Modal
-----------------------------------------------------------*/
.site-modal {
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  background: transparent;
  opacity: 0; }

.site-modal.active {
  display: block; }

.site-modal__table {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed; }

.site-modal__cell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  background: rgba(255, 255, 255, 0.5); }

.site-modal__window {
  display: inline-block;
  position: relative;
  text-align: left; }

.site-modal__close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer; }

.modal {
  display: none;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  max-width: 1200px;
  min-width: 150px;
  min-height: 150px; }

.site-modal__content.active {
  display: block !important; }

/*-----------------------------------------------------------
	/Site Modal
-----------------------------------------------------------*/
/*-----------------------------------------------------------
	Custom
-----------------------------------------------------------*/
.site-modal__cell {
  background: rgba(0, 0, 0, 0.8); }

.site-modal__window {
  padding: 20px; }
  @media screen and (max-width: 660px) {
    .site-modal__window {
      padding: 0; } }

.modal {
  position: relative;
  max-width: 1200px;
  padding: 50px 40px; }
  .modal__close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #5c5c5c;
    cursor: pointer; }
    .modal__close:hover {
      color: #0094db; }
  .modal__title {
    font: 24px Gotham, sans-serif;
    text-transform: uppercase;
    text-align: center; }
  .modal__content {
    margin-top: 60px; }
  @media screen and (max-width: 660px) {
    .modal {
      padding: 40px 15px; }
      .modal__close {
        top: 10px;
        right: 10px; } }

.modal_detailed {
  padding: 25px 20px 30px; }
  @media screen and (max-width: 1024px) {
    .modal_detailed {
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 15px; } }
  @media screen and (max-width: 660px) {
    .modal_detailed {
      width: 100%;
      padding-top: 60px; } }
  @media screen and (max-width: 480px) {
    .modal_detailed {
      padding-top: 5px;
      padding-right: 0;
      padding-left: 0; } }

/*.modal_dark{
	position: relative;
	max-width: 440px;
	padding: 45px 30px 25px;
	text-align: center;
	color: #fff;
	background: #000;
		

	.modal__close{
		color: #fff;
		
		&:hover{
			color: getBase('color-two');
		}
	}
	
	.modal__title{
		font: 24px Calibri, sans-serif;
		text-transform: inherit;
		text-align: center;
		color: #fff;
	}
	
	.modal__content{
		font-size: 16px;
		margin-top: 15px;
	}
}*/
/*-------------------Confidentiality------------------------*/
.modal_confidentiality {
  max-width: 900px; }
  @media screen and (max-width: 480px) {
    .modal_confidentiality .modal__title {
      font-size: 20px; } }
  @media screen and (max-width: 320px) {
    .modal_confidentiality .modal__title {
      font-size: 18px; } }

.modal_success-request {
  max-width: 760px;
  text-align: center;
  padding: 25px 55px 55px; }
  .modal_success-request .modal__content {
    margin-top: 0; }
  .modal_success-request .modal__img {
    margin-bottom: 30px; }
    .modal_success-request .modal__img img {
      max-width: 100%; }
  .modal_success-request .modal__title {
    font-size: 36px;
    text-transform: none;
    margin-bottom: 15px; }
  .modal_success-request .modal__desc {
    font-size: 18px;
    margin-bottom: 28px; }
  .modal_success-request .modal__button {
    width: 310px;
    font-size: 18px; }
  @media screen and (max-width: 768px) {
    .modal_success-request .modal__title {
      font-size: 30px; } }
  @media screen and (max-width: 550px) {
    .modal_success-request .modal__title {
      font-size: 26px; } }
  @media screen and (max-width: 480px) {
    .modal_success-request .modal__title {
      font-size: 20px; }
    .modal_success-request .modal__desc {
      font-size: 16px; } }
  @media screen and (max-width: 350px) {
    .modal_success-request .modal__button {
      width: 100%;
      font-size: 16px; } }

.zcwPopup-bg {
  display: none;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 99991 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.8) !important;
  cursor: pointer !important; }

.zcwPopup {
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -220px;
  display: none;
  text-align: center;
  position: fixed;
  width: 440px;
  z-index: 99992 !important;
  height: auto !important;
  background-color: #000;
  color: #fff;
  padding: 45px 30px 25px;
  max-height: 100%;
  overflow-y: auto;
  border: 2px solid white; }

.zcwPopup-title,
.zcwPopup-description,
.zcwPopup-content {
  margin: 0; }

.zcwPopup-title {
  font: 24px Gotham,sans-serif;
  text-transform: inherit;
  text-align: center;
  color: #fff;
  margin-bottom: 12px; }

#zcwPopup-raty img {
  display: inline !important; }

.zcwPopup-close {
  cursor: pointer !important;
  text-decoration: none;
  color: grey;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 0; }
  .zcwPopup-close:after {
    content: "";
    font-family: fk;
    font-size: 20px; }
  .zcwPopup-close:hover {
    color: #0094db; }

.zcwPopup-content {
  font-size: 16px; }

.zcwPopup-description {
  margin-bottom: 17px; }

.zcwPopup-countdown {
  font-weight: bold;
  display: none;
  font-size: 25px;
  width: 100%; }

.zcwPopup .link {
  text-decoration: underline;
  cursor: pointer; }

.zcwPopup .powered_by {
  margin-top: 7px;
  font-size: 12px;
  color: #777777 !important; }

.zcwPopup .powered_by a {
  font-weight: bold !important;
  color: #777777 !important;
  text-decoration: underline !important; }

.zcwPopup form {
  font-size: 0; }

.zcwPopup input[type="text"] {
  font-size: 18px;
  line-height: 58px;
  padding: 0 28px;
  border: 1px solid #d9d9d9;
  background: 0 0;
  width: 100%;
  color: #d9d9d9;
  margin-bottom: 10px; }

.zcwPopup select {
  font-size: 18px;
  margin: 0;
  color: #d9d9d9;
  border: 1px solid #d9d9d9;
  display: inline !important;
  background: #000 url(../img/drop-down.png) no-repeat;
  background-position: right 15px top 24px;
  height: 58px;
  padding-left: 26px; }
  .zcwPopup select:hover, .zcwPopup select:focus {
    background-image: url(../img/drop-down-hover.png); }

.zcwPopup select[name="day"] {
  width: 144px; }

.zcwPopup select[name="hour"] {
  width: 108px;
  margin-left: 10px; }

.zcwPopup select[name="minute"] {
  width: 108px;
  margin-left: 10px; }

.zcwPopup textarea {
  font-size: 18px;
  color: #d9d9d9; }

.zcwPopup button,
.zcwPopup input[type="submit"],
.zcwPopup input[type="button"] {
  border: 2px solid #8dd50e;
  padding: 0 40px;
  color: #8dd50e;
  font: 700 18px/18px Gotham,sans-serif;
  height: 53px;
  line-height: 52px;
  background: 0 0;
  margin-top: 10px;
  margin-bottom: 10px; }
  .zcwPopup button:hover,
  .zcwPopup input[type="submit"]:hover,
  .zcwPopup input[type="button"]:hover {
    background: #8dd50e;
    color: #000; }

.zcwPopup input.zcwInputError,
.zcwPopup select.zcwInputError,
.zcwPopup textarea.zcwInputError {
  border: 1px solid red; }

#zcwMiniButton {
  position: fixed;
  right: 100px;
  bottom: 100px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  opacity: 0.5;
  z-index: 99990; }

#zcwMiniButton #zcwMiniButtonMain {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #2e77b3;
  width: 70px;
  height: 70px;
  -webkit-animation: zcwmini 1.5s 0s ease-out infinite;
  -moz-animation: zcwmini 1.5s 0s ease-out infinite;
  animation: zcwmini 1.5s 0s ease-out infinite; }

#zcwMiniButton #zcwMiniButtonMain:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(images/mini.png);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-animation: zcwphone 1.5s linear infinite;
  -moz-animation: zcwphone 1.5s linear infinite;
  animation: zcwphone 1.5s linear infinite; }

#zcwPopup-rate {
  line-height: 8px; }

@media screen and (max-width: 440px) {
  .zcwPopup {
    top: 0;
    width: 100%;
    margin-left: 0;
    left: 0;
    padding-top: 15px; }
    .zcwPopup select {
      width: 100% !important;
      margin-left: 0 !important;
      margin-bottom: 10px;
      height: 36px;
      background-position: right 15px top 14px; }
    .zcwPopup input[type="text"] {
      line-height: 36px; }
    .zcwPopup input[type="submit"] {
      height: 45px;
      line-height: 42px; } }

.modal_video {
  padding: 0; }
  .modal_video .modal__close {
    right: -30px;
    top: -30px;
    color: #fff; }
    .modal_video .modal__close:hover {
      color: #0094db; }

.modal_player {
  padding: 0;
  width: 900px;
  height: 505px; }
  .modal_player .modal__close {
    right: -30px;
    top: -30px;
    color: #fff; }
    .modal_player .modal__close:hover {
      color: #0094db; }
  @media screen and (max-width: 990px) {
    .modal_player {
      width: 675px;
      height: 377px; } }
  @media screen and (max-width: 760px) {
    .modal_player {
      width: 90vw;
      height: 320px; }
      .modal_player .modal__close {
        display: none; } }
  @media screen and (max-width: 660px) {
    .modal_player {
      width: 95vw;
      height: 280px; }
      .modal_player .modal__close {
        display: none; } }

.gallery {
  width: 100%; }
  .gallery__main {
    position: relative; }
  .gallery__main-img {
    min-width: 100%;
    max-width: 100%; }
  .gallery__thumb-wrap {
    display: inline-block;
    width: 25%;
    padding: 13px 0 0 13px;
    cursor: pointer; }
  .gallery__thumbs {
    font-size: 0;
    line-height: 0;
    margin-left: -13px; }
  .gallery__thumb {
    min-width: 100%;
    max-width: 100%; }
  .gallery__thumb-wrap.active .gallery__thumb {
    border: 3px solid #0094db; }

/*-----------------------------------------------------------
	Custom
-----------------------------------------------------------*/
.detailed__title {
  font: 30px/40px Gotham, sans-serif;
  width: 100%;
  padding-right: 50px; }

.detailed__gallery {
  width: 50%;
  float: right;
  margin-top: 15px; }

.detailed__info {
  width: 50%;
  float: left;
  padding-top: 25px;
  padding-right: 50px; }

.detailed__info-text {
  font-size: 18px;
  line-height: 18px; }

.detailed__info-blocks {
  display: block;
  font-size: 0;
  line-height: 0;
  margin-bottom: 10px; }

.detailed__info-block {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: normal;
  border: 3px solid #000;
  width: 117px;
  height: 117px;
  padding: 30px 8px;
  vertical-align: top; }
  .detailed__info-block:last-child {
    margin-right: 0; }

.detailed__info-block-value {
  font: 22px Gotham, sans-serif; }

.detailed__info-block_city {
  border-color: #0094db; }
  .detailed__info-block_city .detailed__info-block-value {
    font-size: 16px;
    font-weight: bold; }

.detailed__info-block_year {
  border-color: #8dd50e; }

.detailed__info-block_area {
  border-color: #aaaaaa; }

.detailed__info-block_time {
  border-color: #000; }

.detailed__arrows {
  width: 100%;
  overflow: hidden;
  padding-top: 20px; }

.detailed__arrow {
  text-transform: uppercase;
  font-size: 16px;
  color: #595959;
  cursor: pointer;
  line-height: 25px;
  text-decoration: underline; }
  .detailed__arrow:hover {
    color: #0094db; }

.detailed__arrow:after, .detailed__arrow:before {
  position: relative;
  font-family: fk;
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  top: -1px;
  text-decoration: none; }

.detailed__arrow_left {
  float: left; }
  .detailed__arrow_left:before {
    content: "";
    margin-right: 8px; }

.detailed__arrow_right {
  float: right; }
  .detailed__arrow_right:after {
    content: "";
    margin-left: 8px; }

.detailed__mobile-arrows {
  display: none;
  overflow: hidden;
  padding: 10px;
  text-align: center; }
  .detailed__mobile-arrows.detailed__mobile-arrows_top {
    display: block; }
    .detailed__mobile-arrows.detailed__mobile-arrows_top .modal__close {
      display: none; }
    @media (min-width: 768px) {
      .detailed__mobile-arrows.detailed__mobile-arrows_top .detailed__arrow {
        top: -5px;
        position: absolute; }
        .detailed__mobile-arrows.detailed__mobile-arrows_top .detailed__arrow_left {
          right: 75px; }
        .detailed__mobile-arrows.detailed__mobile-arrows_top .detailed__arrow_right {
          right: 33px; } }

@media screen and (max-width: 1180px) {
  .detailed__info-block {
    margin-right: 10px;
    font-size: 14px;
    width: 115px;
    height: 115px;
    padding: 30px 15px 15px; }
  .detailed__info {
    padding-right: 40px; } }

@media screen and (max-width: 1024px) {
  .detailed {
    /*		.modal__close{
			font-size: 16px;
			top: 25px;
			right: 10px;
		}*/ }
    .detailed__info-blocks {
      width: 170px;
      float: right;
      text-align: right; }
    .detailed__info-block {
      text-align: left;
      margin-right: 0;
      font-size: 14px;
      width: 115px;
      height: 115px;
      padding: 30px 15px 15px;
      margin-bottom: 15px; }
    .detailed__title {
      width: 100%;
      font-size: 30px;
      padding-right: 40px; }
    .detailed__info, .detailed__gallery {
      float: none;
      padding: 0;
      width: 100%;
      margin-top: 15px; }
    .detailed__info-text {
      padding-top: 20px;
      margin-right: 170px; } }

@media screen and (max-width: 768px) {
  .detailed__info-text {
    padding-top: 0; } }

@media screen and (max-width: 660px) {
  .detailed {
    /*.modal__close{
			font-size: 16px;
			top: 15px;
			right: 10px;
		}*/ }
    .detailed__title {
      font-size: 18px;
      line-height: normal;
      text-align: center; }
    .detailed__info-blocks {
      text-align: left;
      float: none;
      width: 100%; }
    .detailed__info-block {
      border: none;
      height: auto;
      width: auto;
      margin-right: 15px;
      padding: 8px 10px;
      background: #000; }
    .detailed__info-block-value {
      font-size: 16px; }
    .detailed__info-block_city {
      background: #0094db; }
    .detailed__info-block_year {
      background: #8dd50e; }
    .detailed__info-block_area {
      background: #aaaaaa; }
    .detailed__info-block_time {
      background: #000;
      color: #fff; }
    .detailed__info-block-label {
      display: none; }
    .detailed__info-text {
      margin: 0; }
    .detailed .detailed__mobile-close {
      display: inline-block !important;
      position: static;
      text-align: center;
      color: #717a81;
      font-size: 18px; }
      .detailed .detailed__mobile-close .ic-close {
        vertical-align: middle; }
      .detailed .detailed__mobile-close:hover {
        color: #0094db; }
    .detailed__arrows {
      display: none; }
    .detailed__mobile-arrows {
      display: block; } }

@media screen and (max-width: 480px) {
  .detailed__info {
    padding-left: 7px;
    padding-right: 7px; } }

.second-level-menu {
  background: #d6d6d6;
  position: fixed;
  width: 100%;
  height: 43px;
  z-index: 9999;
  right: 0;
  top: 0;
  left: 0;
  opacity: 0; }

.portfolio-item {
  height: auto;
  position: relative;
  cursor: pointer;
  overflow: hidden; }
  .portfolio-item__img {
    width: 100%; }
  .portfolio-item__info {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.76);
    color: #fff;
    display: none;
    padding: 2.60417vw 1.875vw; }
  .portfolio-item__title {
    font-family: Gotham, sans-serif;
    line-height: 1.4;
    text-decoration: underline;
    overflow: hidden;
    font-size: 1.25vw;
    margin-bottom: 1.35417vw; }
    @media screen and (max-width: 960px) {
      .portfolio-item__title {
        font-size: 12px; } }
    @media screen and (max-width: 590.76923px) {
      .portfolio-item__title {
        margin-bottom: 8px; } }
  .portfolio-item__tags {
    margin-bottom: 1.875vw; }
    @media screen and (max-width: 746.66667px) {
      .portfolio-item__tags {
        margin-bottom: 14px; } }
  .portfolio-item__tag {
    display: inline-block;
    background: #fff;
    color: #000;
    font-weight: 300;
    text-transform: uppercase;
    margin-right: 0.52083vw;
    height: 2.08333vw;
    line-height: 2.08333vw;
    padding-right: 0.67708vw;
    padding-left: 0.67708vw;
    font-size: 0.9375vw; }
    @media screen and (max-width: 576px) {
      .portfolio-item__tag {
        margin-right: 3px; } }
    @media screen and (max-width: 864px) {
      .portfolio-item__tag {
        height: 18px; } }
    @media screen and (max-width: 864px) {
      .portfolio-item__tag {
        line-height: 18px; } }
    @media screen and (max-width: 295.38462px) {
      .portfolio-item__tag {
        padding-right: 2px; } }
    @media screen and (max-width: 295.38462px) {
      .portfolio-item__tag {
        padding-left: 2px; } }
    @media screen and (max-width: 853.33333px) {
      .portfolio-item__tag {
        font-size: 8px; } }
    .portfolio-item__tag_one {
      background: #0094db;
      color: #fff; }
    .portfolio-item__tag_two {
      background: #8dd50e;
      color: #000; }
    .portfolio-item__tag_three {
      background: #a1a1a1;
      color: #000; }
  .portfolio-item__client {
    font-size: 0.9375vw; }
    @media screen and (max-width: 1066.66667px) {
      .portfolio-item__client {
        font-size: 10px; } }
  .portfolio-item__description {
    font-size: 0.9375vw;
    height: 21.875vw;
    font-weight: 300; }
    @media screen and (max-width: 1066.66667px) {
      .portfolio-item__description {
        font-size: 10px; } }
    @media screen and (max-width: 914.28571px) {
      .portfolio-item__description {
        height: 200px; } }
  .mouse-device .portfolio-item:hover .portfolio-item__info {
    display: block; }
  .touch-device .portfolio-item.hovered .portfolio-item__info {
    display: block; }
  @media screen and (max-width: 768px) {
    .portfolio-item__title {
      font-size: 2.60417vw;
      margin-bottom: 2.60417vw; }
    .portfolio-item__description, .portfolio-item__client, .portfolio-item__tag {
      font-size: 2.34375vw; }
    .portfolio-item__tags {
      margin-bottom: 1.95312vw; }
    .portfolio-item__tag {
      margin-right: 1.17188vw;
      height: 4.55729vw;
      line-height: 4.55729vw;
      padding: 0 1.30208vw; }
    .portfolio-item__info {
      padding: 2.60417vw;
      padding-bottom: 0; } }
  @media screen and (max-width: 480px) {
    .portfolio-item__title {
      font-size: 5.41667vw;
      margin-bottom: 6.04167vw; }
    .portfolio-item__description, .portfolio-item__client, .portfolio-item__tag {
      font-size: 4.58333vw; }
    .portfolio-item__tags {
      margin-bottom: 5.41667vw; }
    .portfolio-item__tag {
      margin-right: 1.875vw;
      height: 7.91667vw;
      line-height: 7.91667vw;
      padding: 0 2.70833vw; }
    .portfolio-item__info {
      padding: 4.16667vw;
      padding-bottom: 0; } }

/*#ui-datepicker-div{
	border-radius: 0;
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-top: 6px solid getBase('color-one');
	padding: 0;
	font: 14px Calibri, sans-serif;
	color: #fff;
	text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
	background: #363636;
	box-shadow: 0 0 10px rgba(0,0,0,0.8);
	
	.ui-datepicker-header{
		background: #363636 url(../img/calendar-header.png);
		border-radius: 0;
		border: 0;
		color: #fff;
		text-transform: uppercase;
		padding: 0;
	}
	
	.ui-datepicker-title{
		height: 32px;
		line-height: 31px;
	}
	
	.ui-datepicker-prev,
	.ui-datepicker-next{
		border: none;
		border-radius: 0;
		background: transparent;
		color: #fff;
		font-size: 10px;
		cursor: pointer;
		height: 32px;
		line-height: 31px;
		text-align: center;
		width: 32px;
		font-weight: normal;
		top: 0px !important;
	}
	
	.ui-datepicker-prev{
		left: 0px !important;
		&:after{
			content: '◀';
		}
	}
	
	.ui-datepicker-next{
		right: 0px !important;
		&:after{
			content: '▶';
		}
	}
	
	.ui-datepicker-calendar{
		margin-bottom: 0;
	}
	
	thead th{
		background: #222222;
		color: #fff;
		font-size: 9px;
		text-transform: uppercase;
	}
	
	td{
		padding: 0;
		border: none;
		background: #363636;
		border: 1px solid #303030;
		span, a{
			color: #fff;
			border: 1px solid #4c4c4c;
			background: #424242;
			background: -moz-linear-gradient(top,  #444444 0%, #3a3a3a 100%);
			background: -webkit-linear-gradient(top,  #444444 0%,#3a3a3a 100%);
			background: linear-gradient(to bottom,  #444444 0%,#3a3a3a 100%);
			height: 32px;
			line-height: 31px;
			font-size: 14px;
			text-align: center;
			padding: 0;
			
			&:hover{
				background: getBase('color-two');
				color: #000;
				text-shadow: none;
			}
		}
	}
}*/
/*.select-customizer-done{
    display: none;
}

.select-customizer{
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    width: 200px;
    box-sizing: border-box;
    position: relative;
    padding: 2px 5px;
    color: #777;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.select-customizer-inner{
    position: absolute;
    top: 100%;
    left: 0;
    margin-left: -1px;
    display: none;
    width: auto;
    box-sizing: border-box;
    min-width: 200px;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 1px rgba(0,0,0,0.1);
    background: #fff;
}

.select-customizer.active .select-customizer-inner{
    display: block;
}

.select-customizer-inner > *{
    padding: 2px 5px;
}

.select-customizer-inner > *:hover{
    background: #eee;
}*/
/*-----------------------------------------------------------
	Custom
-----------------------------------------------------------*/
/*
.custom-select{
	display: inline-block;
	border: 1px solid #d9d9d9;
	position: relative;
	cursor: pointer;
	line-height: 0;
	
	&:after{
		content: url(../img/drop-down.png);
		position: absolute;
		right: 18px;
		top: 24px;
		display: inline-block;
		line-height: 10px;
	}
	
	&:hover:after{
		content: url(../img/drop-down-hover.png);
		color: getBase('color-one);
	}
	
	.text-field_select{
		border: none;
		cursor: pointer;
		padding-right: 50px;
		margin: 0;
	}
}

.select-customizer{
	font-size: 18px;
	line-height: normal;
	width: 100%;
	display: inline-block;
	border: 1px solid #d9d9d9;
	position: relative;
	cursor: pointer;
	padding: 18px 20px;
	border-radius: 0;
	.select-customizer-done{
		display: none;
	}
	
	.select-customizer-active-option{
		color: #909090;
	}
	
	.select-customizer-inner{
		width: auto;
		min-width: 100%;
		background: #111;
		color: #fff;
		max-height: 200px;
		overflow-y: auto;
		border: 0;
		border-radius: 0;
	}
}*/
.text-field {
  font-size: 18px;
  line-height: 58px;
  padding: 0 28px;
  border: 1px solid #d9d9d9;
  background: transparent;
  width: 100%;
  margin-bottom: 21px; }
  .text-field.error {
    border-color: #ff6666;
    color: #ff6666; }
    .text-field.error::-webkit-input-placeholder {
      color: #ff6666; }
    .text-field.error::-moz-placeholder {
      color: #ff6666; }
    .text-field.error:-moz-placeholder {
      color: #ff6666; }
    .text-field.error:-ms-input-placeholder {
      color: #ff6666; }
    .text-field.error:placeholder {
      color: #ff6666; }

textarea.text-field {
  padding: 18px 28px;
  line-height: normal; }

.formated-text {
  font-size: 16px; }
  .formated-text p {
    margin-top: 0;
    margin-bottom: 30px; }
  .formated-text h4 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: normal; }

.formated-text_detailed p {
  margin-bottom: 18px; }

.athlet-review {
  position: relative;
  padding: 0 10px; }
  .athlet-review__parallax_one, .athlet-review__parallax_two {
    font: bold 160px/160px Gotham, sans-serif;
    color: #f0f0f0;
    position: absolute;
    white-space: nowrap;
    transform-style: flat; }
  .athlet-review__parallax_one {
    top: -20px;
    right: -50px; }
  .athlet-review__parallax_two {
    top: 140px;
    right: 130px; }
  .athlet-review__about-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 225px; }
  .athlet-review__about-title {
    font-size: 18px;
    text-transform: uppercase; }
  .athlet-review__about-text {
    font-size: 14px;
    color: #939393; }
  .athlet-review__about, .athlet-review__text {
    width: 50%; }
  .athlet-review__title {
    margin-bottom: 30px;
    font: 36px GothamMedium,sans-serif;
    color: #0094db;
    text-transform: uppercase; }
  .athlet-review__about {
    float: left;
    position: relative; }
    .athlet-review__about img {
      max-width: 100%; }
  .athlet-review__text {
    float: right;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
    font-size: 18px;
    text-align: left; }
    .athlet-review__text p {
      margin-top: 0; }
  .athlet-review__soc-comment {
    margin-top: 80px;
    min-height: 155px; }
  .athlet-review__video-play {
    padding-top: 30px;
    position: relative; }
  .athlet-review__video-play-btn {
    position: absolute;
    left: 32%;
    top: 25%;
    cursor: pointer; }
    .athlet-review__video-play-btn:after {
      content: '';
      display: block;
      width: 94px;
      height: 94px;
      background-image: url(../img/icons/play-white.png); }
    .athlet-review__video-play-btn:hover:after {
      background-image: url(../img/icons/play-blue.png); }

.athlet-review_reverse .athlet-review__parallax_one {
  right: 20px; }

.athlet-review_reverse .athlet-review__parallax_two {
  right: 120px; }

.athlet-review_reverse .athlet-review__video-play-btn {
  top: auto;
  bottom: 35%;
  right: auto;
  left: 45%; }

.athlet-review_nikita .athlet-review__video-play-btn {
  top: auto;
  bottom: 35%;
  right: auto;
  left: 35%; }

.athlet-review_fulltime,
.athlet-review_fulltime2 {
  padding: 80px 5px 0;
  overflow: hidden; }
  .athlet-review_fulltime .athlet-review__content,
  .athlet-review_fulltime2 .athlet-review__content {
    height: 100%;
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
  .athlet-review_fulltime .athlet-review__text,
  .athlet-review_fulltime2 .athlet-review__text {
    padding-bottom: 70px;
    height: 100%; }

.athlet-review_fulltime2 {
  padding-top: 0; }

@media screen and (min-width: 1025px) {
  .home .athlet-review_reverse {
    margin-bottom: 80px; }
  .athlet-review_fulltime {
    margin-bottom: 0; }
  .athlet-review_reverse .athlet-review__parallax_one,
  .athlet-review_nikita .athlet-review__parallax_one {
    top: -20px;
    right: auto;
    left: 0; }
  .athlet-review_reverse .athlet-review__parallax_two,
  .athlet-review_nikita .athlet-review__parallax_two {
    top: 140px;
    right: auto;
    left: 0; }
  .athlet-review_reverse .athlet-review__about,
  .athlet-review_nikita .athlet-review__about {
    float: right; }
  .athlet-review_reverse .athlet-review__text,
  .athlet-review_nikita .athlet-review__text {
    padding-left: 0;
    padding-right: 100px;
    padding-top: 60px;
    float: left; }
  .athlet-review_reverse .athlet-review__about-info,
  .athlet-review_nikita .athlet-review__about-info {
    top: 60px;
    left: 50%;
    text-align: right;
    width: 200px; }
  .athlet-review_reverse .athlet-review__video-play,
  .athlet-review_nikita .athlet-review__video-play {
    padding: 0;
    padding-left: 40px; }
  .athlet-review_reverse .athlet-review__video-play-btn,
  .athlet-review_nikita .athlet-review__video-play-btn {
    right: 48%;
    top: 40%; }
  .athlet-review_nikita {
    height: 700px; }
    .athlet-review_nikita .athlet-review__about {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60%;
      margin-right: -10%; }
      .athlet-review_nikita .athlet-review__about img {
        width: 100%; }
    .athlet-review_nikita .athlet-review__about-info {
      top: 120px;
      right: 30px;
      left: auto;
      text-align: right;
      width: 200px; }
    .athlet-review_nikita .athlet-review__text {
      padding-top: 90px;
      padding-right: 20px; }
    .athlet-review_nikita .athlet-review__video-play-btn {
      right: 48%;
      top: 40%; }
  .athlet-review_konstantin {
    height: 700px; }
    .athlet-review_konstantin .athlet-review__about {
      position: absolute;
      bottom: 0;
      width: 60%;
      margin-left: -10%; }
      .athlet-review_konstantin .athlet-review__about img {
        width: 100%; }
  .athlet-review_max {
    height: 700px; }
    .athlet-review_max .athlet-review__about {
      position: absolute;
      bottom: 0;
      right: 0; }
      .athlet-review_max .athlet-review__about img {
        width: 100%; }
    .athlet-review_max .athlet-review__about-info {
      top: 0;
      width: 290px;
      left: auto;
      right: 50%;
      margin-right: -250px; }
    .athlet-review_max .athlet-review__text {
      padding-top: 90px;
      padding-right: 20px; } }

@media screen and (max-width: 1024px) {
  .athlet-review {
    padding: 0;
    overflow: hidden; }
  .athlet-review__about {
    margin-left: -10%;
    width: 60%; }
  .athlet-review__about-info {
    position: static;
    text-align: left;
    width: 100%;
    max-width: 230px;
    margin-bottom: 30px; }
  .athlet-review__about-text {
    color: #000; }
  .athlet-review__text {
    padding-left: 0;
    padding-right: 20px; }
  .athlet-review__comment {
    font-size: 16px; }
  .athlet-review__soc-comment {
    margin-top: 30px; }
  .athlet-review__parallax_one,
  .athlet-review__parallax_two {
    font-size: 90px;
    line-height: 90px;
    left: 48%; }
  .athlet-review__parallax_one {
    top: 20px; }
  .athlet-review__parallax_two {
    top: 120px; }
  .athlet-review__video-play {
    padding: 0; }
  .athlet-review_reverse .athlet-review__parallax_one {
    left: 35%; }
  .athlet-review_reverse .athlet-review__parallax_two {
    left: 40%; } }

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .athlet-review_nikita .athlet-review__text {
    float: left;
    padding-left: 10px; }
  .athlet-review_nikita .athlet-review__about {
    position: absolute;
    bottom: 29px;
    right: 0;
    margin-right: -10%;
    margin-left: 0; }
  .athlet-review_nikita .athlet-review__parallax_one {
    top: 0;
    left: 0;
    right: auto; }
  .athlet-review_nikita .athlet-review__parallax_two {
    top: 100px;
    left: 0;
    right: auto; } }

@media screen and (max-width: 700px) {
  .athlet-review__about {
    margin-left: 0;
    width: 100%;
    float: none; }
    .athlet-review__about img {
      max-width: none;
      height: 100%;
      display: inline-block; }
  .athlet-review__video-play {
    height: 390px;
    text-align: right; }
  .athlet-review_reverse .athlet-review__video-play {
    text-align: left; }
  .athlet-review__text {
    width: 100%;
    float: none;
    padding: 0;
    padding-bottom: 20px; }
  .athlet-review__about-info {
    background: #f7f7f7;
    text-align: center;
    width: 100%;
    max-width: 100%;
    padding: 15px 10px; }
  .athlet-review__comment,
  .athlet-review__soc-comment {
    padding: 0 10px; }
  .athlet-review__parallax_one,
  .athlet-review__parallax_two {
    font-size: 79px;
    line-height: 79px; }
  .athlet-review__parallax_one {
    top: 240px;
    left: auto;
    right: 0; }
  .athlet-review__parallax_two {
    top: 310px;
    left: 0;
    right: auto; }
  .athlet-review__title {
    text-align: center;
    margin-top: 20px;
    font-size: 24px; }
  .athlet-review_reverse .athlet-review__parallax_one {
    top: 0;
    left: auto;
    right: -80px; }
  .athlet-review_reverse .athlet-review__parallax_two {
    top: 79px;
    right: auto;
    left: 0; }
  .athlet-review_nikita .athlet-review__parallax_one {
    top: 0;
    left: auto;
    right: 20px; }
  .athlet-review_nikita .athlet-review__parallax_two {
    top: 79px;
    right: auto;
    left: 0; } }

@media screen and (max-width: 500px) {
  .athlet-review__video-play {
    height: 300px; }
  .athlet-review__parallax_one,
  .athlet-review__parallax_two {
    font-size: 50px;
    line-height: 50px; }
  .athlet-review__parallax_one {
    top: 200px; }
  .athlet-review__parallax_two {
    top: 250px; }
  .athlet-review_reverse .athlet-review__parallax_one {
    top: 0;
    left: auto;
    right: -30px; }
  .athlet-review_reverse .athlet-review__parallax_two {
    top: 50px;
    right: auto;
    left: 0; } }

@media screen and (max-width: 385px) {
  .athlet-review__video-play {
    height: 250px; }
  .athlet-review__parallax_one {
    top: 150px; }
  .athlet-review__parallax_two {
    top: 200px; } }

@media screen and (max-width: 340px) {
  .athlet-review__parallax_one {
    top: 160px; }
  .athlet-review__parallax_two {
    top: 210px; } }

.bg1 {
  padding: 49px 0 0; }

.nw {
  white-space: nowrap; }

.raid {
  text-align: center; }
  .raid h1 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 30px; }

.row-gallery .raid__img {
  position: relative;
  padding: 0 34px; }
  @media (min-width: 1400px) {
    .row-gallery .raid__img:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 0;
      height: 2px;
      width: 100%;
      background: transparent; } }

.row-gallery .raid h1 {
  font-size: 24px; }

.row-gallery .section_reasons-to-build {
  padding: 0 0 20px; }

.row-gallery .grid_ramp.green-line .raid__img:before {
  background: #a4d435; }

.row-gallery .grid_ramp.blue-line .raid__img:before {
  background: #0393d8; }

.row-gallery .grid_ramp.red-line .raid__img:before {
  background: #fb5800; }

@media (min-width: 768px) {
  .row-gallery .grid_ramp .grid__item, .row-gallery .grid_ramp .l-download__one, .row-gallery .grid_ramp .l-download__two, .row-gallery .grid_ramp .page-footer__menu, .row-gallery .grid_ramp .page-footer__contacts, .row-gallery .grid_ramp .page-footer__copyright, .row-gallery .grid_ramp .page-footer__company, .row-gallery .grid_ramp .page-footer__address, .row-gallery .grid_ramp .page-footer__soc-icons {
    width: 33.3333% !important; } }

@media (min-width: 1400px) {
  .row-gallery .grid_ramp .grid__item, .row-gallery .grid_ramp .l-download__one, .row-gallery .grid_ramp .l-download__two, .row-gallery .grid_ramp .page-footer__menu, .row-gallery .grid_ramp .page-footer__contacts, .row-gallery .grid_ramp .page-footer__copyright, .row-gallery .grid_ramp .page-footer__company, .row-gallery .grid_ramp .page-footer__address, .row-gallery .grid_ramp .page-footer__soc-icons {
    width: 16.6667% !important; } }

@media (max-width: 767px) {
  .row-gallery .grid_ramp .grid__item, .row-gallery .grid_ramp .l-download__one, .row-gallery .grid_ramp .l-download__two, .row-gallery .grid_ramp .page-footer__menu, .row-gallery .grid_ramp .page-footer__contacts, .row-gallery .grid_ramp .page-footer__copyright, .row-gallery .grid_ramp .page-footer__company, .row-gallery .grid_ramp .page-footer__address, .row-gallery .grid_ramp .page-footer__soc-icons {
    width: 50%; }
    .row-gallery .grid_ramp .grid__item .raid__img, .row-gallery .grid_ramp .l-download__one .raid__img, .row-gallery .grid_ramp .l-download__two .raid__img, .row-gallery .grid_ramp .page-footer__menu .raid__img, .row-gallery .grid_ramp .page-footer__contacts .raid__img, .row-gallery .grid_ramp .page-footer__copyright .raid__img, .row-gallery .grid_ramp .page-footer__company .raid__img, .row-gallery .grid_ramp .page-footer__address .raid__img, .row-gallery .grid_ramp .page-footer__soc-icons .raid__img,
    .row-gallery .grid_ramp .grid__item .rids,
    .row-gallery .grid_ramp .l-download__one .rids,
    .row-gallery .grid_ramp .l-download__two .rids,
    .row-gallery .grid_ramp .page-footer__menu .rids,
    .row-gallery .grid_ramp .page-footer__contacts .rids,
    .row-gallery .grid_ramp .page-footer__copyright .rids,
    .row-gallery .grid_ramp .page-footer__company .rids,
    .row-gallery .grid_ramp .page-footer__address .rids,
    .row-gallery .grid_ramp .page-footer__soc-icons .rids {
      padding-left: 10px;
      padding-right: 10px; }
    .row-gallery .grid_ramp .grid__item .rids h1, .row-gallery .grid_ramp .l-download__one .rids h1, .row-gallery .grid_ramp .l-download__two .rids h1, .row-gallery .grid_ramp .page-footer__menu .rids h1, .row-gallery .grid_ramp .page-footer__contacts .rids h1, .row-gallery .grid_ramp .page-footer__copyright .rids h1, .row-gallery .grid_ramp .page-footer__company .rids h1, .row-gallery .grid_ramp .page-footer__address .rids h1, .row-gallery .grid_ramp .page-footer__soc-icons .rids h1 {
      font-size: 20px; } }

.row-gallery .rids {
  padding: 15px 34px 38px; }
  .row-gallery .rids p {
    width: auto;
    font-size: 14px;
    margin-top: 15px; }
    @media (max-width: 768px) {
      .row-gallery .rids p {
        font-size: 12px; } }

.rids {
  padding: 35px 0 38px; }
  .rids p {
    font-size: 14px;
    color: #939393;
    width: 232px;
    margin: 22px auto 0; }

.js-svg_arr {
  height: 23px;
  float: right;
  width: 14px;
  margin-left: 8px; }

.spb {
  line-height: 24px; }
  .spb span {
    text-transform: none;
    font-size: 18px;
    font-weight: 300; }

.bg3 {
  padding: 50px 0 20px; }

.blog-news {
  width: 380px;
  margin-bottom: 30px; }
  .blog-news:hover {
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.27); }

.blog-text {
  border: 1px solid #c4c4c4;
  border-top: 0;
  padding: 1px 0 1px; }
  .blog-text a {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 0;
    display: initial;
    line-height: 20px; }
    .blog-text a:hover {
      color: #0094db; }

.box-text {
  margin: 25px 29px 44px 19px; }
  .box-text .readmore {
    text-decoration: underline;
    text-align: right;
    color: #0094db;
    font-weight: normal;
    font-size: 16px; }
  .box-text p {
    font-size: 15px;
    color: #000;
    text-align: justify; }

.blog-date {
  font-size: 13px !important;
  color: #7b7b7b !important; }

.href {
  text-align: center;
  margin: 12px 0 0;
  white-space: nowrap; }
  .href a {
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 18px;
    color: #6e6e6e; }
    .href a:hover {
      color: #1691cc; }
    .href a svg {
      width: 13px;
      margin: -2px 0 0 5px; }

.blog-sep-page .href {
  padding: 10px 0;
  margin-top: -7px !important; }

@media (max-width: 1030px) {
  .blog-sep-page .href {
    margin-top: 4px !important; } }

.blog-sep-page .section_reasons-to-build {
  padding: 0 0 20px; }

@media (max-width: 800px) {
  .blog-sep-page .section_reasons-to-build {
    padding: 20px 7px; } }

.bg0 {
  background: url(../img/bg.jpg) top center no-repeat;
  padding-bottom: 96px; }

.top-box {
  float: right;
  width: 45%; }

.top-text {
  margin-right: 22px; }
  .top-text p {
    color: #1c2330;
    font-size: 18px;
    text-align: right;
    margin-bottom: 27px; }

.numb-item {
  float: right;
  margin-left: 111px;
  text-align: right; }
  .numb-item h1 {
    font-weight: 400;
    font-size: 36px;
    font-family: Gothic, sans-serif; }
  .numb-item p {
    font-size: 18px;
    margin-top: 7px;
    color: #000; }

.box-number {
  display: table;
  margin-top: 51px; }

.dv {
  display: table;
  float: right;
  margin: 1px 0 0;
  font-size: 22px; }

.logos {
  width: 480px;
  height: 40px;
  background: url(../img/logo.svg) no-repeat top center;
  background-size: contain;
  margin: 85px auto 47px;
  display: table; }

.section_header1 {
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url(../img/bg0.png); }

@media screen and (min-width: 1025px) {
  .mob,
  .tbl {
    display: none !important; } }

@media screen and (max-width: 1200px) {
  .bol {
    display: none !important; } }

@media screen and (max-width: 620px) {
  .pc {
    display: none !important; }
  .page__row_header {
    background: url(../img/mbg.jpg) center 0 no-repeat;
    background-size: cover; }
  .header__title.mob {
    font-family: Gothic,sans-serif;
    font-weight: bold;
    margin: 81px 0 0; }
  .bg0 {
    background: url(../img/mbg1.jpg) bottom center no-repeat;
    padding-bottom: 0;
    background-size: contain; }
  .top-box {
    float: none;
    width: 100%; }
  .logos {
    width: 95%;
    margin: 32px 0 0; }
  .top-text p {
    text-align: left; }
  .numb-item {
    float: none;
    text-align: center;
    margin: 0 auto; }
  .box-number {
    margin: 22px auto;
    text-align: center; }
  .numb-item p {
    text-align: center; }
  .numb-item h1 {
    font-weight: bolder; }
  .raid img {
    width: 220px; }
  .rids h1 {
    font-size: 17px;
    margin-bottom: -8px; }
  .rids {
    padding-top: 14px; }
  .blog-news {
    width: 100%;
    max-width: 380px;
    margin: 0 auto 20px; }
  .box-text {
    margin: 25px 8px 31px;
    background: #fff; }
    .box-text a {
      font-size: 19px; }
  .bg3 {
    background: #f5f5f5; }
  .blog-text {
    background: #fff; }
  .slick-next {
    right: -8px !important; }
  .slick-prev {
    left: -8px !important; }
  .slick-arrow {
    top: 55% !important; }
  .slick-arrow {
    color: #fff !important;
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.3) !important;
    width: 47px !important;
    height: 47px !important;
    border-radius: 61px;
    font-size: 20px;
    padding: 0 !important; }
  .slick-arrow:after {
    font-size: 20px !important; }
  .href {
    margin: -8px 0 0; }
  .tbl {
    display: none !important; } }

@media (min-width: 1025px) and (max-width: 1200px) {
  .blog-news {
    width: 95%;
    margin: 0 auto 30px; } }

@media (min-width: 100px) and (max-width: 1025px) {
  h1.header__title.tbl {
    margin-top: -108px; } }

@media (min-width: 621px) and (max-width: 1025px) {
  .slick-list.draggable {
    max-width: 780px;
    margin: 0 auto; }
  .tb {
    width: 33% !important; }
  .notbs,
  .pc {
    display: none !important; }
  .tbs {
    display: block !important; }
  .blog-news {
    width: 95%;
    margin: 0 auto 20px;
    max-width: 380px; }
  .raid img {
    width: 90%; }
  .rids h1 {
    font-size: 21px; }
  i.logos {
    width: 90%;
    margin: 54px 0 24px; }
  .top-text p {
    text-align: left;
    font-size: 16px; }
  h1.header__title.tbl {
    font-weight: bold; }
  .numb-item h1 {
    font-weight: bold;
    margin-left: 0 !important; }
  .numb-item {
    margin-left: 28px;
    height: 108px; }
    .numb-item p {
      text-align: right; }
  .top-box {
    width: 47%; }
  .bg0 {
    padding-bottom: 0; }
  .page__row.page__row_header {
    background: url(../img/tbg.jpg) center no-repeat;
    background-size: cover; }
  .slick-next {
    right: -8px !important; }
  .slick-prev {
    left: -8px !important; }
  .slick-arrow {
    top: 55% !important; }
  .slick-arrow {
    color: #fff !important;
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.2) !important;
    width: 47px !important;
    height: 47px !important;
    border-radius: 61px;
    font-size: 20px;
    padding: 0 !important; }
  .slick-arrow:after {
    font-size: 20px !important; }
  .bg3 {
    padding-left: 3px; }
  .href {
    margin-top: 48px !important; } }

.card {
  display: inline-block; }

.card_blog {
  height: 460px;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  border-top: none;
  transition: box-shadow 0.3s; }
  .card_blog:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2); }
  .card_blog .card__thumb {
    width: 100%; }
  .card_blog .card__content-wrap {
    padding: 20px; }
  .card_blog .card__title {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 7px; }
    .card_blog .card__title:hover {
      text-decoration: none; }
  .card_blog .card__date {
    font-size: 13px;
    color: #7b7b7b;
    margin-bottom: 7px; }
  .card_blog .card__text {
    font-size: 15px; }

.header__title .sub {
  font-size: 0.7em;
  display: block;
  text-transform: none; }

.myvideo {
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border: 1px solid #ccc; }
  .myvideo iframe,
  .myvideo object,
  .myvideo embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.page__row_post .section {
  padding-top: 0; }

.page__row_post .section__title,
.page__row_post .section__content {
  max-width: 750px;
  background: #fff; }

.page__row_post .section__title {
  margin: 0 auto;
  padding: 20px 10px; }

.page__row_post p {
  margin: 20px;
  color: #1c2330;
  font-size: 18px; }

.post_additional {
  border: 2px solid #a4d434;
  padding: 20px 25px;
  margin: 20px 20px 70px;
  color: #505050;
  font-size: 16px; }
  @media screen and (max-width: 660px) {
    .post_additional {
      padding: 7px 10px;
      margin: 20px 10px; } }
  .post_additional a {
    color: #505050;
    text-decoration: underline;
    margin-left: 30px; }
    @media screen and (max-width: 660px) {
      .post_additional a {
        margin: 0; } }
    .post_additional a:hover {
      color: #0094db; }
    @media screen and (max-width: 660px) {
      .post_additional a {
        color: #0094db;
        text-decoration: underline; } }

.page__row_blog-header .btn_border.btn_white {
  background: rgba(0, 0, 0, 0.48); }
  .page__row_blog-header .btn_border.btn_white:hover {
    background: #0094db;
    border-color: #0094db;
    color: #fff; }

.pluso {
  margin: 0 20px; }
  @media screen and (max-width: 660px) {
    .pluso {
      margin: 0 10px; } }

.fk-item__title {
  margin-bottom: 0px; }

.fk-item_stage:hover path {
  fill: #fff; }

.fk-item_stage path {
  fill: #888888; }

.js-svg_fk_item_1 svg {
  width: 30px;
  height: 30px;
  margin-top: -12px; }

.js-svg_fk_item_2 svg {
  width: 37px;
  height: 23px;
  margin-top: -10px;
  margin-left: 5px; }

.js-svg_fk_item_3 svg {
  width: 35px;
  height: 35px;
  margin-top: -11px; }
  .js-svg_fk_item_3 svg path {
    fill: #888888; }

.js-svg_fk_item_4 svg {
  width: 32px;
  height: 25px;
  margin-top: -12px; }

.js-svg_fk_item_5 svg {
  width: 32px;
  height: 24px;
  margin-top: -14px;
  margin-left: 3px; }

.js-svg_fk_item_6 svg {
  width: 41px;
  height: 34px;
  margin-top: -14px;
  margin-left: 4px; }

.fk-item__title {
  /*line-height: 20px;*/
  text-transform: uppercase; }

.fk-item__desc {
  margin-top: 10px; }

.grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
  right: 43px; }

.grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
  margin-top: 84px; }

.grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
  left: 44px; }

.grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
  margin-top: 84px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
    top: 0; }

.grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
  margin-top: 84px;
  right: 43px; }

.grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
  margin-top: 84px;
  left: 44px; }

@media screen and (max-width: 1200px) {
  .fk-item__storage-map img {
    max-width: 50%; }
  .fk-item_stage {
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__number {
    top: 0; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
    font-size: 14px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
    top: 0 !important;
    right: 35px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
    margin-top: 49px;
    right: 102px !important;
    top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
    margin-top: 49px;
    right: 35px;
    top: 0; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
    top: 0 !important;
    left: 40px !important;
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
    margin-top: 49px;
    left: 90px !important;
    top: 0; }
    .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
      left: -72px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
    margin-top: 49px !important;
    left: 40px !important;
    top: 0 !important; } }

@media screen and (max-width: 1180px) {
  .fk-item__storage-map img {
    max-width: 50%; }
  .fk-item_stage {
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__number {
    top: 0; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
    font-size: 14px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
    top: 0 !important;
    right: 35px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
    margin-top: 49px !important;
    right: 102px !important;
    top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
    margin-top: 49px !important;
    right: 35px !important;
    top: 0; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
    top: 0 !important;
    left: 40px !important;
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
    margin-top: 49px !important;
    left: 90px !important;
    top: 0; }
    .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
      left: -72px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
    margin-top: 49px !important;
    left: 40px !important;
    top: 0 !important; } }

@media screen and (max-width: 1110px) {
  .fk-item__storage-map img {
    max-width: 50%; }
  .fk-item_stage {
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
    font-size: 14px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
    top: 0 !important;
    right: 32px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
    margin-top: 29px !important;
    right: 86px !important;
    top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
    margin-top: 29px !important;
    right: 32px !important;
    top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
    top: 0 !important;
    left: 40px !important;
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
    margin-top: 29px !important;
    left: 90px !important;
    top: 0 !important; }
    .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
      left: -72px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
    margin-top: 29px !important;
    left: 40px !important;
    top: 0 !important; } }

@media screen and (max-width: 1024px) {
  .fk-item__storage-map img {
    max-width: 50%; }
  .fk-item_stage {
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
    font-size: 14px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
    top: -10px !important;
    right: 47px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
    margin-top: 39px !important;
    right: 82px !important;
    top: -10px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
    margin-top: 39px !important;
    right: 47px !important;
    top: -10px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
    top: -10px !important;
    left: 40px !important;
    max-width: 205px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
    margin-top: 39px !important;
    left: 79px !important;
    top: -10px !important; }
    .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
      left: -72px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
    margin-top: 39px !important;
    left: 40px !important;
    top: -10px !important; } }

@media screen and (max-width: 991px) {
  .section_stages {
    padding: 20px 5px 50px; }
  .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
    font-size: 12px; }
  .fk-item_stage .fk-item__desc {
    font-size: 13px; } }

@media screen and (max-width: 870px) {
  .fk-item__storage-map img {
    max-width: 48%; }
  .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
    top: -10px !important;
    right: 39px !important;
    margin-top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
    margin-top: 19px !important;
    right: 63px !important;
    top: -10px !important; }
    .grid_stages .grid__item .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage .fk-item__desc {
      padding-left: 15px; }
  .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
    margin-top: 9px !important;
    right: 39px !important;
    top: -10px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
    top: -10px !important;
    left: 40px !important;
    max-width: 205px;
    margin-top: 0 !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
    margin-top: 19px !important;
    left: 65px !important;
    top: -10px !important;
    padding-right: 25px; }
    .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
      left: -72px !important; }
  .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
    margin-top: 9px !important;
    left: 40px !important;
    top: -10px !important; } }

@media screen and (max-width: 805px) {
  .fk-item__storage-map img {
    max-width: 48%; } }

@media screen and (max-width: 768px) {
  .fk-item__title {
    line-height: 20px;
    text-transform: none; } }

@media screen and (max-width: 767px) {
  .fk-item__storage-map {
    display: none; }
  .grid_stages {
    padding-top: 0; }
    .grid_stages .grid__item, .grid_stages .l-download__one, .grid_stages .l-download__two, .grid_stages .page-footer__menu, .grid_stages .page-footer__contacts, .grid_stages .page-footer__copyright, .grid_stages .page-footer__company, .grid_stages .page-footer__address, .grid_stages .page-footer__soc-icons {
      width: auto !important;
      padding: 0;
      margin-left: 40px;
      margin: 0 auto;
      max-width: 240px;
      vertical-align: top;
      text-align: left;
      font-size: 20px;
      line-height: normal;
      box-sizing: border-box;
      float: none;
      position: relative; }
      .grid_stages .grid__item:before, .grid_stages .l-download__one:before, .grid_stages .l-download__two:before, .grid_stages .page-footer__menu:before, .grid_stages .page-footer__contacts:before, .grid_stages .page-footer__copyright:before, .grid_stages .page-footer__company:before, .grid_stages .page-footer__address:before, .grid_stages .page-footer__soc-icons:before {
        border-left: 3px solid #0393d8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3px;
        content: ''; }
      .grid_stages .grid__item .fk-item, .grid_stages .l-download__one .fk-item, .grid_stages .l-download__two .fk-item, .grid_stages .page-footer__menu .fk-item, .grid_stages .page-footer__contacts .fk-item, .grid_stages .page-footer__copyright .fk-item, .grid_stages .page-footer__company .fk-item, .grid_stages .page-footer__address .fk-item, .grid_stages .page-footer__soc-icons .fk-item {
        float: none;
        display: block; }
        .grid_stages .grid__item .fk-item.fk-item_stage, .grid_stages .l-download__one .fk-item.fk-item_stage, .grid_stages .l-download__two .fk-item.fk-item_stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage, .grid_stages .page-footer__company .fk-item.fk-item_stage, .grid_stages .page-footer__address .fk-item.fk-item_stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage {
          max-width: 300px;
          display: block;
          margin-left: 34px;
          margin-bottom: 40px; }
          .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__desc, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__desc, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__desc, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__desc {
            font-size: 14px; }
          .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__title a, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__title a, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__title a {
            font-size: 16px; }
          .grid_stages .grid__item .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage .fk-item__number {
            left: -61px;
            width: 50px;
            height: 50px; }
          .grid_stages .grid__item .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.first-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.first-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.first-stage {
            top: auto !important;
            right: auto !important;
            margin-top: 0 !important; }
          .grid_stages .grid__item .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage {
            top: auto !important;
            right: auto !important;
            margin-top: 0 !important; }
            .grid_stages .grid__item .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .l-download__one .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .l-download__two .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__menu .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__company .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__address .fk-item.fk-item_stage.second-stage .fk-item__desc, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.second-stage .fk-item__desc {
              padding: 0; }
          .grid_stages .grid__item .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.third-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.third-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.third-stage {
            top: auto !important;
            right: auto !important;
            margin-top: 0 !important; }
          .grid_stages .grid__item .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fourth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fourth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fourth-stage {
            top: auto !important;
            left: auto !important;
            margin-top: 0 !important; }
          .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage {
            top: auto !important;
            left: auto !important;
            margin-top: 0 !important;
            padding: 0; }
            .grid_stages .grid__item .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__one .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .l-download__two .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__menu .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__company .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__address .fk-item.fk-item_stage.fifth-stage .fk-item__number, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.fifth-stage .fk-item__number {
              left: -61px !important; }
          .grid_stages .grid__item .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__one .fk-item.fk-item_stage.sixth-stage, .grid_stages .l-download__two .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__menu .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__contacts .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__copyright .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__company .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__address .fk-item.fk-item_stage.sixth-stage, .grid_stages .page-footer__soc-icons .fk-item.fk-item_stage.sixth-stage {
            top: auto !important;
            left: auto !important;
            margin-top: 0 !important; }
      .grid_stages .grid__item.last, .grid_stages .last.l-download__one, .grid_stages .last.l-download__two, .grid_stages .last.page-footer__menu, .grid_stages .last.page-footer__contacts, .grid_stages .last.page-footer__copyright, .grid_stages .last.page-footer__company, .grid_stages .last.page-footer__address, .grid_stages .last.page-footer__soc-icons {
        float: none;
        display: block;
        vertical-align: top;
        text-align: left;
        font-size: 20px;
        line-height: normal;
        box-sizing: border-box; }
        .grid_stages .grid__item.last .fk-item.sixth-stage, .grid_stages .last.l-download__one .fk-item.sixth-stage, .grid_stages .last.l-download__two .fk-item.sixth-stage, .grid_stages .last.page-footer__menu .fk-item.sixth-stage, .grid_stages .last.page-footer__contacts .fk-item.sixth-stage, .grid_stages .last.page-footer__copyright .fk-item.sixth-stage, .grid_stages .last.page-footer__company .fk-item.sixth-stage, .grid_stages .last.page-footer__address .fk-item.sixth-stage, .grid_stages .last.page-footer__soc-icons .fk-item.sixth-stage {
          position: relative; }
          .grid_stages .grid__item.last .fk-item.sixth-stage:before, .grid_stages .last.l-download__one .fk-item.sixth-stage:before, .grid_stages .last.l-download__two .fk-item.sixth-stage:before, .grid_stages .last.page-footer__menu .fk-item.sixth-stage:before, .grid_stages .last.page-footer__contacts .fk-item.sixth-stage:before, .grid_stages .last.page-footer__copyright .fk-item.sixth-stage:before, .grid_stages .last.page-footer__company .fk-item.sixth-stage:before, .grid_stages .last.page-footer__address .fk-item.sixth-stage:before, .grid_stages .last.page-footer__soc-icons .fk-item.sixth-stage:before {
            content: "";
            border: 3px solid #fff;
            top: 0;
            bottom: 0;
            left: -40px;
            position: absolute; }
        .grid_stages .grid__item.last:before, .grid_stages .last.l-download__one:before, .grid_stages .last.l-download__two:before, .grid_stages .last.page-footer__menu:before, .grid_stages .last.page-footer__contacts:before, .grid_stages .last.page-footer__copyright:before, .grid_stages .last.page-footer__company:before, .grid_stages .last.page-footer__address:before, .grid_stages .last.page-footer__soc-icons:before {
          bottom: 85px; }
        .grid_stages .grid__item.last .fk-item__number, .grid_stages .last.l-download__one .fk-item__number, .grid_stages .last.l-download__two .fk-item__number, .grid_stages .last.page-footer__menu .fk-item__number, .grid_stages .last.page-footer__contacts .fk-item__number, .grid_stages .last.page-footer__copyright .fk-item__number, .grid_stages .last.page-footer__company .fk-item__number, .grid_stages .last.page-footer__address .fk-item__number, .grid_stages .last.page-footer__soc-icons .fk-item__number {
          left: -61px !important;
          width: 50px;
          height: 50px; }
  .js-svg_fk_item_1 svg {
    width: 25px;
    height: 25px;
    margin-top: -28px; }
  .js-svg_fk_item_2 svg {
    width: 29px;
    height: 23px;
    margin-top: -24px;
    margin-left: 5px; }
  .js-svg_fk_item_3 svg {
    width: 25px;
    height: 35px;
    margin-top: -27px; }
  .js-svg_fk_item_4 svg {
    width: 26px;
    height: 25px;
    margin-top: -27px; }
  .js-svg_fk_item_5 svg {
    width: 25px;
    height: 24px;
    margin-top: -28px;
    margin-left: 3px; }
  .js-svg_fk_item_6 svg {
    width: 32px;
    height: 34px;
    margin-top: -28px;
    margin-left: 4px; } }

@media screen and (max-width: 690px) {
  .js-svg_fk_item_1 svg {
    width: 25px;
    height: 25px;
    margin-top: -10px; }
  .js-svg_fk_item_2 svg {
    width: 29px;
    height: 23px;
    margin-top: -8px;
    margin-left: 5px; }
  .js-svg_fk_item_3 svg {
    width: 25px;
    height: 35px;
    margin-top: -9px; }
  .js-svg_fk_item_4 svg {
    width: 26px;
    height: 25px;
    margin-top: -10px; }
  .js-svg_fk_item_5 svg {
    width: 25px;
    height: 24px;
    margin-top: -12px;
    margin-left: 3px; }
  .js-svg_fk_item_6 svg {
    width: 32px;
    height: 34px;
    margin-top: -12px;
    margin-left: 4px; } }

.reasons_wrapper {
  margin-bottom: 70px; }
  .reasons_wrapper .reasons_container {
    max-width: 1160px;
    margin: 0 auto;
    position: relative; }
    .reasons_wrapper .reasons_container .reasons_title {
      font: normal 32px GothamMedium,sans-serif;
      color: #0094db;
      text-transform: uppercase;
      /*font-weight: 700;*/
      text-align: center;
      margin-bottom: 75px; }
    .reasons_wrapper .reasons_container .reason_image {
      text-align: center;
      max-width: 582px;
      position: absolute;
      top: 375px;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 221px; }
      .reasons_wrapper .reasons_container .reason_image img {
        max-width: 100%;
        height: auto; }
    .reasons_wrapper .reasons_container .reasons_inner {
      font-size: 0;
      position: relative; }
      .reasons_wrapper .reasons_container .reasons_inner:before {
        content: "";
        position: absolute;
        left: 120px;
        top: 89px;
        bottom: 89px;
        width: 2px;
        background: url(../img/border-reason-vertical.png) 0 0 repeat-y; }
      .reasons_wrapper .reasons_container .reasons_inner:after {
        content: "";
        position: absolute;
        right: 120px;
        top: 89px;
        bottom: 89px;
        width: 2px;
        background: url(../img/border-reason-vertical.png) 0 0 repeat-y; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top {
        text-align: center;
        min-height: 178px;
        position: relative; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item {
          display: inline-block;
          vertical-align: top;
          margin: 0 40px 80px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top:before {
          content: "";
          height: 2px;
          background: url(../img/border-reason.png) 0 0 repeat-x;
          position: absolute;
          left: 125px;
          right: 125px;
          top: 89px; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle:after {
        content: "";
        height: 0;
        display: block;
        clear: both; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item {
        margin-bottom: 105px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item:first-child {
          float: left; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item:last-child {
          float: right; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom {
        text-align: center;
        min-height: 178px;
        position: relative; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item {
          display: inline-block;
          vertical-align: top;
          margin: 0 40px 0; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom:before {
          content: "";
          height: 2px;
          background: url(../img/border-reason.png) 0 0 repeat-x;
          position: absolute;
          left: 125px;
          right: 125px;
          top: 89px; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item {
        width: 250px;
        height: 178px;
        position: relative;
        background: #fff;
        border: 2px solid #d9d9d9;
        box-sizing: border-box;
        text-align: center;
        z-index: 2; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item .title {
          font: normal 16px/18px GothamBold;
          padding-top: 31px;
          padding-bottom: 16px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item .body {
          font: normal 16px/20px Calibri;
          color: #1c2330; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item .reason_svg {
          left: 50%;
          margin-left: -30px;
          top: -30px;
          width: 60px;
          height: 60px;
          position: absolute;
          border-radius: 50%;
          background: #fff; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item .js-svg_galka {
          width: 29px;
          height: 21px;
          color: #a4d435;
          margin-top: 17px; }

.reasons_wrapper4 {
  margin-bottom: 70px; }
  .reasons_wrapper4 .reasons_container4 {
    max-width: 1160px;
    margin: 0 auto;
    position: relative; }
    .reasons_wrapper4 .reasons_container4 .reasons_title4 {
      font: normal 32px GothamMedium,sans-serif;
      color: #0094db;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 75px; }
    .reasons_wrapper4 .reasons_container4 .reason_image4 {
      text-align: center;
      max-width: 582px;
      position: absolute;
      top: 406px;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 221px; }
      .reasons_wrapper4 .reasons_container4 .reason_image4 img {
        max-width: 100%;
        height: auto; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 {
      font-size: 0;
      position: relative; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4:before {
        content: "";
        position: absolute;
        left: 120px;
        top: 89px;
        bottom: 89px;
        width: 2px;
        background: url(../img/border-reason-vertical.png) 0 0 repeat-y; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4:after {
        content: "";
        position: absolute;
        right: 120px;
        top: 89px;
        bottom: 89px;
        width: 2px;
        background: url(../img/border-reason-vertical.png) 0 0 repeat-y; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 {
        text-align: center;
        min-height: 178px;
        position: relative; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4 {
          display: inline-block;
          vertical-align: top;
          margin: 0 40px 60px;
          box-sizing: border-box;
          padding-bottom: 20px;
          min-height: 168px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4:before {
          content: "";
          height: 2px;
          background: url(../img/border-reason.png) 0 0 repeat-x;
          position: absolute;
          left: 125px;
          right: 125px;
          top: 89px; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4:after {
        content: "";
        height: 0;
        display: block;
        clear: both; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4 {
        margin-bottom: 28px;
        width: 248px;
        min-height: 250px;
        box-sizing: border-box;
        padding-bottom: 20px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4:first-child {
          float: left; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4:last-child {
          float: right; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 {
        text-align: center;
        min-height: 178px;
        position: relative; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4 {
          display: inline-block;
          vertical-align: top;
          margin: 0 40px 0;
          padding-bottom: 20px;
          min-height: 157px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4:before {
          content: "";
          height: 2px;
          background: url(../img/border-reason.png) 0 0 repeat-x;
          position: absolute;
          left: 125px;
          right: 125px;
          top: 89px; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 {
        width: 540px;
        min-height: 178px;
        position: relative;
        background: #fff;
        border: 2px solid #d9d9d9;
        box-sizing: border-box;
        text-align: center;
        z-index: 2; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 .title4 {
          font: normal 16px/18px GothamBold;
          padding-top: 31px;
          padding-bottom: 16px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 .body4 {
          font: normal 16px/20px Calibri;
          color: #1c2330; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 .reason_svg4 {
          left: 50%;
          margin-left: -30px;
          top: -30px;
          width: 60px;
          height: 60px;
          position: absolute;
          border-radius: 50%;
          background: #fff; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 .js-svg_galka {
          width: 29px;
          height: 21px;
          color: #a4d435;
          margin-top: 17px; }

.portfolio_slider {
  position: relative; }
  .portfolio_slider .portfolio_slider_title {
    text-align: center;
    font: normal 32px GothamMedium,sans-serif;
    color: #0094db;
    text-transform: uppercase; }
  .portfolio_slider .port_slider {
    position: relative; }
    .portfolio_slider .port_slider.port_slider_tablet {
      display: none;
      padding-bottom: 20px; }
      @media (min-width: 768px) and (max-width: 1001px) {
        .portfolio_slider .port_slider.port_slider_tablet {
          display: block; } }
  .portfolio_slider .owl-dots {
    outline: 0;
    text-align: center;
    font-size: 0;
    position: absolute;
    margin-top: 20px;
    margin-bottom: 20px;
    left: 0;
    right: 0;
    z-index: 222;
    height: 40px;
    -webkit-tap-highlight-color: transparent; }
    .portfolio_slider .owl-dots:hover, .portfolio_slider .owl-dots:focus {
      outline: 0;
      background: transparent; }
    .portfolio_slider .owl-dots .owl-dot {
      display: inline-block;
      vertical-align: top;
      margin: 0 6px;
      cursor: pointer;
      z-index: 3444;
      position: relative;
      overflow: hidden; }
      @media (max-width: 767px) {
        .portfolio_slider .owl-dots .owl-dot {
          /*	 padding: 5px;*/ } }
      .portfolio_slider .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #c5c5c5; }
      .portfolio_slider .owl-dots .owl-dot.active span {
        border-color: #0094db; }
  .portfolio_slider .owl-nav {
    font-size: 0; }
    .portfolio_slider .owl-nav div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      cursor: pointer; }
      .portfolio_slider .owl-nav div:hover {
        background: rgba(255, 255, 255, 0.7); }
        .portfolio_slider .owl-nav div:hover span svg {
          color: #0094db; }
      .portfolio_slider .owl-nav div:first-child span {
        right: 19px;
        transform: rotate(180deg); }
      .portfolio_slider .owl-nav div span {
        position: absolute;
        top: 14px;
        right: 16px;
        width: 14px;
        height: 23px; }
        .portfolio_slider .owl-nav div span svg {
          color: #fff; }
  .portfolio_slider .owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 5px; }
  .portfolio_slider .owl-next {
    position: absolute;
    top: 50%;
    margin-top: -40px;
    right: 5px; }

.portfolio_slider {
  max-width: 1920px;
  margin: 0 auto; }
  .portfolio_slider .portfolio_slider_title {
    margin-bottom: 30px; }
  .portfolio_slider .owl-item {
    display: inline-block !important;
    vertical-align: top; }

.slider_athlets ul.slick-dots,
.slider_one ul.slick-dots,
.slider_athlets_fulltime ul.slick-dots {
  text-align: center;
  font-size: 0;
  position: absolute;
  left: 0;
  bottom: 47px;
  right: 0; }
  .slider_athlets ul.slick-dots li,
  .slider_one ul.slick-dots li,
  .slider_athlets_fulltime ul.slick-dots li {
    display: inline-block;
    vertical-align: top;
    margin: 0 3px;
    border-radius: 50%;
    -webkit-box-shadow: 3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
    -moz-box-shadow: 3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
    box-shadow: 3px 3px 7px 0px rgba(50, 50, 50, 0.35) inset;
    background: #f4f4f4;
    overflow: hidden;
    position: relative;
    cursor: pointer; }
    .slider_athlets ul.slick-dots li:before,
    .slider_one ul.slick-dots li:before,
    .slider_athlets_fulltime ul.slick-dots li:before {
      border: 1px solid #cdcdcd;
      content: "";
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1; }
    .slider_athlets ul.slick-dots li:first-child button,
    .slider_one ul.slick-dots li:first-child button,
    .slider_athlets_fulltime ul.slick-dots li:first-child button {
      /*background-position: -8px 5px !important;
					background-size: 100% 100% !important;*/ }
    .slider_athlets ul.slick-dots li button,
    .slider_one ul.slick-dots li button,
    .slider_athlets_fulltime ul.slick-dots li button {
      width: 56px;
      height: 56px;
      background: none;
      box-sizing: border-box;
      outline: none;
      background-size: cover !important;
      overflow: hidden; }
      .slider_athlets ul.slick-dots li button:hover,
      .slider_one ul.slick-dots li button:hover,
      .slider_athlets_fulltime ul.slick-dots li button:hover {
        outline: none; }
      .slider_athlets ul.slick-dots li button img,
      .slider_one ul.slick-dots li button img,
      .slider_athlets_fulltime ul.slick-dots li button img {
        max-width: 100%;
        height: auto; }
  .slider_athlets ul.slick-dots li.slick-active:before,
  .slider_one ul.slick-dots li.slick-active:before,
  .slider_athlets_fulltime ul.slick-dots li.slick-active:before {
    border: 2px solid #0393d9;
    content: "";
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.slider_one ul.slick-dots {
  bottom: 285px; }
  .slider_one ul.slick-dots li > button {
    background-size: cover !important; }

.slider_athlets ul.slick-dots {
  bottom: -27px; }
  @media (min-width: 768px) {
    .slider_athlets ul.slick-dots {
      bottom: 7px; } }

.header__menu, .header__logo {
  position: relative;
  z-index: 2; }

.header_biz .header__second {
  padding-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0; }
  .header_biz .header__second:after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle; }
  .header_biz .header__second .header__second_inner {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100%; }
  .header_biz .header__second .header__title {
    padding: 0;
    top: auto;
    text-shadow: none;
    font-size: 48px;
    line-height: 50px;
    font-family: Gotham;
    margin-bottom: 0;
    position: static;
    left: auto; }
  .header_biz .header__second .header__second_links {
    padding-top: 50px;
    font-size: 0; }
    .header_biz .header__second .header__second_links a.header_second__link {
      width: 100%;
      max-width: 388px;
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      font: 700 22px/70px Calibri, sans-serif;
      color: #fff;
      height: 74px;
      border: 2px solid #fff;
      text-align: center;
      margin: 0 33px;
      background: rgba(0, 0, 0, 0.46); }
      .header_biz .header__second .header__second_links a.header_second__link:hover {
        border-color: #8dd50e; }
      .header_biz .header__second .header__second_links a.header_second__link:first-child {
        max-width: 405px; }

.header__third {
  overflow: visible; }
  .header__third .header__third_links {
    width: 230px;
    left: 50%;
    margin-left: -115px;
    text-align: center;
    bottom: 25px;
    position: absolute;
    font: normal 12px/16px 'Calibri';
    color: #fff;
    transition: all 0.3s ease; }
    .header__third .header__third_links a {
      color: #fff;
      transition: all 0.3s ease; }
      .header__third .header__third_links a:hover {
        color: #8dd50e; }

.header__second_inner .header__third_links {
  text-align: center;
  font: normal 12px/16px 'Calibri';
  color: #fff;
  transition: all 0.3s ease;
  padding-top: 70px;
  display: none; }
  .header__second_inner .header__third_links a {
    color: #fff;
    transition: all 0.3s ease; }
    .header__second_inner .header__third_links a:hover {
      color: #8dd50e; }

@media screen and (max-width: 1160px) {
  .reasons_wrapper .reasons_container .reason_image {
    max-width: 450px; }
  .reasons_wrapper4 .reasons_container4 .reason_image4 {
    max-width: 450px; } }

@media screen and (max-width: 1040px) {
  .portfolio_slider .portfolio_slider_title {
    font-size: 30px;
    line-height: 34px; }
  .portfolio_slider .item_sl img {
    width: 100%; }
  .reasons_wrapper .reasons_container .reasons_title {
    font-size: 30px;
    line-height: 34px; }
  .reasons_wrapper4 .reasons_container4 .reasons_title4 {
    font-size: 30px;
    line-height: 34px; } }

@media screen and (max-width: 991px) {
  .reasons_wrapper .reasons_container .reason_image {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    max-width: 100%;
    margin-bottom: 93px;
    box-sizing: border-box;
    padding: 0 60px;
    height: auto; }
  .reasons_wrapper .reasons_container .reasons_inner {
    padding: 0 60px; }
    .reasons_wrapper .reasons_container .reasons_inner:before {
      left: 185px;
      top: 0;
      bottom: 0; }
    .reasons_wrapper .reasons_container .reasons_inner:after {
      right: 185px;
      top: 0;
      bottom: 0; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason:after {
      content: "";
      height: 0;
      display: block;
      clear: both; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top {
      padding-top: 58px; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top:before {
        top: 0; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item {
        margin: 0 0 79px 0; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item:first-child {
          float: left; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item:last-child {
          float: right; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom {
      padding-bottom: 58px; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom:before {
        top: auto;
        bottom: 0; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item {
        margin: 0 0 0 0; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item:first-child {
          float: left; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item:last-child {
          float: right; }
  .reasons_wrapper4 .reasons_container4 .reason_image4 {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    max-width: 100%;
    margin-bottom: 93px;
    box-sizing: border-box;
    padding: 0 60px;
    height: auto; }
  .reasons_wrapper4 .reasons_container4 .reasons_inner4 {
    padding: 0 60px; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4:before {
      left: 185px;
      top: 0;
      bottom: 0; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4:after {
      right: 185px;
      top: 0;
      bottom: 0; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4:after {
      content: "";
      height: 0;
      display: block;
      clear: both; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 {
      padding-top: 58px; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4:before {
        top: 0; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4 {
        margin: 0 0 79px 0;
        width: 100%; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4:first-child {
          float: none; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4:last-child {
          float: none; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4 {
      margin-bottom: 78px; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 {
      padding-bottom: 58px; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4:before {
        top: auto;
        bottom: 0; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4 {
        margin: 0 0 0 0;
        width: 100%; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4:first-child {
          float: none; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4:last-child {
          float: none; } }

@media screen and (max-width: 940px) {
  .header_biz .header__second .header__second_links a.header_second__link {
    width: 100%;
    max-width: 360px;
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    font: 700 20px/70px Calibri, sans-serif;
    color: #fff;
    height: 74px;
    border: 2px solid #fff;
    text-align: center;
    margin: 0 auto 25px;
    background: rgba(0, 0, 0, 0.46); }
    .header_biz .header__second .header__second_links a.header_second__link:hover, .header_biz .header__second .header__second_links a.header_second__link:active {
      border-color: #8dd50e; }
    .header_biz .header__second .header__second_links a.header_second__link:first-child {
      max-width: 360px; } }

@media screen and (max-width: 860px) {
  .header_biz .header__second {
    padding: 0 0; }
    .header_biz .header__second .header__second_inner .header__title {
      font-size: 40px;
      line-height: 42px; }
    .header_biz .header__second .header__second_links {
      padding-top: 70px; } }

@media screen and (max-width: 768px) {
  .slider_athlets_fulltime .athlet-review__text {
    padding-bottom: 123px !important; }
  .portfolio_slider .port_slider .owl-stage-outer .owl-item .item_sl {
    height: 490px;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .portfolio_slider .port_slider .owl-stage-outer .owl-item .item_sl img {
      max-width: none;
      height: 790px;
      width: 790px;
      position: absolute;
      left: 0;
      /* margin-left: -960px; */
      top: 50%;
      margin-top: -380px; } }

@media screen and (max-width: 645px) {
  .reasons_wrapper .reasons_container .reasons_title {
    padding: 0 15px;
    margin-bottom: 40px; }
  .reasons_wrapper .reasons_container .reason_image {
    padding: 0 15px;
    margin-bottom: 50px; }
  .reasons_wrapper .reasons_container .reasons_inner {
    padding: 0 35px; }
    .reasons_wrapper .reasons_container .reasons_inner:before {
      left: 50%;
      margin-left: -1px; }
    .reasons_wrapper .reasons_container .reasons_inner:after {
      display: none; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top {
      padding-top: 0; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top:before {
        display: none; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item {
        display: block;
        margin: 0 auto 64px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item:first-child {
          float: none; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_top .reason_item:last-child {
          float: none; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle {
      padding-top: 0; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle:before {
        display: none; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item {
        display: block;
        margin: 0 auto 64px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item:first-child {
          float: none; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_middle .reason_item:last-child {
          float: none; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom {
      padding-top: 0;
      padding-bottom: 0; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom:before {
        display: none; }
      .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item {
        display: block;
        margin: 0 auto 64px; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item:first-child {
          float: none; }
        .reasons_wrapper .reasons_container .reasons_inner .row_reason.row_reason_bottom .reason_item:last-child {
          float: none;
          margin-bottom: 0; }
    .reasons_wrapper .reasons_container .reasons_inner .row_reason .reason_item {
      display: block; }
  .reasons_wrapper4 .reasons_container4 .reasons_title4 {
    padding: 0 15px;
    margin-bottom: 40px; }
  .reasons_wrapper4 .reasons_container4 .reason_image4 {
    padding: 0 15px;
    margin-bottom: 50px; }
  .reasons_wrapper4 .reasons_container4 .reasons_inner4 {
    padding: 0 35px; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4:before {
      left: 50%;
      margin-left: -1px; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4:after {
      display: none; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 {
      padding-top: 0; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4:before {
        display: none; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4 {
        display: block;
        margin: 0 auto 64px;
        width: 248px;
        padding: 0 15px 20px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4:first-child {
          float: none; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_top4 .reason_item4:last-child {
          float: none; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 {
      padding-top: 0; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4:before {
        display: none; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4 {
        display: block;
        margin: 0 auto 64px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4:first-child {
          float: none; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_middle4 .reason_item4:last-child {
          float: none; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 {
      padding-top: 0;
      padding-bottom: 0; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4:before {
        display: none; }
      .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4 {
        display: block;
        margin: 0 auto 64px;
        width: 248px;
        padding: 0 15px 20px; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4:first-child {
          float: none; }
        .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4.row_reason_bottom4 .reason_item4:last-child {
          float: none;
          margin-bottom: 0; }
    .reasons_wrapper4 .reasons_container4 .reasons_inner4 .row_reason4 .reason_item4 {
      display: block; } }

@media screen and (max-width: 570px) {
  .portfolio_slider .portfolio_slider_title {
    font-size: 24px;
    line-height: 28px; }
  .reasons_wrapper .reasons_container .reasons_title {
    font-size: 24px;
    line-height: 28px; }
  .reasons_wrapper4 .reasons_container4 .reasons_title4 {
    font-size: 24px;
    line-height: 28px; } }

@media screen and (max-width: 480px) {
  .header__third {
    overflow: visible; }
  .header__third .header__third_links {
    bottom: 120%;
    display: none; }
  .header__second_inner .header__third_links {
    display: block;
    padding-top: 0px; }
  .header_biz .header__second {
    position: static;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto; }
  .header {
    /*height: auto;*/
    padding-bottom: 105px; } }

@media screen and (max-width: 425px) {
  .portfolio_slider .port_slider .owl-stage-outer .owl-item .item_sl {
    height: 400px;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .portfolio_slider .port_slider .owl-stage-outer .owl-item .item_sl img {
      max-width: none;
      height: 790px;
      width: 790px;
      position: absolute;
      left: 50%;
      margin-left: -380px;
      top: 50%;
      margin-top: -380px; } }

@media screen and (max-width: 425px) {
  .header_biz .header__second .header__second_inner {
    display: block;
    margin-top: 130px; }
    .header_biz .header__second .header__second_inner .header__title {
      font-size: 18px; }
  .header_biz .header__second .header__second_links {
    padding-top: 15px; }
    .header_biz .header__second .header__second_links a.header_second__link {
      width: 100%;
      max-width: 245px;
      font-size: 12px;
      line-height: 53px;
      height: 55px;
      margin-bottom: 15px; }
      .header_biz .header__second .header__second_links a.header_second__link:first-child {
        max-width: 245px; } }

.n-about {
  background-color: #f9f9f9;
  padding: 45px 0 0; }
  @media screen and (max-width: 568px) {
    .n-about {
      padding: 32px 0 0; } }
  .n-about .n-content-wrap {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 30px;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .n-about .n-content-wrap {
        padding: 0 20px; } }
    @media screen and (max-width: 568px) {
      .n-about .n-content-wrap {
        padding: 0 8px; } }
    .n-about .n-content-wrap__row {
      font-size: 0;
      line-height: 0;
      margin-left: -35px; }
      @media screen and (max-width: 568px) {
        .n-about .n-content-wrap__row {
          margin-left: 0; } }
    .n-about .n-content-wrap__col {
      display: inline-block;
      vertical-align: top;
      padding-left: 35px; }
      .n-about .n-content-wrap__col--left {
        width: 52%; }
      .n-about .n-content-wrap__col--right {
        width: 48%;
        padding-left: 30px; }
        @media screen and (max-width: 1024px) {
          .n-about .n-content-wrap__col--right {
            padding-left: 46px; } }
      @media screen and (max-width: 568px) {
        .n-about .n-content-wrap__col {
          padding-left: 0; }
          .n-about .n-content-wrap__col--left {
            width: 100%; }
          .n-about .n-content-wrap__col--right {
            width: 100%;
            padding-left: 0; } }
  .n-about .n-content-title {
    font-size: 24px;
    line-height: 28px;
    color: #000;
    font-family: GothamBold;
    /*font-weight: 700;*/
    text-transform: uppercase;
    padding-bottom: 22px;
    margin: 0; }
    @media screen and (max-width: 568px) {
      .n-about .n-content-title {
        text-align: center;
        padding-bottom: 16px; } }
  .n-about .n-features {
    padding-bottom: 24px;
    margin-left: -25px; }
    @media screen and (max-width: 1024px) {
      .n-about .n-features {
        margin-left: -10px;
        padding-bottom: 27px; } }
    @media screen and (max-width: 568px) {
      .n-about .n-features {
        margin-left: -5px; } }
    .n-about .n-features__inner {
      position: relative;
      z-index: 0; }
      .n-about .n-features__inner:before {
        content: '';
        position: absolute;
        top: 0;
        left: 22px;
        width: 3px;
        height: 1000000px;
        background-color: #5392d5;
        z-index: -1; }
      @media screen and (max-width: 568px) {
        .n-about .n-features__inner:before {
          display: none; } }
  .n-about .n-features-item {
    padding-bottom: 38px; }
    @media screen and (max-width: 1024px) {
      .n-about .n-features-item {
        padding-bottom: 18px; } }
    @media screen and (max-width: 568px) {
      .n-about .n-features-item {
        padding-bottom: 0; }
        .n-about .n-features-item:last-child .n-features-item__list-item:last-child:before {
          height: 7px; } }
    .n-about .n-features-item:last-child {
      padding-bottom: 0; }
    .n-about .n-features-item__head {
      padding-bottom: 22px; }
      @media screen and (max-width: 568px) {
        .n-about .n-features-item__head {
          position: relative; }
          .n-about .n-features-item__head:before {
            content: '';
            position: absolute;
            top: 0;
            left: 22px;
            height: 100%;
            width: 3px;
            background-color: #5392d5;
            z-index: -1; } }
      .n-about .n-features-item__head-icon, .n-about .n-features-item__head-title {
        display: inline-block;
        vertical-align: middle; }
      .n-about .n-features-item__head-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #a4d435;
        background-color: #fff;
        color: #888888;
        text-align: center;
        margin-right: 16px; }
        .n-about .n-features-item__head-icon:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%; }
        .n-about .n-features-item__head-icon-skating {
          width: 17px;
          height: 31px;
          display: inline-block;
          vertical-align: middle; }
        .n-about .n-features-item__head-icon-finger {
          width: 24px;
          height: 29px; }
        @media screen and (max-width: 1024px) {
          .n-about .n-features-item__head-icon {
            margin-right: 8px; } }
      .n-about .n-features-item__head-title {
        font-size: 18px;
        line-height: 22px;
        font-family: GothamBold;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        width: calc(100% - 66px); }
        @media screen and (max-width: 1024px) {
          .n-about .n-features-item__head-title {
            width: calc(100% - 58px); } }
        @media screen and (max-width: 568px) {
          .n-about .n-features-item__head-title {
            font-size: 16px;
            line-height: 20px; } }
    .n-about .n-features-item__el {
      position: relative; }
      .n-about .n-features-item__el:before {
        content: '';
        position: absolute;
        top: 4px;
        left: -44px;
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 2px solid #a4d435;
        border-radius: 50%; }
        @media screen and (max-width: 1024px) {
          .n-about .n-features-item__el:before {
            left: -27px; } }
        @media screen and (max-width: 568px) {
          .n-about .n-features-item__el:before {
            left: -27px; } }
      .n-about .n-features-item__el:after {
        content: '';
        position: absolute;
        left: -33px;
        top: 9px;
        width: 22px;
        height: 2px;
        background-color: #a4d435; }
        @media screen and (max-width: 1024px) {
          .n-about .n-features-item__el:after {
            width: 10px;
            left: -16px; } }
        @media screen and (max-width: 568px) {
          .n-about .n-features-item__el:after {
            width: 10px;
            left: -16px; } }
    .n-about .n-features-item__list {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .n-about .n-features-item__list-item {
        padding-bottom: 18px;
        padding-left: 62px;
        position: relative;
        font-size: 16px;
        line-height: 20px;
        font-family: Calibri;
        font-weight: 300; }
        .n-about .n-features-item__list-item:last-child {
          padding-bottom: 0; }
        @media screen and (max-width: 1024px) {
          .n-about .n-features-item__list-item {
            padding-left: 45px; } }
        @media screen and (max-width: 568px) {
          .n-about .n-features-item__list-item {
            font-size: 14px;
            padding-bottom: 14px; }
            .n-about .n-features-item__list-item:last-child {
              padding-bottom: 32px; }
            .n-about .n-features-item__list-item:before {
              content: '';
              position: absolute;
              top: -2px;
              left: 22px;
              width: 3px;
              height: 103%;
              background-color: #5392d5; } }
  .n-about .n-about-info {
    padding-bottom: 26px; }
    .n-about .n-about-info__pic {
      width: 355px;
      max-width: 100%;
      height: 228px;
      margin-bottom: 20px; }
      @media screen and (max-width: 1024px) {
        .n-about .n-about-info__pic {
          width: 289px;
          height: 185px;
          margin-bottom: 10px; } }
      @media screen and (max-width: 568px) {
        .n-about .n-about-info__pic {
          margin: 0 auto 6px; } }
    .n-about .n-about-info__text {
      padding-bottom: 40px;
      font-size: 16px;
      line-height: 19px;
      font-family: Calibri;
      font-weight: 300; }
      @media screen and (max-width: 1024px) {
        .n-about .n-about-info__text {
          padding-bottom: 32px; } }
      @media screen and (max-width: 568px) {
        .n-about .n-about-info__text {
          font-size: 14px;
          line-height: 17px;
          padding-bottom: 19px; } }
    .n-about .n-about-info__subtitle {
      font-weight: 700;
      padding-bottom: 29px;
      font-size: 18px;
      line-height: 21px;
      margin: 0; }
      @media screen and (max-width: 1024px) {
        .n-about .n-about-info__subtitle {
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 21px; } }
    .n-about .n-about-info__text-item {
      padding-bottom: 20px;
      margin: 0; }
      .n-about .n-about-info__text-item:last-child {
        padding-bottom: 0; }
    .n-about .n-about-info__bottom:after {
      content: '';
      clear: both;
      display: table;
      width: 100%; }
    .n-about .n-about-info__link {
      float: right; }
      .n-about .n-about-info__link-text {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        line-height: 16px;
        font-family: Calibri;
        font-weight: 300;
        color: #555555; }
        .n-about .n-about-info__link-text:hover {
          color: #0094db; }
      .n-about .n-about-info__link-icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        width: 11px;
        height: 18px;
        color: #0393d8; }

body {
  font: normal 20px 'Calibri', sans-serif; }

.underline, .link_underline {
  position: relative;
  display: inline-block; }
  .underline:after, .link_underline:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1); }

.underline-none:after, .link_underline:hover:after {
  border-color: transparent; }

.preloader {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  display: none;
  transition: opacity 0.2s;
  z-index: 9999; }
  .preloader.active {
    display: block;
    opacity: 1; }
  .preloader__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    height: 50px;
    width: 50px;
    border-width: 5px;
    border-style: solid;
    border-color: #8dd50e #8dd50e #0094db #0094db;
    border-radius: 100%;
    animation: clockwise .6s linear infinite; }

.from-1180px {
  display: none; }

.soc-project-full {
  border: 1px solid #c4c4c4;
  padding: 15px; }
  .soc-project-full__stage {
    margin-bottom: 70px;
    font-size: 18px;
    text-align: justify; }
  .soc-project-full__stage-name {
    font: bold 24px Gotham, sans-serif;
    text-transform: uppercase; }
  .soc-project-full__content {
    overflow: hidden; }
  .soc-project-full__first {
    width: 50%;
    float: right; }
  .soc-project-full__second {
    width: 50%;
    float: left;
    padding-right: 35px;
    padding-top: 20px; }
  .soc-project-full__third {
    padding-top: 0; }
  .soc-project-full__space {
    visibility: hidden; }
  .soc-project-full__after {
    font-size: 18px;
    padding: 30px 15px 0; }

.js-svg_attention_small-blue {
  width: 19px;
  height: 19px;
  color: #0094db;
  position: relative;
  top: -2px; }

.scroll-top {
  position: fixed;
  right: 0;
  bottom: -100px;
  width: 47px;
  height: 47px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background: #8cd50e;
  cursor: pointer;
  z-index: 999;
  transition: bottom 0.2s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
  .scroll-top.active {
    bottom: 100px; }

.breadcrumbs {
  color: #a7a7a7;
  font-size: 0;
  padding-top: 30px;
  padding-bottom: 30px; }
  .breadcrumbs__item {
    font-size: 16px;
    margin-right: 16px;
    position: relative; }
  .breadcrumbs__item:hover {
    text-decoration: underline; }
  .breadcrumbs__item:before {
    position: absolute;
    left: -11px;
    content: '/'; }
  .breadcrumbs__item:first-child:before {
    display: none; }

.l-download__one, .l-download__two {
  position: relative;
  width: 50%;
  height: 100%; }

.l-download__broshure {
  position: relative;
  bottom: -40px;
  right: -130px;
  background: url(../img/broshure.png) no-repeat;
  width: 784px;
  height: 472px;
  float: right; }

.l-download__two {
  padding: 68px 0 20px; }

.l-download__title {
  font: 31px Gotham, sans-serif;
  padding-left: 70px;
  margin-bottom: 33px;
  text-transform: uppercase; }

.l-download__line-one, .l-download__line-two, .l-download__line-three, .l-download__line-four {
  padding-left: 150px;
  margin-bottom: 23px;
  font-size: 18px; }

.l-download__line-one {
  font-size: 24px; }

.l-download__context-btn {
  color: #8dd50e;
  text-transform: uppercase;
  font-size: 14px;
  height: 48px;
  line-height: 44px;
  padding: 0 17px; }

.l-download__context-btn-icon {
  display: inline-block;
  position: relative;
  font-size: 22px;
  margin-right: 7px;
  vertical-align: middle;
  width: 32px;
  font-size: 0; }

.l-download__context-btn-label {
  vertical-align: middle; }

.l-download__btn {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -60px;
  margin-left: 50px;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.67);
  color: #000;
  text-align: center;
  padding-top: 12px; }
  .l-download__btn:hover {
    background: rgba(164, 212, 53, 0.67); }

.l-download__btn-icon {
  display: block;
  font-size: 35px;
  margin-bottom: 2px; }

.l-download__btn-label {
  display: block;
  text-decoration: underline;
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 1px; }

.custom-radio,
.custom-checkbox {
  overflow: hidden;
  position: relative; }
  .custom-radio input,
  .custom-checkbox input {
    position: absolute;
    visibility: hidden;
    left: -100px;
    top: -100px; }
  .custom-radio__view,
  .custom-checkbox__view {
    cursor: pointer; }

.custom-radio_type_one,
.custom-checkbox_type_one {
  vertical-align: top; }
  .custom-radio_type_one .custom-radio__view,
  .custom-radio_type_one .custom-checkbox__view,
  .custom-checkbox_type_one .custom-radio__view,
  .custom-checkbox_type_one .custom-checkbox__view {
    position: relative;
    font-size: 18px;
    color: #000;
    border: 2px solid #e8e8e8;
    padding: 22px;
    padding-right: 45px;
    width: 250px;
    text-align: left;
    vertical-align: top; }
    .custom-radio_type_one .custom-radio__view:after, .custom-radio_type_one .custom-radio__view:before,
    .custom-radio_type_one .custom-checkbox__view:after,
    .custom-radio_type_one .custom-checkbox__view:before,
    .custom-checkbox_type_one .custom-radio__view:after,
    .custom-checkbox_type_one .custom-radio__view:before,
    .custom-checkbox_type_one .custom-checkbox__view:after,
    .custom-checkbox_type_one .custom-checkbox__view:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      right: 22px;
      top: 25px; }
    .custom-radio_type_one .custom-radio__view:before,
    .custom-radio_type_one .custom-checkbox__view:before,
    .custom-checkbox_type_one .custom-radio__view:before,
    .custom-checkbox_type_one .custom-checkbox__view:before {
      width: 21px;
      height: 21px;
      background: #e8e8e8; }
    .custom-radio_type_one .custom-radio__view:after,
    .custom-radio_type_one .custom-checkbox__view:after,
    .custom-checkbox_type_one .custom-radio__view:after,
    .custom-checkbox_type_one .custom-checkbox__view:after {
      width: 5px;
      height: 5px;
      background: #fff;
      margin-right: 6px;
      margin-top: 6px;
      border: 2px solid #dcdcdc; }
  .custom-radio_type_one input:checked + .custom-radio__view,
  .custom-radio_type_one input:checked + .custom-checkbox__view,
  .custom-checkbox_type_one input:checked + .custom-radio__view,
  .custom-checkbox_type_one input:checked + .custom-checkbox__view {
    border-color: #0094db; }
    .custom-radio_type_one input:checked + .custom-radio__view:before,
    .custom-radio_type_one input:checked + .custom-checkbox__view:before,
    .custom-checkbox_type_one input:checked + .custom-radio__view:before,
    .custom-checkbox_type_one input:checked + .custom-checkbox__view:before {
      background: #0094db; }
    .custom-radio_type_one input:checked + .custom-radio__view:after,
    .custom-radio_type_one input:checked + .custom-checkbox__view:after,
    .custom-checkbox_type_one input:checked + .custom-radio__view:after,
    .custom-checkbox_type_one input:checked + .custom-checkbox__view:after {
      background: #0094db;
      border-color: #fff; }

.ramp-calc {
  text-align: left;
  border: 1px solid #dcdcdc;
  padding: 40px 18px 35px; }
  .ramp-calc__section {
    margin-bottom: 35px; }
  .ramp-calc__section-label {
    text-transform: uppercase;
    color: #8b8b8b;
    margin-bottom: 15px;
    font-size: 18px; }
  .ramp-calc__section_height {
    float: left; }
  .ramp-calc__section_width {
    float: right; }
  .ramp-calc__section_view {
    overflow: hidden;
    width: 100%; }
  .ramp-calc__total {
    text-align: right;
    padding-top: 32px;
    border-top: 1px solid #e8e8e8; }
  .ramp-calc__total-label {
    font-size: 24px;
    margin-right: 20px; }
  .ramp-calc__total-price {
    font-size: 36px; }
  @media screen and (max-width: 1090px) {
    .ramp-calc {
      max-width: 630px;
      margin: 0 auto;
      padding-left: 60px;
      padding-right: 60px; }
      .ramp-calc__section_height, .ramp-calc__section_width {
        float: none; }
      .ramp-calc .custom-checkbox__view {
        margin-bottom: 15px; } }
  @media screen and (max-width: 665px) {
    .ramp-calc {
      width: 100%;
      margin: 0 auto;
      padding: 30px 5px 0;
      border-left: none;
      border-right: none;
      border-bottom: 0; }
      .ramp-calc__section_height, .ramp-calc__section_width {
        float: none; }
      .ramp-calc__section {
        margin-bottom: 15px; }
      .ramp-calc__section-label {
        font-size: 16px;
        text-align: center; }
      .ramp-calc__total {
        padding: 0;
        text-align: center;
        border-top: none; }
      .ramp-calc__total-label {
        margin-right: 5px; }
      .ramp-calc__total-price {
        font-size: 30px; }
      .ramp-calc__section-content {
        text-align: center; }
      .ramp-calc .custom-checkbox {
        width: 50%; }
      .ramp-calc .custom-checkbox__view,
      .ramp-calc .custom-radio__view {
        font-size: 14px;
        padding: 7px;
        padding-right: 24px;
        margin-bottom: 10px;
        width: 45%; }
        .ramp-calc .custom-checkbox__view:before,
        .ramp-calc .custom-radio__view:before {
          top: 10px;
          right: 7px;
          width: 13px;
          height: 13px; }
        .ramp-calc .custom-checkbox__view:after,
        .ramp-calc .custom-radio__view:after {
          top: 10px;
          right: 7px;
          width: 1px;
          height: 1px;
          margin-right: 4px;
          margin-top: 4px; } }

.img-viewer {
  width: 100%; }
  .img-viewer img {
    max-width: 100%; }

.faq {
  max-width: 738px;
  width: 100%;
  margin: 0 auto; }
  .faq__item {
    border: 1px solid #9a9a9a;
    border-top-width: 0;
    color: #5e5e5e;
    background-color: #fff;
    transition: background-color 0.4s; }
    .faq__item:first-child {
      border-top-width: 1px; }
  .faq__question {
    cursor: pointer;
    font-size: 26px;
    position: relative;
    padding: 20px;
    padding-right: 35px; }
    .faq__question:hover {
      text-decoration: underline; }
    .faq__question:after {
      content: "";
      font-family: fk;
      color: #8abb19;
      font-size: 27px;
      position: absolute;
      right: 20px;
      top: 20px; }
  .faq__answer-content {
    padding: 20px;
    font-size: 18px;
    font-weight: 300; }
  .faq__answer {
    overflow: hidden;
    display: none; }
  .faq__item.active {
    background-color: #fafafb; }
    .faq__item.active .faq__question:after {
      content: "";
      color: #1299da; }
      @media (max-width: 767px) {
        .faq__item.active .faq__question:after {
          top: calc(50% - 12px) !important; } }

.slider {
  position: relative; }

.slick-arrow {
  position: absolute;
  top: 45%;
  outline: none;
  z-index: 10;
  cursor: pointer;
  color: #000;
  background: transparent;
  font-size: 0;
  margin-top: -50px;
  padding: 50px 10px;
  text-shadow: -2px 0 0 #fff, -1px 0 0 #fff, 0 0 0 #fff, 1px 0 0 #fff, 2px 0 0 #fff, 0 -2px 0 #fff, 0 -1px 0 #fff, 0 0 0 #fff, 0 1px 0 #fff, 0 2px 0 #fff, 1px -2px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 1px 2px 0 #fff, 2px -2px 0 #fff, 2px -1px 0 #fff, 2px 0 0 #fff, 2px 1px 0 #fff, 2px 2px 0 #fff, -1px -2px 0 #fff, -1px -1px 0 #fff, -1px 0 0 #fff, -1px 1px 0 #fff, -1px 2px 0 #fff, -2px -2px 0 #fff, -2px -1px 0 #fff, -2px 0 0 #fff, -2px 1px 0 #fff, -2px 2px 0 #fff; }
  .slick-arrow:hover {
    color: #0094db; }
  .slick-arrow:after {
    font-family: 'fk';
    font-size: 40px; }

.slick-slide {
  outline: none; }

.slick-prev {
  left: 0; }
  .slick-prev:after {
    content: ""; }

.slick-next {
  right: 0; }
  .slick-next:after {
    content: ""; }

.slider_regular {
  padding: 0 70px; }
  .slider_regular .slider__slide {
    text-align: center; }
  .slider_regular .slick-arrow {
    top: 0;
    height: 100%;
    color: #c5c5c5; }
    .slider_regular .slick-arrow:after {
      font-size: 30px; }
    .slider_regular .slick-arrow:hover {
      color: #0094db; }

.slider_athlets .slick-arrow {
  top: 0;
  height: 100%;
  color: #c6c6c6; }
  .slider_athlets .slick-arrow:after {
    font-size: 25px; }
  .slider_athlets .slick-arrow:hover {
    color: #0094db; }

@media screen and (max-width: 700px) {
  .slider_athlets .slick-arrow {
    top: 130px;
    height: 25px; } }

.slider_athlets_fulltime {
  /*top: 30px;*/
  z-index: 20;
  padding-top: 30px;
  margin-bottom: -1px; }
  .slider_athlets_fulltime .slick-arrow {
    top: 0;
    height: 100%;
    color: #c6c6c6; }
    .slider_athlets_fulltime .slick-arrow:after {
      font-size: 38px; }
    .slider_athlets_fulltime .slick-arrow:hover {
      color: #0094db; }
  @media screen and (min-width: 1300px) {
    .slider_athlets_fulltime .slick-arrow {
      top: 50%;
      margin-top: -18px;
      height: auto; }
    .slider_athlets_fulltime .slick-prev {
      left: 50%;
      margin-left: -650px; }
    .slider_athlets_fulltime .slick-next {
      right: 50%;
      margin-right: -650px; } }
  @media screen and (max-width: 700px) {
    .slider_athlets_fulltime .slick-arrow {
      top: 130px;
      height: 25px; } }

.slider_one .slick-arrow {
  top: 318px; }

@media screen and (min-width: 1250px) {
  .slider_one .slick-arrow {
    top: 308px;
    right: auto;
    left: auto; }
  .slider_one .slick-prev {
    left: 50%;
    margin-left: -620px; }
  .slider_one .slick-next {
    right: 50%;
    margin-right: -620px; } }

.slide {
  outline: none;
  overflow: hidden;
  cursor: default; }

.slide_style_one {
  position: relative;
  height: 950px; }
  .slide_style_one .slide__content {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto; }
  .slide_style_one .slide__text {
    position: absolute;
    right: 53px;
    top: 0;
    max-width: 523px;
    width: 50%;
    font-size: 18px; }
    .slide_style_one .slide__text p {
      margin: 20px 0; }
    .slide_style_one .slide__text .slide__marked-text {
      font: 24px Gotham, sans-serif;
      text-align: right;
      margin-top: 80px;
      text-transform: uppercase;
      /*text-shadow: 1px 0px 0px #000;*/ }
  .slide_style_one .slide__title {
    text-align: center;
    font: 30px GothamMedium, sans-serif;
    color: #1299da;
    /*text-shadow: 1px 0px 0px #1299da;*/
    text-transform: uppercase; }
  .slide_style_one .slide__img {
    position: relative;
    left: -145px; }
  .slide_style_one .slide__title_big {
    font-size: 32px; }

.slider_docs {
  padding: 0 60px; }
  .slider_docs .slick-arrow {
    top: 50%;
    right: auto;
    left: auto;
    margin-top: -20px;
    color: rgba(255, 255, 255, 0.32);
    text-shadow: none; }
    .slider_docs .slick-arrow:hover {
      color: #0094db; }
  .slider_docs .slick-prev {
    left: 0; }
  .slider_docs .slick-next {
    right: 0; }

.docs-block {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 270px;
  background: rgba(32, 34, 37, 0.78);
  color: #fff;
  overflow: hidden; }

.docs-block__title {
  width: 33.333%;
  float: left;
  font: 36px Gotham, sans-serif;
  text-transform: uppercase;
  margin-top: 68px;
  padding-left: 20px; }

.docs-block__slider {
  width: 66.666%;
  float: right;
  margin-top: 60px; }

.doc {
  display: block;
  position: relative;
  width: 170px;
  height: 128px;
  background: url(../img/file-white.png) no-repeat;
  padding: 20px 17px 7px;
  margin: 0 auto; }
  .doc:hover {
    background-image: url(../img/file-blue.png); }
  .doc__title {
    font-size: 18px; }
  .doc__type {
    position: absolute;
    right: 11px;
    bottom: 7px;
    font-size: 16px; }

.slider_reviews {
  padding: 0 60px;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto; }
  .slider_reviews .slick-arrow {
    top: 70px;
    color: #cbcbcb; }
    .slider_reviews .slick-arrow:hover {
      color: #0094db; }
  .slider_reviews .slick-prev {
    left: 0; }
  .slider_reviews .slick-next {
    right: 0; }

.slider_blog-videos {
  /*.slick-slide{
		width: 32vw !important;
	}
	.slick-current{
		width: 66vw !important;
	}*/ }
  .slider_blog-videos .slick-arrow:after {
    text-shadow: none;
    color: #fff;
    opacity: 0.7;
    font-size: 26px; }
  .slider_blog-videos .slick-arrow:hover:after {
    opacity: 1; }
  @media screen and (min-width: 1200px) {
    .slider_blog-videos {
      width: 120%; }
      .slider_blog-videos .slick-next {
        right: 16.6666%; } }

.link {
  cursor: pointer; }
  .link_blue {
    text-decoration: underline;
    color: #0094db; }
    .link_blue:hover {
      text-decoration: none; }

.loupe {
  width: 100%;
  text-align: center; }
  .loupe__img {
    display: inline-block;
    margin-bottom: 20px;
    border: 2px solid transparent;
    padding: 8px;
    position: relative;
    height: 176px;
    line-height: 176px;
    text-align: center; }
    .loupe__img img {
      max-height: 100%;
      vertical-align: middle; }
    .loupe__img:after {
      content: "";
      font-family: fk;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -11px;
      margin-top: -12px;
      font-size: 27px;
      color: #0094db;
      display: none;
      line-height: normal; }
  .loupe__label {
    font-size: 18px;
    text-transform: uppercase; }
  .loupe:hover .loupe__img {
    border: 2px solid #73bcdf;
    background: rgba(255, 255, 255, 0.59); }
    .loupe:hover .loupe__img img {
      opacity: 0.59; }
    .loupe:hover .loupe__img:after {
      display: block; }
  .loupe:hover .loupe__label {
    text-decoration: underline; }

.page__row_footer {
  background: #0094db;
  color: #fff; }

.page-footer {
  padding: 54px 20px 40px; }
  .page-footer__first, .page-footer__second {
    text-align: justify; }
  .page-footer__menu, .page-footer__contacts, .page-footer__copyright, .page-footer__company, .page-footer__address, .page-footer__soc-icons {
    width: auto; }
  .page-footer__first {
    margin-bottom: 47px; }
  .page-footer__logo {
    width: 130px;
    height: 108px;
    fill: #fff; }
  .page-footer__second {
    position: relative;
    margin-top: -100px;
    width: 78%; }
    .page-footer__second a {
      text-decoration: underline; }
      .page-footer__second a:hover {
        text-decoration: none; }
  .page-footer__justify-last {
    display: inline-block;
    width: 100%;
    height: 0;
    overflow: hidden; }
  .page-footer__copyright, .page-footer__company, .page-footer__address, .page-footer__copyright-mobile {
    font-size: 14px;
    font-weight: 300;
    color: #c3ecff; }
  .page-footer__company span {
    width: auto;
    display: block; }
  .page-footer__soc-icons {
    margin-top: 48px; }
  .page-footer__soc-icon {
    width: 40px;
    height: 40px;
    line-height: 36px;
    /*border: 2px solid #fff;*/
    border-radius: 100%;
    text-align: center;
    font-size: 24px;
    color: #fff; }
    .page-footer__soc-icon:nth-child(1):hover {
      background: #da2713; }
    .page-footer__soc-icon:nth-child(2):hover {
      background: #000000; }
    .page-footer__soc-icon:nth-child(3):hover {
      background: #3b5998; }
    .page-footer__soc-icon:nth-child(4):hover {
      background: #6291c4; }
  .page-footer__contacts {
    text-align: right; }
  .page-footer__menu-btn {
    display: none;
    float: right;
    border: 2px solid #fff;
    height: 48px;
    line-height: 47px;
    color: #fff;
    text-transform: uppercase;
    padding: 0 28px;
    font-size: 14px;
    cursor: pointer; }
    .page-footer__menu-btn:hover {
      background: #fff;
      color: #0094db; }
  .page-footer__copyright-mobile {
    display: none;
    text-align: center;
    margin-top: 25px;
    line-height: normal; }
    .page-footer__copyright-mobile a {
      text-decoration: underline; }
      .page-footer__copyright-mobile a:hover {
        text-decoration: none; }

.contact {
  margin-bottom: 18px; }
  .contact__title {
    font-size: 14px;
    font-weight: 300; }
  .contact__data {
    font-size: 36px;
    font-weight: 300; }

.fixed-help {
  position: fixed;
  right: 0;
  top: 215px;
  z-index: 9999; }
  .fixed-help__close, .fixed-help__content {
    display: none; }
  .fixed-help.open .fixed-help__close,
  .fixed-help.open .fixed-help__content {
    display: block; }
  .fixed-help.open .fixed-help__open {
    display: none; }
  .fixed-help__open {
    width: 130px;
    height: 47px;
    line-height: 47px;
    background: #0094db;
    /* font-size: 0; */
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transform: rotate(-90deg);
    font-size: 18px;
    margin-right: -42px;
    margin-top: 41px; }
  .fixed-help__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 47px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    background: #0094db;
    color: rgba(255, 255, 255, 0.8);
    font-size: 17px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
    .fixed-help__close:hover {
      color: #fff; }
    .fixed-help__close:after {
      content: '';
      display: block;
      width: 100%;
      height: 8px;
      position: absolute;
      left: 0;
      bottom: -4px;
      background: #0094db; }
  .fixed-help__content {
    position: absolute;
    right: 0;
    top: 45px;
    width: 220px;
    background: #0094db;
    color: #fff;
    font-size: 16px;
    padding: 20px 13px;
    font-weight: 300;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
    .fixed-help__content a:hover {
      text-decoration: underline; }
  .fixed-help__item {
    margin-bottom: 13px;
    padding-left: 30px;
    position: relative; }
    .fixed-help__item:before {
      content: '';
      position: absolute;
      right: 100%;
      margin-right: -20px;
      top: 5px; }
    .fixed-help__item:last-child {
      margin-bottom: 0; }
  .fixed-help__item_mail {
    margin-bottom: 20px; }
  .fixed-help__item_chat:before {
    content: "";
    font-family: fk;
    font-size: 18px; }
  .fixed-help__item_mail:before {
    content: "";
    font-family: fk;
    font-size: 12px;
    top: 5px; }
  .fixed-help__item_phones:before {
    content: "";
    font-family: fk;
    font-size: 18px;
    top: 3px; }
  .fixed-help__marked {
    display: block;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 13px; }
    .fixed-help__marked:last-child {
      margin-bottom: 0; }

.form_contact-us .form__section_one {
  width: 50%;
  float: left;
  padding-right: 20px; }

.form_contact-us .form__section_two {
  float: right;
  width: 50%; }
  .form_contact-us .form__section_two textarea {
    height: 140px; }

.form_contact-us .form__section_row {
  overflow: hidden;
  height: 185px; }

.form_contact-us .form__section_four {
  padding-left: 51px;
  display: inline-block;
  font-size: 16px;
  color: #9f9f9f; }
  .form_contact-us .form__section_four a {
    text-decoration: underline; }
    .form_contact-us .form__section_four a:hover {
      text-decoration: none; }

@media screen and (max-width: 1110px) {
  .form_contact-us .form__section_row {
    height: auto;
    margin-bottom: 15px; } }

.form_callback .form__section-phone {
  margin-top: 15px; }
  .form_callback .form__section-phone .text-field {
    margin-bottom: 0; }

.form_callback .form__section-datetime {
  font-size: 0;
  text-align: left; }

.form_callback .form__section-date {
  display: inline-block;
  width: 40%;
  padding: 10px 10px 10px 0; }

.form_callback .form__section-hours {
  display: inline-block;
  width: 30%;
  padding: 10px 10px 10px 0; }

.form_callback .form__section-minutes {
  display: inline-block;
  width: 30%;
  padding: 10px 0 10px 0; }

.form_callback .form__section-button {
  text-align: center; }
  .form_callback .form__section-button .btn {
    margin-top: 10px; }

.contact_two {
  text-align: left;
  margin-bottom: 20px; }
  .contact_two .contact__data {
    font: 32px/32px Gotham, sans-serif; }
  .contact_two .contact__title {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 3px; }

.vk-widget {
  width: 100% !important; }

@media screen and (max-width: 1024px) {
  .build-instruction-video {
    height: 480px; } }

@media screen and (max-width: 700px) {
  .build-instruction-video {
    height: 320px; } }

@media screen and (max-width: 480px) {
  .build-instruction-video {
    height: 220px; } }

.video-blog-item {
  display: block;
  overflow: hidden;
  position: relative;
  cursor: pointer; }
  .video-blog-item__bg {
    height: 670px;
    width: 100%;
    position: relative;
    background-size: auto 100% !important;
    transition: background-size 0.4s; }
    .video-blog-item__bg:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: url(../img/slider-video-overlay.png) center center no-repeat;
      z-index: 5; }
  .video-blog-item:hover .video-blog-item__bg {
    background-size: auto 105% !important; }
  .video-blog-item__content {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    color: #fff; }
  .video-blog-item__title, .video-blog-item__play {
    position: absolute;
    top: 50%;
    left: 10%; }
  .video-blog-item__title {
    font: 24px/40px Gotham, sans-serif;
    max-width: 435px;
    margin-top: 20px; }
  .video-blog-item__play {
    font: 18px Gotham, sans-serif;
    margin-top: -50px; }
  .video-blog-item .js-svg_play {
    height: 53px;
    width: 53px;
    margin-left: 10px; }
  @media screen and (max-width: 1260px) {
    .video-blog-item__bg {
      height: 480px; }
    .video-blog-item__title {
      font-size: 20px;
      line-height: 32px;
      max-width: 300px; }
    .video-blog-item__play {
      font-size: 16px; }
    .video-blog-item .js-svg_play {
      height: 40px;
      width: 40px; } }
  @media screen and (max-width: 1024px) {
    .video-blog-item__bg {
      height: 385px; }
    .video-blog-item__title {
      font: normal 16px/22px Calibri, sans-serif;
      max-width: 250px; }
    .video-blog-item__play {
      font-size: 14px; }
    .video-blog-item .js-svg_play {
      height: 30px;
      width: 30px;
      margin-left: 7px; } }
  @media screen and (max-width: 767px) {
    .video-blog-item__bg {
      height: 330px;
      background-size: cover !important; }
    .video-blog-item:hover .video-blog-item__bg {
      background-size: cover !important; }
    .video-blog-item__title {
      font: normal 14px/22px Calibri, sans-serif;
      max-width: 190px; }
    .video-blog-item__play {
      font-size: 12px; }
    .video-blog-item .js-svg_play {
      height: 25px;
      width: 25px;
      margin-left: 5px; } }

.panel {
  position: relative;
  z-index: 11;
  padding: 23px 15px 25px;
  line-height: normal;
  overflow: hidden;
  width: 100%; }
  .panel__title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    line-height: normal; }
  .panel__content {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    text-transform: none;
    line-height: normal;
    width: 100%; }
  .panel__bgicon {
    position: absolute;
    bottom: -10px;
    right: -30px;
    width: 130px;
    height: 140px;
    color: #157eb1;
    z-index: -1; }
  .panel__close {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 15px;
    display: none; }
  .panel__more {
    position: absolute;
    bottom: 5px;
    right: 15px;
    text-align: right;
    padding: 4px 0;
    text-decoration: underline;
    display: none;
    font-style: italic; }
  .touch-device .panel {
    padding-bottom: 35px; }
  .touch-device .panel__close,
  .touch-device .panel__more {
    display: block; }
  @media screen and (max-width: 550px) {
    .panel .panel__title {
      font-size: 18px;
      margin-bottom: 20px; }
    .panel .panel__content {
      font-size: 14px; } }

.panel_blue {
  background: #0094db;
  color: #fff; }

@media screen and (max-width: 1180px) {
  .slide_number_two .slide__img {
    position: absolute;
    bottom: 0;
    top: auto;
    left: -70px; }
    .slide_number_two .slide__img img {
      width: 600px; }
  .slide_number_two .slide__text {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 100%;
    max-width: 100%; }
  .slide_number_two .slide__content {
    height: 726px;
    padding: 0 45px; }
  .slide_number_two .slide__title {
    padding-right: 15px;
    padding-left: 15px; }
  .slide_number_one .slide__img {
    position: absolute;
    top: 0;
    left: 50px; }
    .slide_number_one .slide__img img {
      width: 440px; }
  .slide_number_one .slide__text {
    position: relative;
    left: auto;
    right: auto;
    top: 270px;
    bottom: auto;
    width: 100%;
    max-width: 100%;
    padding-top: 30px; }
  .slide_number_one .slide__content {
    height: 726px;
    padding: 0 45px; }
  .slide_number_one .slide__text .slide__marked-text {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin: 0;
    width: 220px;
    text-align: right;
    font: bold 18px Calibri, sans-serif;
    text-transform: none; }
  .slide_number_one .slide__title {
    padding-right: 15px;
    padding-left: 15px; }
  .slider_one .slick-arrow {
    top: 230px !important; }
    .slider_one .slick-arrow:after {
      color: #ccc;
      font-size: 34px; }
    .slider_one .slick-arrow:hover:after {
      color: #0094db; }
  .from-1180px {
    display: block; } }

@media screen and (max-width: 1110px) {
  .page-footer {
    padding: 37px 20px 33px; }
    .page-footer__logo {
      width: 115px;
      height: 95px;
      fill: #fff; }
    .page-footer__contacts {
      width: 100%;
      text-align: left;
      padding-top: 32px; }
      .page-footer__contacts .contact {
        display: inline-block;
        margin-right: 7%; }
        .page-footer__contacts .contact__data {
          font-size: 24px;
          font-weight: 300; }
    .page-footer__first {
      margin-bottom: 30px; }
    .page-footer__second {
      position: static;
      margin-top: 0;
      width: 100%; }
    .page-footer__soc-icons {
      float: right;
      margin-top: 0; }
    .page-footer__soc-icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
      /*border: 2px solid #fff;*/
      border-radius: 100%;
      text-align: center;
      font-size: 24px;
      color: #fff; }
      .page-footer__soc-icon:hover {
        background: #fff;
        color: #0094db; }
    .page-footer__company span {
      display: inline-block;
      margin-right: 5px; }
    .page-footer__copyright, .page-footer__copyright, .page-footer__address, .page-footer__company {
      padding-right: 50px;
      width: 50%;
      margin-bottom: 22px;
      text-align: left; }
  .br_footer {
    display: none; } }

@media screen and (max-width: 1060px) {
  .docs-block__title {
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
    text-align: center;
    font-size: 30px; }
  .docs-block__slider {
    width: 100%;
    margin-top: 25px; } }

@media screen and (max-width: 950px) {
  .l-download__two,
  .l-download__one {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px; }
  .l-download {
    position: relative;
    padding-top: 80px;
    height: 470px; }
  .l-download__title {
    text-align: center;
    padding: 0; }
  .l-download__btn {
    z-index: 99;
    right: 405px;
    top: 235px;
    margin: 0;
    left: auto; }
  .l-download__broshure {
    position: absolute;
    margin: 0;
    right: 205px   !important;
    bottom: -25px;
    width: 556px;
    height: 345px;
    background-size: contain !important; }
  .l-download__line-one {
    padding-left: 30px;
    width: 262px; }
  .l-download__line-two {
    position: absolute;
    top: 113px;
    right: 30px;
    width: 215px;
    padding: 0; }
  .l-download__line-three {
    position: absolute;
    top: 192px;
    right: 34px;
    width: 165px;
    padding: 0; }
  .l-download__line-four {
    position: absolute;
    bottom: 10px;
    right: 30px;
    width: 230px;
    padding: 0; } }

@media screen and (max-width: 768px) {
  .page__row_header {
    background-size: cover; }
  .scroll-top {
    width: 45px;
    height: 45px;
    line-height: 48px;
    font-size: 20px; }
  .page-footer__menu {
    display: none; }
  .page-footer__menu-btn,
  .page-footer__copyright-mobile {
    display: block; }
  .page-footer__contacts {
    text-align: center; }
    .page-footer__contacts .contact {
      display: inline-block;
      margin-right: 20px;
      margin-left: 20px; }
      .page-footer__contacts .contact__data {
        font-size: 30px; }
  .page-footer__soc-icons {
    display: block;
    float: none;
    text-align: center; }
  .page-footer__copyright,
  .page-footer__copyright,
  .page-footer__address,
  .page-footer__company {
    display: none; }
  .page-footer__logo {
    height: 75px;
    width: 92px; }
  .page-footer {
    padding-bottom: 15px; }
  .slide_number_two .slide__img {
    position: absolute;
    bottom: 0;
    top: auto;
    left: -70px; }
    .slide_number_two .slide__img img {
      width: 600px; }
  .slide_number_two .slide__text {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 100%;
    max-width: 100%;
    font-size: 16px; }
  .slide_number_two .slide__text .slide__marked-text {
    font-size: 18px; }
  .slide_number_one .slide__img {
    position: absolute;
    bottom: 170px;
    top: auto;
    left: 50%;
    margin-left: -205px; }
    .slide_number_one .slide__img img {
      width: 410px; }
  .slide_number_one .slide__text {
    position: relative;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    width: 100%;
    padding-top: 50px; }
  .slide_number_one .slide__text .slide__marked-text {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 100%;
    text-align: right;
    font: bold 16px Calibri, sans-serif;
    text-transform: none; }
  .slide_style_one .slide__title_big,
  .slide_style_one .slide__title {
    font-size: 24px !important; }
  .slider_one .slick-arrow {
    top: auto !important;
    bottom: 628px !important;
    width: 50px;
    height: 70px; }
    .slider_one .slick-arrow:after {
      color: #ccc;
      font-size: 28px; }
  .slider_one .slick-prev {
    left: 0; }
  .slider_one .slick-next {
    right: 0; } }

@media screen and (max-width: 660px) {
  .l-download {
    position: relative;
    padding-top: 80px;
    height: 650px; }
  .l-download__title {
    text-align: center; }
  .l-download__btn {
    display: none; }
  .l-download__broshure {
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: 70px;
    width: 453px;
    height: 276px;
    margin-left: -226px;
    background-size: contain !important; }
  .l-download__line-one,
  .l-download__line-two,
  .l-download__line-three {
    position: static;
    padding-left: 30px;
    width: 100%; }
  .l-download__line-two {
    margin-bottom: 5px; }
  .l-download__line-four {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 0; }
  .l-download__context-btn {
    background: #8dd50e;
    color: #000; }
  .docs-block__title,
  .docs-block__slider {
    float: none;
    width: 100%; }
  .docs-block__title {
    font-size: 24px;
    margin-top: 20px;
    text-align: center; }
  .docs-block__slider {
    margin-top: 20px; }
  .form_contact-us .form__section_one {
    width: 100%;
    float: none;
    padding-right: 0; }
  .form_contact-us .form__section_two {
    float: none;
    width: 100%; }
  .form_contact-us .form__section_three {
    padding-top: 0;
    text-align: center; }
  .form_contact-us .form__section_row {
    overflow: hidden; }
  .form_contact-us .form__section_four {
    margin-top: 10px;
    padding-left: 0;
    display: block; } }

@media screen and (max-width: 570px) {
  .faq__question {
    font-size: 18px; }
  .faq__answer-content {
    padding-top: 20px;
    font-size: 15px; } }

@media screen and (max-width: 510px), screen and (max-height: 515px) {
  .fixed-help {
    top: auto;
    right: auto;
    right: 0;
    bottom: 0; }
    .fixed-help__open {
      font-size: 18px;
      width: 111px;
      height: 46px;
      right: 0;
      bottom: 0;
      left: auto;
      top: auto;
      line-height: 46px;
      background-image: none;
      margin: 0;
      transform: rotate(0); }
    .fixed-help__close {
      right: 0;
      bottom: 0;
      top: auto;
      left: auto; }
      .fixed-help__close:after {
        height: 100%;
        width: 8px;
        left: -4px;
        top: 0; }
    .fixed-help__content {
      top: auto;
      left: auto;
      right: 46px;
      bottom: 0; } }

@media screen and (max-width: 480px) {
  .slide_number_two .slide__content {
    height: 900px;
    padding: 0 10px; }
  .slide_number_two .slide__marked-text {
    margin-top: 30px !important; }
  .slide_number_one .slide__img {
    position: absolute;
    bottom: 80px;
    top: auto;
    left: 50%;
    margin-left: -205px; }
    .slide_number_one .slide__img img {
      width: 410px; }
  .slide_number_one .slide__text {
    position: relative;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    width: 100%;
    padding-top: 60px; }
  .slide_number_one .slide__text .slide__marked-text {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 100%;
    text-align: right;
    font: bold 16px Calibri, sans-serif;
    text-transform: none; }
  .slide_number_one .slide__content {
    height: 760px;
    padding: 0 10px; }
  .slide_style_one .slide__title_big,
  .slide_style_one .slide__title {
    font-size: 18px; }
  .slider_one .slick-arrow {
    bottom: 300px; }
  .contact_two .contact__data {
    font-size: 30px; }
  .page-footer {
    padding: 20px 7px 20px; }
  .slider_docs {
    padding: 0 40px; } }
