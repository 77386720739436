.bg1 {  padding: 49px 0 0;  }
.nw {
  white-space: nowrap;
}
.raid {
  text-align: center;
  h1 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 30px;
  }
}
.row-gallery  {
  .raid {
    &__img {
      position: relative;
      padding: 0 34px;
      @media (min-width: 1400px) {
        &:before {
          content: '';
          z-index: -1;
          position: absolute;
          top:50%;
          left: 0;
          height: 2px;
          width: 100%;
          background: transparent;
        }
      }
    }
    h1{
      font-size: 24px;
    }
  }
  .section_reasons-to-build {
    padding: 0 0 20px;
  }
  .grid_ramp.green-line .raid__img:before {background: #a4d435;}
  .grid_ramp.blue-line .raid__img:before {background: #0393d8;}
  .grid_ramp.red-line .raid__img:before {background: #fb5800;}
  @media (min-width: 768px) {
    .grid_ramp .grid__item {
      width: 33.3333% !important;
    }
  }   
  //@media (min-width: 1025px) {
  //  .grid_ramp {
  //    display: inline !important;
  //  }
  //  .grid_ramp .grid__item {
  //    width: 25% !important;
  //  }
  //}
  @media (min-width: 1400px) {
    .grid_ramp .grid__item {
      width: 16.6667% !important;
    }
  }

  @media (max-width: 767px) {
    .grid_ramp .grid__item {
      width: 50%;
      .raid__img,
      .rids {
        padding-left: 10px;
        padding-right: 10px;
      }
      .rids h1 {
        font-size: 20px;
      }
    }
  }
  .rids {
    padding: 15px 34px 38px;
    p {
      width: auto;
      font-size: 14px;
      margin-top: 15px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}
.rids {
  padding: 35px 0 38px;
  p {
    font-size: 14px;
    color: #939393;
    width: 232px;
    margin: 22px auto 0;
  }
}
.js-svg_arr {
  height: 23px;
  float: right;
  width: 14px;
  margin-left: 8px;
}
.spb {
  line-height: 24px;
  span {
    text-transform: none;
    font-size: 18px;
    font-weight: 300;
  }
}
.bg3 {  padding: 50px 0 20px;  }
.blog-news {
  width: 380px;
  margin-bottom: 30px;
  &:hover {
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.27);
  }
}
.blog-text {
  border: 1px solid #c4c4c4;
  border-top: 0;
  padding: 1px 0 1px;
  a {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 0;
    display: initial;
    line-height: 20px;
    &:hover {
      color: getBase('color-two');
    }
  }
}
.box-text {
  margin: 25px 29px 44px 19px;
  &.alignr {
  	//text-align: right;
  }
  .readmore {
  	text-decoration: underline;
    text-align: right;
    color: #0094db;
    font-weight: normal;
    font-size: 16px;
  }
  p {
    font-size: 15px;
    color: #000;
        text-align: justify;
  }
}
.blog-date {
  font-size: 13px !important;
  color: #7b7b7b !important;
}
.href {
  text-align: center;
  margin: 12px 0 0;
  white-space: nowrap;
  a {
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 18px;
    color: #6e6e6e;
    &:hover {
      color: #1691cc;
    }
    svg {
      width: 13px;
      margin: -2px 0 0 5px;
    }
  }
}
.blog-sep-page {
  .href {
    padding: 10px 0;     margin-top: -7px !important;
  }
  @media (max-width: 1030px) {
    .href {
      margin-top: 4px !important;
    }
  }
  .section_reasons-to-build {
    padding: 0 0 20px;
  }
  @media (max-width: 800px) {
    .section_reasons-to-build {
      padding: 20px 7px;
    }
  }
}
.bg0 {
  background: url(../img/bg.jpg) top center no-repeat;
  padding-bottom: 96px;
}
.top-box {
  float: right;
  width: 45%;
}
.top-text {
  margin-right: 22px;
  p {
    color: #1c2330;
    font-size: 18px;
    text-align: right;
    margin-bottom: 27px;
  }
}
.numb-item {
  float: right;
  margin-left: 111px;
  text-align: right;
  h1 {
    font-weight: 400;
    font-size: 36px;
    font-family: Gothic, sans-serif;
  }
  p {
    font-size: 18px;
    margin-top: 7px;
    color: #000;
  }
}
.box-number {
  display: table;
  margin-top: 51px;
}
.dv {
  display: table;
  float: right;
  margin: 1px 0 0;
  font-size: 22px;
}
.logos {
  width: 480px;
  height: 40px;
  background: url(../img/logo.svg) no-repeat top center;
  background-size: contain;
  margin: 85px auto 47px;
  display: table;
}
.section_header1 {
  background-color: rgba(0, 0, 0, .65);
  background-image: url(../img/bg0.png);
}
@media screen and (min-width: 1025px) {
  .mob,
  .tbl {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .bol {
    display: none !important;
  }
}
@media screen and (max-width: 620px) {
  .pc {
    display: none !important;
  }
  .page__row_header {
    background: url(../img/mbg.jpg) center 0 no-repeat;
    background-size: cover;
  }
  .header__title.mob {
    font-family: Gothic,sans-serif;
    font-weight: bold;
    margin: 81px 0 0;
  }
  .bg0 {
    background: url(../img/mbg1.jpg) bottom center no-repeat;
    padding-bottom: 0;
    background-size:contain;
  }
  .top-box {
    float: none; 
    width: 100%;
  }
  .logos {
    width: 95%;
    margin: 32px 0 0;
  }
  .top-text p { 
    text-align: left;
  }
  .numb-item {
    float: none;
    text-align: center;
    margin: 0 auto;
  }
  .box-number {
    margin: 22px auto;
    text-align: center;
  }
  .numb-item {
    p {
      text-align: center;
    }
    h1 {
      font-weight: bolder;
    }
  }
  .raid img {
    width: 220px;
  }
  .rids h1 {
    font-size: 17px;
    margin-bottom: -8px;
  }
  .rids {
    padding-top: 14px;
  }
  .blog-news {
    width: 100%;
    max-width: 380px;
    margin: 0 auto 20px;
  }
  .box-text {
    margin: 25px 8px 31px;
    background: #fff;
     a {
      font-size: 19px;
    }
  }
  .bg3 {
    background: #f5f5f5;
  }
  .blog-text {
    background: #fff;
  }
  .slick-next {
    right: -8px !important;
  }
  .slick-prev {
    left: -8px !important;
  }
  .slick-arrow {
    top: 55% !important;
  }
  .slick-arrow {
    color: #fff !important;
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.3) !important;
    width: 47px !important;
    height: 47px !important;
    border-radius: 61px;
    font-size: 20px;
    padding: 0 !important;
  }
  .slick-arrow:after {
    font-size: 20px !important;
  }
  .href {
    margin: -8px 0 0;
  }
  .tbl {
    display: none !important;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .blog-news {
    width: 95%;
    margin: 0 auto 30px;
  }
}
@media (min-width: 100px) and (max-width: 1025px) {
  h1.header__title.tbl {
    margin-top: -108px;
  }
}
@media (min-width: 621px) and (max-width: 1025px) {
  .slick-list.draggable {
    max-width: 780px;
    margin: 0 auto;
  }
  .tb {
    width: 33% !important;
  }
  .notbs,
  .pc {
    display: none !important;
  }
  .tbs {
    display: block !important;
  }
  .blog-news {
    width: 95%;
    margin: 0 auto 20px;
    max-width: 380px;
  }
  .raid img {
    width: 90%;
  }
  .rids h1 {
    font-size: 21px;
  }
  i.logos {
    width: 90%;
    margin: 54px 0 24px;
  }
  .top-text p {
    text-align: left;
    font-size: 16px;
  }
  h1.header__title.tbl {
    font-weight: bold;
  }
  .numb-item h1 {
    font-weight: bold;
    margin-left: 0 !important;
  }
  .numb-item {
    margin-left: 28px;
    height: 108px;
    p {  text-align: right;  }
  }
  .top-box {
    width: 47%;
  }
  .bg0 {  padding-bottom: 0;  }
  .page__row.page__row_header {
    background: url(../img/tbg.jpg) center no-repeat;
    background-size: cover;
  }
  .slick-next {  right: -8px !important;  }
  .slick-prev {  left: -8px !important;  }
  .slick-arrow {
    top: 55% !important;
  }
  .slick-arrow {
    color: #fff !important;
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.2) !important;
    width: 47px !important;
    height: 47px !important;
    border-radius: 61px;
    font-size: 20px;
    padding: 0 !important;
  }
  .slick-arrow:after {  font-size: 20px !important;  }
  .bg3 {  padding-left: 3px;  }
  .href {
    margin-top: 48px !important;
  }
}
