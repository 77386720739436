.header__title {
  .sub {
    font-size: 0.7em;
    display: block;
    text-transform: none;
  }
}
.myvideo {
  margin-bottom: 20px;
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
  border:1px solid #ccc;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.page__row_post {
  .section {
    padding-top: 0;
  }
  .section__title,
  .section__content {
    max-width: 750px;
    background: #fff;
  }
  .section__title {
    margin: 0 auto;
    padding: 20px 10px;
  }
  p {
    margin: 20px;
    color: #1c2330;
    font-size: 18px;
  }
}
.post_additional {
  border: 2px solid #a4d434;
  padding: 20px 25px;
  margin: 20px 20px 70px;
  color: #505050;
  font-size: 16px;
  @media screen and (max-width: 660px) {
    padding: 7px 10px;
    margin: 20px 10px;
  }
  a {
    color: #505050;
    text-decoration: underline;
    margin-left: 30px;
    @media screen and (max-width: 660px) {
      margin: 0;
    }
    &:hover {
      color: getBase('color-two');
    }
    @media screen and (max-width: 660px) {
      color: getBase('color-two');
      text-decoration: underline;
    }
  }
}
.page__row_blog-header {
  .btn_border.btn_white {
    background: rgba(0,0,0, .48);
    &:hover {
      background: getBase('color-two');
      border-color: getBase('color-two');
      color: #fff;
    }
  }
}
.pluso {
  margin: 0 20px;
  @media screen and (max-width: 660px) {
    margin: 0 10px;
  }
}
