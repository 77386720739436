.detailed{
	&__title{
		font: 30px/40px Gotham, sans-serif;
		width: 100%;
		padding-right: 50px;
	}

	&__gallery{
		width: 50%;
		float: right;
		margin-top: 15px;
	}

	&__info{
		width: 50%;
		float: left;
		padding-top: 25px;
		padding-right: 50px;
	}

	&__info-text{
		font-size: 18px;
		line-height: 18px;
	}

	&__info-blocks{
		display: block;
		font-size: 0;
		line-height: 0;
		margin-bottom: 10px;
	}

	&__info-block{
		display: inline-block;
		margin-right: 12px;
		margin-bottom: 12px;
		font-size: 14px;
		line-height: normal;
		border: 3px solid #000;
		width: 117px;
		height: 117px;
		padding: 30px 8px;
		vertical-align: top;

		&:last-child{
			margin-right: 0;
		}
	}

	&__info-block-value{
		font: 22px Gotham, sans-serif;
	}

	&__info-block_city{
		border-color: getBase('color-two');

		.detailed__info-block-value{
			font-size: 16px;
			font-weight: bold;
		}
	}
	&__info-block_year{
		border-color: getBase('color-one');
	}
	&__info-block_area{
		border-color: #aaaaaa;
	}
	&__info-block_time{
		border-color: #000;
	}

	&__arrows{
		width: 100%;
		overflow: hidden;
		padding-top: 20px;
	}

	&__arrow{
		text-transform: uppercase;
		font-size: 16px;
		color: #595959;
		cursor: pointer;
		line-height: 25px;
		text-decoration: underline;
		&:hover{
			color: getBase('color-two');
		}
	}

	&__arrow:after,
	&__arrow:before{
		position: relative;
		font-family: fk;
		display: inline-block;
		vertical-align: middle;
		font-size: 22px;
		top: -1px;
		text-decoration: none;
	}

	&__arrow_left{
		float: left;
		&:before{
			content: $ic-arrow-left;
			margin-right: 8px;
		}
	}
	&__arrow_right{
		float: right;
		&:after{
			content: $ic-arrow-right;
			margin-left: 8px;
		}
	}

	&__mobile-arrows{
		display: none;
		overflow: hidden;
		padding: 10px;
		text-align: center;
		&.detailed__mobile-arrows_top {
			display: block;
			.modal__close {
				display: none
			}
			@media  (min-width:768px) {
				.detailed__arrow {
					top: -5px;
					position: absolute;
					&_left  {
						right: 75px;
					}
					&_right  {
						right: 33px;
					}
				}
			}
		}

	}

	@media screen and (max-width:1180px) {
		&__info-block{
			margin-right: 10px;
			font-size: 14px;
			width: 115px;
			height: 115px;
			padding: 30px 15px 15px;
		}

		&__info{
			padding-right: 40px;
		}
	}


	@media screen and (max-width:1024px) {
/*		.modal__close{
			font-size: 16px;
			top: 25px;
			right: 10px;
		}*/

		&__info-blocks{
			width: 170px;
			float: right;
			text-align: right;

		}
		&__info-block{
			text-align: left;
			margin-right: 0;
			font-size: 14px;
			width: 115px;
			height: 115px;
			padding: 30px 15px 15px;
			margin-bottom: 15px;
		}

		&__title{
			width: 100%;
			font-size: 30px;
			padding-right: 40px;
		}

		&__info,
		&__gallery{
			float: none;
			padding: 0;
			width: 100%;
			margin-top: 15px;
		}

		&__info-text{
			padding-top: 20px;
			margin-right: 170px;
		}
	}

	@media screen and (max-width:768px) {
		&__info-text{
			padding-top: 0;
		}
	}

	@media screen and (max-width:660px) {
		/*.modal__close{
			font-size: 16px;
			top: 15px;
			right: 10px;
		}*/

		&__title{
			font-size: 18px;
			line-height: normal;
			text-align: center;
		}
		&__info-blocks{
			text-align: left;
			float: none;
			width: 100%;
		}

		&__info-block{
			border: none;
			height: auto;
			width: auto;
			margin-right: 15px;
			padding: 8px 10px;
			background: #000;
		}

		&__info-block-value{
			font-size: 16px;
		}


		&__info-block_city{
			background: getBase('color-two');

		}
		&__info-block_year{
			background: getBase('color-one');
		}
		&__info-block_area{
			background: #aaaaaa;
		}
		&__info-block_time{
			background: #000;
			color: #fff;
		}

		&__info-block-label{
			display: none;
		}

		&__info-text{
			margin: 0;
		}

		.detailed__mobile-close{
			display: inline-block !important;
			position: static;
			text-align: center;
			color: #717a81;
			font-size: 18px ;

			.ic-close{
				vertical-align: middle;
			}

			&:hover{
				color: getBase('color-two');
			}
		}

		&__arrows{
			display: none;
		}

		&__mobile-arrows{
			display: block;
		}
	}

	@media screen and (max-width:480px) {
		&__info{
			padding-left: 7px;
			padding-right: 7px;
		}
	}
}
