.gallery{
	width: 100%;

	&__main{
		position: relative;
	}
	
	&__main-img{
		min-width: 100%;
		max-width: 100%;
	}

	&__thumb-wrap{
		display: inline-block;
		width: 25%;
		padding: 13px 0 0 13px;
		cursor: pointer;
	}

	&__thumbs{
		font-size: 0;
		line-height: 0;
		margin-left: -13px;
	}
	
	&__thumb{
		min-width: 100%;
		max-width: 100%;
	}
	
	&__thumb-wrap.active{
		.gallery__thumb{
			border: 3px solid getBase('color-two');
		}
	}
}

/*-----------------------------------------------------------
	Custom
-----------------------------------------------------------*/