.fk-item__title {
 
    margin-bottom: 0px;
}
.fk-item_stage  {
	&:hover {
		path {
			fill: #fff;
		}
	}
	path {
		fill: #888888;
		
	}
}


.js-svg_fk_item_1 {
	svg {
		width: 30px;
		height: 30px;
		margin-top: -12px;
	}
}

.js-svg_fk_item_2 {
	svg {
		width: 37px;
		height: 23px;
		margin-top: -10px;
		margin-left: 5px;
	}
}

.js-svg_fk_item_3 {
	svg {
		width: 35px;
		height: 35px;
		margin-top: -11px;
		path {
			fill: #888888;
		}
	}
}

.js-svg_fk_item_4 {
	svg {
		width: 32px;
		height: 25px;
		margin-top: -12px;
	}
}

.js-svg_fk_item_5 {
	svg {
		width: 32px;
		height: 24px;
		margin-top: -14px;
		margin-left: 3px;
	}
}

.js-svg_fk_item_6 {
	svg {
		width: 41px;
		height: 34px;
		margin-top: -14px;
		margin-left: 4px;
	}
}

.fk-item__title {
	/*line-height: 20px;*/
	text-transform: uppercase;
}

.fk-item__desc {
	margin-top: 10px;
}


.grid_stages {
	.grid__item {
		.fk-item {
			&.fk-item_stage {
				&.first-stage {
					right: 43px;
				}
				&.second-stage {
					margin-top: 84px;
				}
				&.fourth-stage {
					left: 44px;
				}
				&.fifth-stage {
					margin-top: 84px;
					.fk-item__number {
						top:0;
					}
				}
				&.third-stage {
					margin-top: 84px;
					right: 43px;
				}
				&.sixth-stage {
					margin-top: 84px;
					left: 44px;
				}
			}
		}
	}
}

// .section__content  {
// 	position: relative;
// 	&:before {
// 		content: '';
// 		left: 0;
// 		right: 0;
// 		top:50%;
// 		height: 1px;
// 		background:red;
// 		z-index: 1000;
// 		position: absolute;
// 	}
// 	&:after {
// 		content: '';
// 		height: 100%;
// 		left: 424px;
// 		left: 392px;
// 		width: 1px;
// 		background:red;
// 		z-index: 1000;
// 		position: absolute;
// 		top:0;
// 	}
// }

// .grid_stages {
// 	&:after {
// 		content: '';
// 		height: 100%;
// 		right: 424px;
// 		width: 1px;
// 		background:red;
// 		z-index: 1000;
// 		position: absolute;
// 		top:0;
// 	}
// }


@media screen and (max-width: 1200px) {
	.fk-item__storage-map img {
		max-width: 50%;
	}
	.fk-item_stage {
		max-width: 205px;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {
					.fk-item__number {
						top:0;
					}
					.fk-item__title {
						a {
							font-size: 14px;
						}
					}
					&.first-stage {
						top: 0 !important;
						right: 35px!important;
					}
					&.second-stage {
						margin-top:49px;
						right: 102px !important;
						top:0 !important;
					}
					&.third-stage {
						margin-top: 49px;
						right: 35px;
						top:0;
					}
					&.fourth-stage {
						top: 0 !important;
						left: 40px!important;
						max-width: 205px;
					}
					&.fifth-stage {
						margin-top: 49px;
						left: 90px!important;
						top: 0;
						.fk-item__number {
							left: -72px!important;
						}
					}
					&.sixth-stage {
						margin-top: 49px !important;
						left: 40px!important;
						top: 0 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1180px) {
	.fk-item__storage-map img {
		max-width: 50%;
	}
	.fk-item_stage {
		max-width: 205px;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {
					.fk-item__number {
						top:0;
					}
					.fk-item__title {
						a {
							font-size: 14px;
						}
					}
					&.first-stage {
						top: 0 !important;
						right: 35px!important;
					}
					&.second-stage {
						margin-top:49px !important;
						right: 102px !important;
						top:0 !important;
					}
					&.third-stage {
						margin-top: 49px !important;
						right: 35px !important;
						top:0;
					}
					&.fourth-stage {
						top: 0 !important;
						left: 40px!important;
						max-width: 205px;
					}
					&.fifth-stage {
						margin-top: 49px !important;
						left: 90px!important;
						top: 0;
						.fk-item__number {
							left: -72px!important;
						}
					}
					&.sixth-stage {
						margin-top: 49px !important;
						left: 40px!important;
						top: 0 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1110px) {
	.fk-item__storage-map img {
		max-width: 50%;
	}
	.fk-item_stage {
		max-width: 205px;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {
					.fk-item__title {
						a {
							font-size: 14px;
						}
					}
					&.first-stage {
						top: 0 !important;
						right: 32px!important;
					}
					&.second-stage {
						margin-top:29px !important;
						right: 86px !important;
						top:0 !important;
					}
					&.third-stage {
						margin-top: 29px !important;
						right: 32px !important;
						top:0 !important;
					}
					&.fourth-stage {
						top: 0 !important;
						left: 40px!important;
						max-width: 205px;
					}
					&.fifth-stage {
						margin-top: 29px !important;
						left: 90px!important;
						top: 0 !important;
						.fk-item__number {
							left: -72px!important;
						}
					}
					&.sixth-stage {
						margin-top: 29px !important;
						left: 40px!important;
						top: 0 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.fk-item__storage-map img {
		max-width: 50%;
	}
	.fk-item_stage {
		max-width: 205px;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {
					.fk-item__title {
						a {
							font-size: 14px;
						}
					}
					&.first-stage {
						top: -10px !important;
						right: 47px!important;
					}
					&.second-stage {
						margin-top:39px !important;
						right: 82px !important;
						top:-10px !important;
					}
					&.third-stage {
						margin-top: 39px !important;
						right: 47px !important;
						top:-10px !important;
					}
					&.fourth-stage {
						top: -10px !important;
						left: 40px!important;
						max-width: 205px;
					}
					&.fifth-stage {
						margin-top: 39px !important;
						left: 79px!important;
						top: -10px !important;
						.fk-item__number {
							left: -72px!important;
						}
					}
					&.sixth-stage {
						margin-top: 39px !important;
						left: 40px!important;
						top: -10px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.section_stages {
		padding: 20px 5px 50px;
	}
	.grid_stages .grid__item .fk-item.fk-item_stage .fk-item__title a {
		font-size: 12px;
	}
	.fk-item_stage .fk-item__desc{
		font-size: 13px;
	}
}


@media screen and (max-width: 870px) {
	.fk-item__storage-map img {
		max-width: 48%;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {
					&.first-stage {
						top: -10px !important;
						right: 39px!important;
						margin-top: 0 !important;
					}
					&.second-stage {
						margin-top:19px !important;
						right: 63px !important;
						top:-10px !important;
						.fk-item__desc {
							padding-left: 15px;
						}
					}
					&.third-stage {
						margin-top:9px !important;
						right: 39px !important;
						top:-10px !important;
					}
					&.fourth-stage {
						top: -10px !important;
						left: 40px!important;
						max-width: 205px;
						margin-top: 0 !important;
					}
					&.fifth-stage {
						margin-top: 19px !important;
						left: 65px!important;
						top: -10px !important;
						padding-right: 25px;
						.fk-item__number {
							left: -72px!important;
						}
					}
					&.sixth-stage {
						margin-top: 9px !important;
						left: 40px!important;
						top: -10px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 805px) {
	.fk-item__storage-map img {
		max-width: 48%;
	}
	.grid_stages {
		.grid__item {
			.fk-item {
				&.fk-item_stage {

				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.fk-item__title {
		line-height: 20px;
		text-transform: none;
	}

}


@media screen and (max-width: 767px) {
	.fk-item__storage-map {
		display: none;
	}

	.grid_stages {
		padding-top: 0;
		.grid__item {
			width: auto!important;
			padding: 0;
			margin-left: 40px;
			margin: 0 auto;
			max-width: 240px;
			vertical-align: top;
			text-align: left;
			font-size: 20px;
			line-height: normal;
			box-sizing: border-box;
			float: none;
			position: relative;
			&:before {
				border-left: 3px solid #0393d8;
				position: absolute;
				top:0;
				bottom: 0;
				left: -3px;
				content: '';
			}
			.fk-item {
				float: none;
				display: block;
				&.fk-item_stage {
					max-width: 300px;
					display: block;
					margin-left: 34px;
					margin-bottom: 40px;
					.fk-item__desc {
						font-size: 14px;
					}
					.fk-item__title {
						a {
							font-size: 16px;
						}
					}
					.fk-item__number {
						left: -61px;
						width: 50px;
						height: 50px;
					}
					&.first-stage {
						top: auto !important;
						right: auto !important;
						margin-top: 0 !important;
					}
					&.second-stage {
						top: auto !important;
						right: auto !important;
						margin-top: 0 !important;
						.fk-item__desc {
							padding: 0;
						}
					}
					&.third-stage {
						top: auto !important;
						right: auto !important;
						margin-top: 0 !important;
					}
					&.fourth-stage {
						top: auto !important;
						left: auto !important;
						margin-top: 0 !important;
					}
					&.fifth-stage {
						top: auto !important;
						left: auto !important;
						margin-top: 0 !important;
						padding: 0;
						.fk-item__number {
							left: -61px !important;
						}
					}
					&.sixth-stage {
						top: auto !important;
						left: auto !important;
						margin-top: 0 !important;
					}
				}
			}
			&.last {
				float: none;
				display: block;
				vertical-align: top;
				text-align: left;
				font-size: 20px;
				line-height: normal;
				box-sizing: border-box;
				.fk-item {
					&.sixth-stage {
						position: relative;
						&:before {
							content: "";
							border:3px solid #fff;
							top:0;
							bottom: 0;
							left: -40px;
							position: absolute;
						}
					}
				}
				&:before {
					bottom: 85px;
				}
				.fk-item__number {
					left: -61px !important;
					width: 50px;
					height: 50px;
				}
			}
		}
	}

	.js-svg_fk_item_1 {
		svg {
			width: 25px;
			height: 25px;
			margin-top: -28px;
		}
	}

	.js-svg_fk_item_2 {
		svg {
			width: 29px;
			height: 23px;
			margin-top: -24px;
			margin-left: 5px;
		}
	}

	.js-svg_fk_item_3 {
		svg {
			width: 25px;
			height: 35px;
			margin-top: -27px;
		}
	}

	.js-svg_fk_item_4 {
		svg {
			width: 26px;
			height: 25px;
			margin-top: -27px;
		}
	}

	.js-svg_fk_item_5 {
		svg {
			width: 25px;
			height: 24px;
			margin-top: -28px;
			margin-left: 3px;
		}
	}

	.js-svg_fk_item_6 {
		svg {
			width: 32px;
			height: 34px;
			margin-top: -28px;
			margin-left: 4px;
		}
	}

}


@media screen and (max-width:690px) {

	.js-svg_fk_item_1 {
		svg {
			width: 25px;
			height: 25px;
			margin-top: -10px;
		}
	}

	.js-svg_fk_item_2 {
		svg {
			width: 29px;
			height: 23px;
			margin-top: -8px;
			margin-left: 5px;
		}
	}

	.js-svg_fk_item_3 {
		svg {
			width: 25px;
			height: 35px;
			margin-top: -9px;
		}
	}

	.js-svg_fk_item_4 {
		svg {
			width: 26px;
			height: 25px;
			margin-top: -10px;
		}
	}

	.js-svg_fk_item_5 {
		svg {
			width: 25px;
			height: 24px;
			margin-top: -12px;
			margin-left: 3px;
		}
	}

	.js-svg_fk_item_6 {
		svg {
			width: 32px;
			height: 34px;
			margin-top: -12px;
			margin-left: 4px;
		}
	}

} 