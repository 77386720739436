.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }


@function getBase($key) {
	@return map-get($base, $key);
}

/*-----------------------------------------------------------
	Sizes Control
-----------------------------------------------------------*/
@function sizeRelativePageWidth($size, $page-width) {
	@return 1vw * ($size/($page-width/100));
}

@mixin sizeRelativePageWidth($option, $size, $page-width, $min-size){
	#{$option}: sizeRelativePageWidth($size, $page-width);
	
	@media screen and (max-width: $min-size*($page-width/$size)) {
		#{$option}: $min-size;
	}
}

@function sizeRelativePageHeight($size, $page-height) {
	@return 1vh * ($size/($page-height/100));
}

@mixin sizeRelativePageHeight($option, $size, $page-height, $min-size){
	#{$option}: sizeRelativePageHeight($size, $page-height);
	
	@media screen and (max-height: $min-size*($page-height/$size)) {
		#{$option}: $min-size;
	}
}


/*-----------------------------------------------------------
	Other
-----------------------------------------------------------*/
@mixin overlay($background: null) {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	@if $background{
		background: $background;
	}
}

@mixin fixedNumberOfLines($line-height, $lines: 1) {
	line-height: $line-height;
	height: $line-height * $lines;
	text-overflow: ellipsis;
	overflow: hidden;
	@if $lines == 1{
		white-space: nowrap;
	}
}

@mixin inlineBackground($font-size, $padding, $background-color) {
	font-size: $font-size;
	line-height: $font-size + $padding;
	padding: $padding 0;
	box-shadow: $padding 0 0 $background-color, -$padding 0 0 $background-color;
	background-color: $background-color;
	box-decoration-break: clone;
}