.card{
	display: inline-block;
}


.card_blog{
	height: 460px;
	overflow: hidden;
	border: 1px solid #c4c4c4;
	border-top: none;
	transition: box-shadow 0.3s;
	
	&:hover{
		box-shadow: 0 0 25px rgba(0,0,0,0.2);
	}

	.card__thumb{
		width: 100%;
	}
	
	.card__content-wrap{
		padding: 20px;
	}
	
	.card__title{
		font-size: 20px;
		font-weight: bold;
		text-decoration: underline;
		margin-bottom: 7px;
		
		&:hover{
			text-decoration: none;
		}
	}
	
	.card__date{
		font-size: 13px;
		color: #7b7b7b;
		margin-bottom: 7px;
	}
	
	.card__text{
		font-size: 15px;
	}
}