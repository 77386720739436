.fk-item{

	&__storage-map {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

/*------------------------[ Map ]----------------------------*/
.fk-item__storage-map {
	text-align: center;
	img {
		@media screen and (max-width: 1180px) {
			max-width: 50%;
		}
	}
	@media screen and (max-width: 690px) {
		display: none;
	}
}

/*------------------------[ grid Stages ]----------------------------*/
.section_stages .section__content {
	position: relative;
	@media screen and (min-width: 1080px) {
		min-height: 550px;
	}
}
.grid_stages {
	@media screen and (min-width: 690px) {
		padding-top:55px;
		overflow: hidden;
	}
	.grid__item {
		@media screen and (min-width: 690px) {
			width: 33% !important;
			float: left;
			text-align: right;
		}
		@media screen and (max-width: 690px) {
			width: auto !important;
			padding: 0;
			border-left: 3px solid #0393d8;
			margin-left: 40px;
			&:hover {
				border-left: 3px solid #0393d8;
			}
		}
		&.last {
			@media screen and (min-width: 690px) {
				text-align: left;
				float: right;
				.fk-item__number{
					left: -75px !important;
					@media screen and (min-width: 1180px) {
						left: -85px !important;
					}
				}
			}
		}
	}
}

/*------------------------[ Stages ]----------------------------*/
.fk-item_stage {
	position: relative;
	max-width: 300px;
	&:hover {
		.fk-item__title a {
			text-decoration: underline !important;
		}
		.fk-item__number {
			background: #a4d435;
			color: #fff;
		}
	}

	.fk-item__title {
		font: 18px 'GothamBold', sans-serif;
		color: #333;
		a {
			font: 18px 'GothamBold', sans-serif;
			color: #333;
		}
	}
	.fk-item__number{
		position: absolute;
		right: -75px;
		top: 0;
		border: 3px solid #a4d435;
		border-radius: 100%;
		background: #fff;
		width: 65px;
		height: 65px;
		text-align: center;
		font-size: 36px;
		line-height: 65px;
		color: #888;
		@media screen and (min-width: 1180px) {
			right: -85px;
		}
	}
	.fk-item__desc {
		font: 16px 'Calibri', sans-serif;
		color: #000;
	}
	@media screen and (min-width: 690px) {
		display: inline-block;
		&.first-stage {
			top:-15px;
			right: 50px;
			.fk-item__number{
				top: 20px;
			}
		}
		&.second-stage {
			margin-top:45px;
			right: 120px;
		}
		&.third-stage {
			margin-top:75px;
			right: 68px;
		}
		&.fourth-stage {
			top: -15px;
			left: 50px;
			.fk-item__number{
				top: 20px;
			}
		}
		&.fifth-stage {
			left: 110px;
			margin-top: 28px;
			.fk-item__number{
				top: 20px;
			}
		}
		&.sixth-stage {
			margin-top: 88px;
    		left: 67px;
		}
	}
	@media screen and (max-width: 1024px) {
		.fk-item__title {
			font: 16px 'GothamBold', sans-serif;
			color: #000;
			a {
				font: 16px 'GothamBold', sans-serif;
				color: #000;
			}
		}
		.fk-item__desc {
			font-size: 14px;
		}
	}
}
@media screen and (max-width: 690px) {
	div.fk-item_stage {
		top: auto !important;
		left: auto !important;
		right: auto !important;
		margin: 0 0 40px 34px !important;

		.fk-item__number {
			right: auto !important;
			left: -60px !important;
			height: 50px;
			width: 50px;
			line-height: 50px;
			font-size: 25px;
		}
		&.sixth-stage {
			margin-bottom: 0 !important;
			.fk-item__desc {
				margin-bottom: 0;
			}
			.fk-item__number:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 17px;
				margin-top:3px;
				width: 10px;
				height: 30px;
				background: #fff;
			}
		}
	}
}

@media screen and (min-width: 690px) and (max-width: 800px) {
	.fk-item_stage {
		width: 200px;
		.fk-item__title {
			font-size: 14px;
			a {
				font-size: 14px;
			}
		}
	}
	.first-stage {
		top:-58px !important;
		right: 10px !important;
	}
	.second-stage {
		margin-top: -30px !important;
		right: 60px !important;
		width: 200px;
	}
	.third-stage {
		margin-top: 5px !important;
		right: 40px !important;
	}
	.fourth-stage {
		top: -58px !important;
		left: 0 !important;
		.fk-item__desc {
			font-size: 13px;
		}
	}
	.fifth-stage {
		left: 45px !important;
		top: -50px !important;
	}
	.sixth-stage {
		margin-top: -40px !important;
		left: 30px !important;
	}
}
@media screen and (min-width: 800px) and (max-width: 900px) {
	.fk-item_stage {
		width: 200px;
	}
	.first-stage {
		top:-55px !important;
		right: 20px !important;
	}
	.second-stage {
		margin-top: 0 !important;
		right: 60px !important;
		width: 200px;
	}
	.third-stage {
		margin-top: 15px !important;
		right: 50px !important;
	}
	.fourth-stage {
		 top: -55px !important;
		 left: 10px !important;
	}
	.fifth-stage {
		width: 200px;
		left: 55px !important;
		top: -40px !important;
	}
	.sixth-stage {
		margin-top: -40px !important;
		left: 40px !important;
	}
}
@media screen and (min-width: 900px) and (max-width: 1180px) {
	.fk-item_stage {
		max-width: 200px;
	}
	.first-stage {
		top:-35px !important;
		right: 40px !important;
	}
	.fourth-stage {
		top: -35px !important;
		left: 30px !important;
	}
	.third-stage {	
		margin-top: 15px !important;
		right: 50px !important;
	}
	.sixth-stage {
		margin-top: -10px !important;
		left: 30px !important;
	}
	.second-stage {
		margin-top: 0 !important;
		right: 80px !important;
	}
	.fifth-stage {
		left: 70px !important;
		top: -10px;
	}
}
@media screen and (min-width: 900px) and (max-width: 1200px){
	.fk-item_stage {
		max-width: 200px;
	}
}

.item__gallery {
	text-align: center;
    margin-top: 30px;
	.item__gallery-item {
		display: inline-block;
	    padding-right: 7px;
	    height: 160px;
	    overflow: hidden;
	    &:last-child {
			padding-right: 0px;
		}
		&.slick-slide {
			padding:0 5px
		}
		
		img {
			height: auto;
    		width: 176px;
		}
	}
	.slick-arrow:before {
			content: '';
	}
	.slick-arrow {
		 
	}
	button {
		display: block;
	    font-size: 0;
	    top: 66%!important;
	    transform: none;
	    background: rgba(0, 0, 0, 0.2)!important;
	    &:hover {
			    background: rgba(255, 255, 255, 0.7) !important; 
			    color: #0094db !important;
		}
	}
	.slick-dots {
	    bottom: -30px;
	}
}

.complex {
	text-align: left;
    width: 294px;
 
    position: relative;
    max-width: 100%;
    	&:before, &:after  {
			position: absolute;
		    left: 100%;		    
		    font-size: 12px;
    		color: #666;
		}
		&:before {
			    content: '0%';
			    bottom: 0;
			    
		}
		&:after {
			content:'100%';
			top: 0;
		}
	> div.complex__wrap {
		display: inline-block;
	    border-right: 3px solid #c3eaf9;
	    padding-right: 20px;
	    position: relative;
	    &:before, &:after  {
	    	position: absolute;
		    width: 100%;
		    height: 1px;
		    left:0;
		    border-top: 1px dashed #666;
	    }	       
    
		&:before {
			content:'';
			top: 15px;
		}
		&:after {
			content:'';
			bottom: 15px;	
		}
		
		@media (max-width: 768px){
			padding-right: 10px;
		}
	}
	svg {
		width:46px;
		margin-right: 15px;
		background: #fff;
	    position: relative;
	    z-index: 9;
	    @media (max-width: 768px){
			margin-right: 7px;
		}
	} 
	@media (max-width: 768px){
		margin: 0 auto;
		width: 249px;
		transform: scale(0.9);
		position: relative;
    	left: -10px;
	}
}


.dwg__plan {
	position: absolute;
    right: 20px;
    top: 9px;
    color: #666666;
    font-size: 16px;
    text-transform: none;
        font-family: calibri;
    div {
		width: auto;
    	padding-right: 10px;
	}
	svg {
		width: 27px;
    	color: #888888;  
	}
	@media   (max-width: 767px) {
		top: 36px;
		font-size: 14px;
	}
}  