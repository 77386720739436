.wbbg {
  background: #fff;
  .bg3 {
    background: #fff;
  }
}
.section_services {
  background: #fff;
    padding: 20px 7px !important;
}
.sr-item {
  display: block;
  min-height:280px;
  text-align: center;
  position: relative;
  border: 2px solid transparent;

  &__title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 5px;
    font: 18px 'Gotham', sans-serif;
    color: #000;
    text-transform: uppercase;
  }
  &:hover {
    border-color: #a4d435;
  }
}

